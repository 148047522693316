import { type FC } from 'react';
import { chartEditorIcons } from '../../../../../constants/chartEditorIcons';
import classStyles from '../ChartWrapper.module.css';
import classNames from 'classnames';
import { type ChartEditorFigure } from '../../../../../enums/ChartEditorFigure';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../../redux/theme/reducer';

interface IProps {
  selectEditor: (key: ChartEditorFigure) => void;
  editorKey: ChartEditorFigure;
}

export const AsidePopperItem: FC<IProps> = ({ selectEditor, editorKey }) => {
  const { icon: Component, name } = chartEditorIcons[editorKey];
  const onUpdate = (): void => {
    selectEditor(editorKey);
  };
  const theme = useSelector(selectTheme);

  return (
    <div
      style={{
        backgroundColor: theme.chartBackground,
        color: theme.textColor,
        fill: theme.textColor,
        stroke: theme.textColor,
      }}
      className={classStyles.popUpItem}
      onClick={onUpdate}
    >
      <Component className={classNames(classStyles.svg)} />
      <span className={classStyles.popUpItemName}>{name}</span>
    </div>
  );
};
