import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useSelector } from '../../redux/rootReducer';

import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import TwoFactorForm from './components/TwoFactorForm';
import ExchangeApiForm from './components/ExchangeApiForm';
import PasswordRecovery from './components/PasswordRecovery';
import PasswordRecoverySubmit from './components/PasswordRecoverySubmit';

import logo from '../../assets/images/logo.png';
import authLinesBlue from '../../assets/images/auth-lines-blue.svg';
import authLinesEmerald from '../../assets/images/auth-lines-emerald.svg';
import authLinesGray from '../../assets/images/auth-lines-gray.svg';
import authLinesGreen from '../../assets/images/auth-lines-green.svg';
import authLinesPurple from '../../assets/images/auth-lines-purple.svg';
import authLinesRed from '../../assets/images/auth-lines-red.svg';

import './auth.scss';

export enum Components {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  PASSWORD_RECOVERY = 'password_recovery',
}

export interface IAuthFormProps {
  setComponent: (component: Components) => void;

  goNext: () => void;

  goBack: () => void;
}

interface IComponentData {
  type: string;
  image: string;
  form: FC<IAuthFormProps>;
}

const authForms: Record<Components, Array<IComponentData>> = {
  [Components.LOGIN]: [{ type: 'blue', image: authLinesBlue, form: LoginForm }],
  [Components.REGISTRATION]: [
    { type: 'purple', image: authLinesPurple, form: RegistrationForm },
    { type: 'red', image: authLinesRed, form: TwoFactorForm },
    { type: 'emerald', image: authLinesEmerald, form: ExchangeApiForm },
  ],
  [Components.PASSWORD_RECOVERY]: [
    { type: 'gray', image: authLinesGray, form: PasswordRecovery },
    { type: 'green', image: authLinesGreen, form: PasswordRecoverySubmit },
  ],
};

const Auth: FC = () => {
  const [component, setComponent] = useState<Components>(Components.LOGIN);
  const [formKey, setFormKey] = useState<number>(0);

  const navigate = useNavigate();

  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStore.user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setFormKey(0);
  }, [component]);

  const goNext = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev + 1]) {
        return prev + 1;
      }

      return prev;
    });
  };

  const goBack = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev - 1]) {
        return prev - 1;
      }

      return prev;
    });
  };

  const componentData = authForms[component][formKey];

  const AuthForm = componentData.form;

  return (
    <section className="auth">
      <div className={cn('auth__bg', `auth__bg_${componentData.type}`)}>
        <img src={componentData.image} alt="Auth BG" />
      </div>

      <div className="auth-main">
        <div className="auth-main__logo">
          <img src={logo} alt="ALGO LAB LOGO" />
          <span>Algo Lab</span>
        </div>

        <AuthForm setComponent={setComponent} goNext={goNext} goBack={goBack} />

        {component === Components.REGISTRATION && (
          <div className="auth-main-steps">
            {authForms.registration.map((_, key) => {
              return (
                <Fragment key={key}>
                  <div
                    className={cn(
                      'auth-main-steps__step',
                      key === formKey && 'auth-main-steps__step_active',
                    )}
                  >
                    {key + 1}
                  </div>

                  <div className="auth-main-steps__line" />
                </Fragment>
              );
            })}
          </div>
        )}

        <a href="#" target="_blank" className="auth-main__terms">
          Terms of use
        </a>
      </div>
    </section>
  );
};

export default Auth;
