import React, { type FC, Fragment, useEffect } from 'react';
import styles from '../ChartWrapper.module.css';
import { ChartSideMenuIcon } from '../../../../../assets/icons/ChartSideMenuIcon';
import { ChartLockCloseIcon, ChartLockOpenIcon } from '../../../../../assets/icons/ChartLocktIcon';
import { ChartInvisibleIcon, ChartVisibleIcon } from '../../../../../assets/icons/ChartVisibleIcon';
import { ChartTrashIcon } from '../../../../../assets/icons/ChartTrashIcon';
import classNames from 'classnames';
import { useSelector } from '../../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import {
  setDynamicBackTest,
  setIsDestroy,
  setIsLose,
  setIsVisible,
  setSelectedEditor,
  updateListEditor,
} from '../../../../../redux/chart/reducer';
import { type ChartEditorFigure } from '../../../../../enums/ChartEditorFigure';
import { AsideInteractiveItem } from './AsideInteractiveItem';
import { HeaderDayPicker } from './HeaderDayPicker';
import { ChartInfoSelector } from '../../../../../components/ChartInfoSelector';
import { type IChartHeaderProps } from '../../../../../interfaces/chartData';
import ChartArrow from '../../../../../assets/images/chart-arrow.svg';
import FullScreenIcon from '../../../../../assets/images/FullScreenIcon.svg';
import ScreenIcon from '../../../../../assets/images/ScreenIcon.svg';
import SettingIcon from '../../../../../assets/images/SettingsIcon.svg';
import GTumbler from '../../../../../components/GTumbler/GTumbler';
import { selectTheme } from '../../../../../redux/theme/reducer';
import { fetchCustomTickers } from '../../../../../redux/chartTickers/reducer';

interface IProps {
  children: JSX.Element;
  chartHeader: IChartHeaderProps;
  enableScrollToReal: boolean;
  scrollToReal: () => void;
  isSideMenu: boolean;
  toggleSideMenu: () => void;
}
export const ChartWrapper: FC<IProps> = (props) => {
  const { listEditors, selectedEditor, isLock, isVisible, isDynamicBackTest } = useSelector(
    (state) => state.chart,
  );

  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    dispatch(fetchCustomTickers() as any); // Dispatch the fetchCustomTickers action
  }, [dispatch]);
  const selectEditor =
    (prevEditor: ChartEditorFigure) =>
    (newEditor: ChartEditorFigure): void => {
      dispatch(setSelectedEditor(newEditor));
      dispatch(updateListEditor({ prevEditor, newEditor }));
    };

  const selectCurrentEditor = (currEditor: ChartEditorFigure) => (): void => {
    dispatch(setSelectedEditor(currEditor));
  };

  const toggleLock = (): void => {
    dispatch(setIsLose());
  };

  const toggleVisible = (): void => {
    dispatch(setIsVisible());
  };

  const destroy = (): void => {
    dispatch(setIsDestroy());
  };
  return (
    <div className={styles.chartWrapper}>
      <div
        style={{ backgroundColor: theme.chartHeaderBakcground, color: theme.textColor }}
        className={styles.chartHeader}
      >
        <div
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          className={`${styles.chartHeaderStart} ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            props.chartHeader.data.customIndex &&
            props.chartHeader.data.customIndex.tickers.length > 0 &&
            styles.chartHeaderStartCustomIndex
          }`}
        >
          <div className={styles.chartHeaderStartMainContent}>
            <div
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
                borderRight: !props.isSideMenu ? '1px solid white ' : 'none',
              }}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              className={`${styles.chartSidebarIconWrapper} ${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                props.chartHeader.data.customIndex &&
                props.chartHeader.data.customIndex.tickers.length > 0 &&
                styles.chartSidebarIconWrapperCustomOpened
              }`}
            >
              <ChartSideMenuIcon
                onClick={props.toggleSideMenu}
                className={classNames(styles.asideIcon, {
                  [styles.asideIconActive]: props.isSideMenu,
                })}
              />
            </div>
            <ChartInfoSelector {...props.chartHeader} />

            <div className={styles.chartHeaderRightContainer}>
              <div className={styles.chartDynamicBacktest}>
                <span>Dynamic backtest</span>
                <GTumbler
                  name="Dynamic Backtest"
                  checked={isDynamicBackTest}
                  onToggle={setDynamicBackTest}
                />
              </div>

              <div className={styles.chartHeadertIconsContainer}>
                <HeaderDayPicker />
                <img src={FullScreenIcon} alt="arrow" />
                <img src={ScreenIcon} alt="arrow" />
                <img src={SettingIcon} alt="arrow" />
              </div>
            </div>
          </div>
          {props.chartHeader.data.customIndex &&
            props.chartHeader.data.customIndex?.tickers.length > 0 && (
              <div className={styles.chartHeaderStartCustomContent}>
                {props.chartHeader.data.customIndex.tickers.map((ticker, index) => (
                  <div key={index} className={styles.chartHeaderStartCustomContentItem}>
                    <img
                      className={styles.tickerSelectorContentImage}
                      src={`${process.env.PUBLIC_URL}/coins/${ticker.baseAsset}.png`}
                      alt=""
                    />
                    <div>
                      {ticker.baseAsset} / {ticker.quoteAsset}
                      <span> {ticker.percent}%</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
      <div className={styles.chartMain}>
        <div
          style={{ backgroundColor: theme.chartAsideMenuBackground }}
          className={classNames(styles.chartAside, {
            [styles.chartAsideActive]: props.isSideMenu,
          })}
        >
          <ul
            style={{
              backgroundColor: theme.chartAsideMenuBackground,
              fill: theme.chartAsideMenuIconFill,
            }}
            className={styles.asideList}
          >
            {listEditors.map((editorKey, idx) => (
              <Fragment key={editorKey}>
                {idx === 5 && (
                  <li className={styles.asideListItem}>
                    <span className={styles.brLine}></span>
                  </li>
                )}
                <AsideInteractiveItem
                  {...{ editorKey, selectCurrentEditor, selectEditor, selectedEditor }}
                />
              </Fragment>
            ))}
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <div
                style={{
                  backgroundColor: theme.chartAsideMenuBackground,
                  fill: theme.chartAsideMenuIconFill,
                }}
                className={styles.asideIconWrapper}
                onClick={toggleLock}
              >
                {!isLock && <ChartLockOpenIcon className={styles.svg} />}
                {isLock && <ChartLockCloseIcon className={styles.svg} />}
              </div>
            </li>
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <div className={styles.asideIconWrapper} onClick={toggleVisible}>
                {isVisible && <ChartVisibleIcon className={styles.svg} />}
                {!isVisible && <ChartInvisibleIcon className={styles.svg} />}
              </div>
            </li>
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <span className={styles.brLine}></span>
            </li>
            <li className={styles.asideListItem}>
              <div className={styles.asideIconWrapper} onClick={destroy}>
                <ChartTrashIcon className={styles.svg} />
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classNames(styles.chartSection, {
            [styles.chartSectionActive]: props.isSideMenu,
          })}
        >
          {props.children}
          {props.enableScrollToReal && (
            <div className={styles.chartRollRealtime} onClick={props.scrollToReal}>
              <img src={ChartArrow} alt="<-" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
