import type { Dispatch, FC, SetStateAction } from 'react';

import GTumbler from '../../../../../../components/GTumbler/GTumbler';
import CloseSection from '../../../../../../assets/images/Strategy/CloseSectionIcon.svg';

import './strategyEditorHeader.scss';

interface IStrategyEditorHeaderProps {
  title: string;
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  collapseConstructor: () => void;
}

const StrategyEditorHeader: FC<IStrategyEditorHeaderProps> = (props) => {
  return (
    <div className="strategy-editor-header">
      <span className="strategy-editor-header__title">{props.title}</span>

      <div className="strategy-editor-header-controls">
        <GTumbler
          name="strategyTumbler"
          checked={props.strategyEnabled}
          onToggle={(state) => {
            props.setStrategyEnabled(state);
          }}
        />
        <img
          src={CloseSection}
          className="strategy-editor-header-controls-close"
          onClick={props.collapseConstructor}
          alt="close"
        />
      </div>
    </div>
  );
};

export default StrategyEditorHeader;
