import { type FC, memo, useEffect, useState } from 'react';
import styles from '../History.module.css';
// import classNames from 'classnames';
// import { BarChart } from './BarChart';
import BarChart from '../../Log/components/BarChart/BarChart';
import { type IStatistics, type ITradeData } from '../../../../../interfaces/IStrategy';
import { ChartTime } from '../../../../../enums/HistoryChartTabs';
import { TIMEFRAMES } from '../../../../../configs/chart';
import { Info } from '../../Log/components/Info';

interface IProps {
  stats: IStatistics;
  containerSize: {
    height: number;
    width: number;
  };
  trades: ITradeData[];
  chartTime: ChartTime;
  currChartTime: number;
}

const ChartTimeData: Record<ChartTime, number> = {
  [ChartTime.DAY]: 60 * 60000 * 24,
  [ChartTime.MONTH]: 60 * 60000 * 24 * 30,
  [ChartTime.YEAR]: 60 * 60000 * 24 * 365,
};

// const getFirstDayOfMonth = (val: number): number => {
//   const d = new Date(val);
//   const year = d.getFullYear();
//   const month = d.getMonth();
//   const isLeapYear = new Date(year, month + 1, 0).getDate() === 29;
//   return new Date(year, month, isLeapYear ? 29 : 1).getTime();
// };

export const StrategyTesterContent: FC<IProps> = memo(function Table({
  stats,
  containerSize,
  trades,
  chartTime,
  currChartTime,
}) {
  // const {
  //   deposit,
  //   net_profit: netProfit,
  //   trades_count: tradesCount,
  //   max_drawdown: maxDrawdown,
  //   avg_trade_profit: avgTradeProfit,
  //   profit_percent: profitPercent,
  //   commission_paid: commissionPaid,
  // } = stats;
  const [data, setData] = useState<{ time: number; date: string; profit: number; count: number }[]>(
    [],
  );
  useEffect(() => {
    if (trades.length === 0) return;

    const tempData: { time: number; date: string; profit: number; count: number }[] = [];

    switch (chartTime) {
      case ChartTime.DAY: {
        const date = new Date(currChartTime);
        date.setDate(1);
        const firstDayOfMonth = date.getTime();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        const lastDayOfMonth = date.getTime();

        for (let i = lastDayOfMonth; i >= firstDayOfMonth; i -= TIMEFRAMES['1d']) {
          const startDayTime = new Date(i).setHours(0, 0, 0, 0);
          const endDayTime = new Date(i).setHours(23, 59, 59, 999);

          let profit = 0;
          let count = 0;

          for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
            const trade = trades[tradeId];

            if (trade.exit_time >= startDayTime && trade.exit_time <= endDayTime) {
              profit += trade.pnl;
              count += 1;
            }
          }
          tempData.unshift({
            time: i,
            date: new Date(i).toLocaleDateString('en-US', { day: 'numeric' }).slice(0, 3),
            profit,
            count,
          });
        }
        break;
      }
      case ChartTime.MONTH: {
        const firstOfYear = new Date(new Date(currChartTime).getFullYear(), 0, 1).getTime();

        for (
          let i = firstOfYear + ChartTimeData[ChartTime.YEAR] - ChartTimeData[ChartTime.MONTH];
          i >= firstOfYear;
          i -= ChartTimeData[ChartTime.MONTH]
        ) {
          const startMonthTime = new Date(new Date(i).setDate(0)).setHours(24, 0, 0, 0);
          const endMonthTime = new Date(
            new Date(new Date(i).setMonth(new Date(i).getMonth() + 1)).setDate(0),
          ).setHours(24, 0, 0, 0);

          let profit = 0;
          let count = 0;

          for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
            const trade = trades[tradeId];

            if (trade.exit_time >= startMonthTime && trade.exit_time < endMonthTime) {
              profit += trade.pnl;
              count += 1;
            }
          }
          tempData.unshift({
            time: i,
            date: new Date(i).toLocaleString('en-US', { month: 'long' }).slice(0, 3),
            profit,
            count,
          });
        }
        break;
      }
      case ChartTime.YEAR: {
        for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
          const trade = trades[tradeId];
          const year = new Date(trade.exit_time).getFullYear().toString();
          if (tempData[0]?.date === year) {
            tempData[0].profit += trade.pnl;
            tempData[0].count += 1;
          } else {
            tempData.unshift({
              time: new Date(parseInt(year), 0, 1).getTime(),
              date: year,
              profit: trade.pnl,
              count: 1,
            });
          }
        }
        break;
      }
    }

    setData(tempData);
  }, [trades, chartTime, currChartTime]);

  // const profitColorFlags: Record<string, boolean>[] = [
  //   maxDrawdown,
  //   avgTradeProfit,
  //   profitPercent,
  // ].map((item) => {
  //   return Object.entries(item).reduce(
  //     (acc, [key, value]) => ({
  //       ...acc,
  //       [key]: (Array.isArray(value) ? value[0] : value) > 0,
  //     }),
  //     {},
  //   );
  // });

  // const [flagsMaxDrawdown, flagsAvgTradeProfit, flagsProfitPercent] = profitColorFlags;
  // const marginBottomChart = 30;
  // const marginXChart = 200;

  return (
    <div className={styles.mainContent}>
      <div className={styles.tableWrap}>
        <Info {...stats} />
      </div>
      <div className={styles.chartBarWrapper}>
        <BarChart
          // height={containerSize.height - marginBottomChart}
          // width={containerSize.width - marginXChart}
          data={data}
        />
      </div>
    </div>
  );
});
