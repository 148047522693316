import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

import './BarChart.scss';

interface IData {
  time: number;
  date: any;
  profit: number;
  count: number;
}

interface IProps {
  data: IData[];
  width?: number;
  height?: number;
}

interface ChartData {
  labels: any[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
    borderRadius: number;
  }[];
}

interface ChartOptions {
  maxBarThickness: number;
  responsive: boolean;
  maintainAspectRatio: boolean;
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      callbacks: {
        title: (tooltipItems: any) => string;
        footer: (tooltipItems: any) => string;
        label: (context: any) => any;
      };
    };
  };
  scales: {
    y: {
      position: any;
      beginAtZero: boolean;
      suggestedMin: number;
      suggestedMax: number;
      title: {
        display: boolean;
      };
      ticks: {
        color: string;
        font: {
          size: number;
        };
      };
      grid: {
        color: string;
        borderColor: string;
      };
    };
    x: {
      beginAtZero: boolean;
      ticks: {
        color: string;
        font: {
          size: number;
        };
      };
      grid: {
        color: string;
        borderColor: string;
      };
    };
  };
}

const BarChart: React.FC<IProps> = (props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const barChartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      const backgroundColors = props.data
        .map((item) => item.profit)
        .map((value: number) => (value >= 0 ? '#30D960' : '#FF0000'));

      const max = Math.max(...props.data.map((item) => Math.abs(item.profit)));

      const chartData: ChartData = {
        labels: props.data.map((item) => item.date),
        datasets: [
          {
            label: 'Profit',
            data: props.data.map((item) => item.profit),
            backgroundColor: backgroundColors,
            borderWidth: 0,
            borderRadius: 2,
          },
        ],
      };

      const chartOptions: ChartOptions = {
        maxBarThickness: 10,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => '',
              footer: (tooltipItems) => {
                const index = tooltipItems[0].dataIndex;
                return `Trades count: ${props.data[index].count}`;
              },
              label: function (context: any): number | string {
                let label: number | string = String(context.dataset.label) || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += Number(context.parsed.y).toFixed(2);
                }
                return label + '$';
              },
            },
          },
        },
        scales: {
          y: {
            position: 'left',
            beginAtZero: false,
            suggestedMin: -max,
            suggestedMax: max,
            title: {
              display: false,
            },
            ticks: {
              color: 'white',
              font: {
                size: 8,
              },
            },
            grid: {
              color: 'rgba(255,255,255,.0)',
              borderColor: 'rgba(255,255,255,.05)',
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              color: 'white',
              font: {
                size: 8,
              },
            },
            grid: {
              color: 'rgba(255,255,255,.05)',
              borderColor: 'rgba(255,255,255,.05)',
            },
          },
        },
      };

      if (chartInstance.current) {
        chartInstance.current.data = chartData;
        chartInstance.current.options = chartOptions;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: chartOptions,
        });
      }
    }
  }, [props.data]);

  return (
    <div ref={barChartRef} className="bar-chart">
      <canvas ref={chartRef} />
    </div>
  );
};
export default BarChart;
