import { type Exchanges } from '../interfaces/IExchange';
import { Exchanges as ExchangesEnum } from '../interfaces/IStrategy';
import binanceLogo from '../assets/images/binance-logo.svg';
import whiteBitLogo from '../assets/images/whitebit-logo.svg';
import byBitLogo from '../assets/images/bybit-logo.svg';

const exchanges: Exchanges = {
  binance: {
    name: 'Binance',
    icon: binanceLogo,
  },
  whiteBit: {
    name: 'WhiteBit',
    icon: whiteBitLogo,
    disabled: false,
  },
  byBit: {
    name: 'ByBit',
    icon: byBitLogo,
    disabled: false,
  },
};

export const EXCHANGES_IMAGES: Record<ExchangesEnum, string> = {
  [ExchangesEnum.BinanceFutures]: binanceLogo,
};

export default exchanges;
