import { type FC } from 'react';
import Arrow from '../../../../../../assets/images/Strategy/BackArrow.svg';
import './StrategySettingsHeader.scss';

interface IProps {
  name: string;
  onClose: () => void;
}

export const StrategySettingsHeader: FC<IProps> = (props) => {
  return (
    <div className="strategy-settings-header">
      <button onClick={props.onClose} className="strategy-settings-header__button">
        <img src={Arrow} alt="<" /> Back
      </button>
      <h3>{props.name}</h3>
    </div>
  );
};
