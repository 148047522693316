import type { Dispatch, FC, SetStateAction } from 'react';

import GButton, { ButtonColors, ButtonTypes } from '../../../../../../components/GButton/GButton';

import { Components } from '../../Components';

import onImg from '../../../../../../assets/images/Strategy/NoStrategy.svg';
import offImg from '../../../../../../assets/images/Strategy/DisabledConstructor.svg';

import './strategyEditorEmpty.scss';
import { type IStrategyAllResponse } from '../../../../../../services/api/Strategy/types/IStrategyResponse';

interface IStrategyEditorEmptyProps {
  strategies: IStrategyAllResponse;
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  setStrategyNode: Dispatch<SetStateAction<Components | null>>;
}

const StrategyEditorEmpty: FC<IStrategyEditorEmptyProps> = (props) => {
  return (
    <div className="strategy-editor-empty">
      <img
        className="strategy-editor-empty__icon"
        src={props.strategyEnabled ? onImg : offImg}
        alt="Strategy editor"
      />

      <span className="strategy-editor-empty__title">
        {props.strategyEnabled ? 'You no have strategy!' : 'Constructor disabled'}
      </span>
      <p
        style={{ width: props.strategyEnabled ? '84%' : '' }}
        className="strategy-editor-empty__description"
      >
        {props.strategyEnabled
          ? 'Select an existing or create a new strategy'
          : 'Please turn on or press button for open the strategy!'}
      </p>

      <div className="strategy-editor-empty-actions">
        {props.strategyEnabled ? (
          <>
            <GButton
              type={ButtonTypes.BUTTON}
              title="Create strategy"
              color={ButtonColors.VIOLET}
              disabled={props.strategies.length >= 25}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.MODES);
              }}
            />

            <GButton
              type={ButtonTypes.BUTTON}
              title="Saved strategies"
              color={ButtonColors.GRAY}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.SAVED_STRATEGIES);
              }}
            />

            <GButton
              type={ButtonTypes.BUTTON}
              title="Library"
              color={ButtonColors.GRAY}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.LIBRARY);
              }}
            />
          </>
        ) : (
          <GButton
            type={ButtonTypes.BUTTON}
            title="Enable constructor"
            color={ButtonColors.VIOLET}
            bordered={false}
            filled={true}
            onClick={() => {
              props.setStrategyEnabled(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StrategyEditorEmpty;
