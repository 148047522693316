import { TradesDirections } from '../../../../interfaces/IStrategy';

export const TRADES_COLORS = {
  BACKGROUND: {
    [TradesDirections.Short]: 'rgba(255,28,28,0.35)',
    [TradesDirections.Long]: 'rgba(92,254,0,0.35)',
  },
  TRIANGLE: '#d501f8',
  STOPLOSS_LINE: 'rgb(183,7,7)',
  LIQUIDATION_LINE: '#d501f8',
  OPEN_TRADE: '#2962ff',
  AVERAGE_LINE: '#FF8A00',
  center_line: '#323232',
};

export const TRADES_CONFIG = {
  TRIANGLE: {
    width: 12.5,
    lineWidth: 4,
    lineHeight: 10,
  },
  STATS: {
    width: 244,
    y: 25,
    padding: 12.5,
  },
};
