import { useState, type FC } from 'react';
import './styles.scss';
import Arrow from '../../../../../../assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    id: number;
    name?: string;
    desc?: string;
  }[];
  activeOption: {
    id: number;
    name?: string;
    desc?: string;
  };
  onChange: (id: number) => void;
  nameFontWeight?: string;
  largeList?: boolean;
}
const CustomDropDown: FC<IProps> = ({
  options,
  onChange,
  activeOption,
  nameFontWeight,
  largeList,
}) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className="custom-dropdown"
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      <div
        className="custom-dropdown__selected"
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div style={{ fontWeight: nameFontWeight }} className="custom-dropdown__selected_name">
          {activeOption.name}
        </div>
        <div
          style={{
            fontSize: activeOption.name ? '' : '12px',
          }}
          className="custom-dropdown__selected_desc"
        >
          {activeOption.desc}
        </div>
        <img
          className={
            'custom-dropdown__selected_arrow' +
            (isOpened ? ' custom-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && largeList && (
        <div className="custom-dropdown__itemsContainer">
          {options.map((option, index) => (
            <div
              className="custom-dropdown__item"
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div style={{ fontWeight: nameFontWeight }} className="custom-dropdown__item_name">
                {option.name}
              </div>
              <div
                style={{ fontSize: option.name ? '' : '12px' }}
                className="custom-dropdown__item_desc"
              >
                {option.desc}
              </div>
            </div>
          ))}{' '}
        </div>
      )}
      {isOpened &&
        !largeList &&
        options.map((option, index) => (
          <div
            className="custom-dropdown__item"
            key={index}
            onClick={() => {
              setOpened(false);
              onChange(option.id);
            }}
          >
            <div style={{ fontWeight: nameFontWeight }} className="custom-dropdown__item_name">
              {option.name}
            </div>
            <div
              style={{ fontSize: option.name ? '' : '12px' }}
              className="custom-dropdown__item_desc"
            >
              {option.desc}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustomDropDown;
