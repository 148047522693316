import { type FC } from 'react';
import Settings from '../../../../../../assets/images/Strategy/SettingsIcon.svg';
import Delete from '../../../../../../assets/images/Strategy/DeleteIcon.svg';
// import ReactSelect from 'react-select';
import './StrategySelector.scss';
import CustomDropDown from '../CustomMultiLineDropDown/CustomMultiLineDropDown';

interface IProps {
  options: {
    id: number;
    name: string;
    desc?: string;
  }[];
  activeOption: number;
  onChange: (id: number) => void;
  openSettings?: () => void;
  deleteOption?: () => void;
  width?: string;
  height?: number;
  border?: string;
  background?: string;
}

export const StrategySelectorXL: FC<IProps> = (props) => {
  // const [isFocused, setFocused] = useState(false);
  // const options = props.options.map((e) => ({
  //   value: e.id,
  //   label: `${e.name} ${e.desc ?? ''}`,
  // }));
  // console.log(
  //   options.find((e) => e.value === props.activeOption),
  //   'options.find((e) => e.value === props.activeOption)',
  // );
  return (
    <div
      className="strategy-selectorXL"
      style={{
        // width: props.deleteOption ? '84%' : '',
        height: props.height ? `${props.height}vh` : null,
      }}
    >
      <div
        className="strategy-selectorXL__select"
        style={props.width ? { width: props.width } : null}
      >
        {/* <ReactSelect
          className="strategy-selectorXL__select-select"
          value={options.find((e) => e.value === props.activeOption)}
          isSearchable={false}
          onMenuClose={() => {
            setFocused(false);
          }}
          onMenuOpen={() => {
            setFocused(true);
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            menu: (base) => ({
              ...base,
              backgroundColor: '#454154',
              borderRadius: '3px',
            }),
            menuList: (base) => ({
              ...base,
              backgroundColor: '#454154',
              borderRadius: '3px',
            }),
            container: (base) => ({
              ...base,
              backgroundColor: '#262336',
            }),
            singleValue: (base) => ({
              ...base,
              fontSize: '16px',
              color: 'white',
              width: '100%',
              backgroundColor: '#262336',
              lineHeight: '100%',
              // textOverflow: 'clip',
            }),
            control: (base) => ({
              ...base,
              border: props.border ?? 0,
              boxShadow: 'none',
              height: '100%',
              margin: 0,
              color: 'white',
              background: props.background ?? '#262336',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: 'white',
              transition: 'all .2s ease',
              transform: isFocused ? 'rotate(180deg)' : null,
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: '#454154',
              // height: `${props.height ?? 5}vh`,
              // lineHeight: `${(props.height ?? 5) * 0.6}vh`,
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              margin: '0 2.5%',
              width: '100%',
              borderRadius: '4px',
              ':hover': {
                background: '#413e4f',
              },
            }),
          }}
          options={options}
          onChange={(e) => {
            props.onChange(e.value);
          }}
        /> */}
        <CustomDropDown
          options={props.options}
          onChange={props.onChange}
          activeOption={props.options.find((e) => e.id === props.activeOption)}
        />
      </div>
      {props.openSettings && (
        <button onClick={props.openSettings} className="strategy-selectorXL__settings">
          <img src={Settings} alt="S" />
        </button>
      )}
      {props.deleteOption && (
        <button onClick={props.deleteOption} className="strategy-selectorXL__delete">
          <img src={Delete} alt="S" />
        </button>
      )}
    </div>
  );
};
