import { type Dispatch, type FC, type SetStateAction, useEffect, useState } from 'react';
import './Strategy.scss';
import {
  IndicatorsList,
  IndicatorSource,
  type IStrategy,
  OrderTypes,
  type ISettingsIndicator,
} from '../../../../../../interfaces/IStrategy';
import { apiStrategy } from '../../../../../../services/api/Strategy/ApiStrategy';
import { StrategyName } from '../StrategyName/StrategyName';
import { StrategySwitcher } from '../StrategySwitcher/StrategySwitcher';
import { StrategyType } from '../../../../../../enums/Strategy';
import { strategySettings } from '../../../../../../configs/strategySettings';
import { StrategySettings } from '../StrategySettings/StrategySettings';
import { StrategyConstructor } from './StrategyConstructor';
import { StrategyProperties } from './StrategyProperties';
import { StrategyTypeSettings } from '../StrategyTypeSettings/StrategyTypeSettings';
import { type IChartHeaderProps } from '../../../../../../interfaces/chartData';
import { compareStrategyHeaderData } from '../../../../../../services/utils/strategy';
import { useSelector } from '../../../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { setCurrentStrategyType } from '../../../../../../redux/strategy/reducer';
import StrategyOtherFilters from '../StrategyOtherFilters/StrategyOtherFilters';

interface IProps {
  strategyId: string;
  updateHistoryName: (id: string, name: string) => void;
  backTest: (data: IStrategy) => void;
  setBackground: Dispatch<SetStateAction<string>>;
  chartHeaderData: IChartHeaderProps;
  isChartLoading: boolean;
  strategyNames: string[];
}

const constructorPages = [
  {
    name: 'Constructor',
    id: 0,
  },
  {
    name: 'Properties',
    id: 1,
  },
];

const strategiesPool: Record<string, IStrategy> = {};
let oldStrategy: IStrategy | null = null;

export const Strategy: FC<IProps> = ({
  strategyId,
  setBackground,
  updateHistoryName,
  chartHeaderData: { data: chartHeaderData, setData: setChartHeaderData },
  backTest,
  isChartLoading,
  strategyNames,
}) => {
  if (!strategyId) return;

  const [strategy, setStrategy] = useState<IStrategy | null>(null);
  const [activePage, setActivePage] = useState<number>(0);
  const [strategyType, setStrategyType] = useState<StrategyType>(StrategyType.FixedRisk);
  const [isStrategySettings, setStrategySettings] = useState<[number, number] | null>(null);
  const [isStrategyTypeSettings, setStrategyTypeSettings] = useState<boolean>(false);
  const { createdTickers: customTickers } = useSelector((e) => e.chartTickers);
  const [strategyName, setStrategyName] = useState<string>('');
  // console.log('vvvv', strategyNames, currentStrategyName, strategyNameIndex);
  // console.log('strategy', strategy);
  const dispatch = useDispatch();
  if (strategy?.settings.stop_loss) {
    dispatch(setCurrentStrategyType('Fixed risk'));
  } else {
    dispatch(setCurrentStrategyType('         DCA'));
  }
  useEffect(() => {
    if (strategy) {
      console.log('strategy', strategy.property);
      setStrategyName(strategy.name);
    }
    if (strategy) {
      if (strategy.ticker?.id) {
        const customTicker = customTickers.find((e) => e._id === strategy.ticker?.id);
        if (customTicker) {
          setChartHeaderData({
            timeframe: strategy.timeframe,
            baseAsset: '',
            quoteAsset: '',
            exchange: 0,
            customIndex: {
              _id: customTicker._id,
              name: customTicker.name,
              tickers: customTicker.tickers,
            },
          });
        } else {
          const value = {
            ...strategy,
            timeframe: 900000,
            exchange: 0,
            ticker: {
              base_asset: 'BTC',
              quote_asset: 'USDT',
            },
          };
          setStrategy(value);
          backTest(value);
        }
      }
      if (!strategy.ticker?.id) {
        setChartHeaderData({
          baseAsset: strategy.ticker.base_asset,
          quoteAsset: strategy.ticker.quote_asset,
          exchange: strategy.exchange,
          timeframe: strategy.timeframe,
        });
      }
      if (!strategy.ticker?.id && compareStrategyHeaderData(strategy, chartHeaderData)) {
        setChartHeaderData({
          timeframe: strategy.timeframe,
          baseAsset: strategy.ticker.base_asset,
          quoteAsset: strategy.ticker.quote_asset,
          exchange: strategy.exchange,
        });
      }
      backTest(strategy);
    }
  }, [strategy]);
  useEffect(() => {
    if (strategy && compareStrategyHeaderData(strategy, chartHeaderData)) {
      if (chartHeaderData.customIndex) {
        const value: IStrategy = {
          ...strategy,
          timeframe: chartHeaderData.timeframe,
          exchange: 0,
          ticker: {
            id: chartHeaderData.customIndex._id,
            assets: chartHeaderData.customIndex.tickers.map((e) => ({
              base_asset: e.baseAsset,
              quote_asset: e.quoteAsset,
              percent: e.percent,
            })),
          },
        };
        setStrategy(value);
        backTest(value);
      } else {
        const value = {
          ...strategy,
          timeframe: chartHeaderData.timeframe,
          exchange: chartHeaderData.exchange,
          ticker: {
            base_asset: chartHeaderData.baseAsset,
            quote_asset: chartHeaderData.quoteAsset,
          },
        };
        setStrategy(value);
        backTest(value);
      }
    }
  }, [
    chartHeaderData.baseAsset,
    chartHeaderData.quoteAsset,
    chartHeaderData.exchange,
    chartHeaderData.timeframe,
    chartHeaderData.customIndex,
  ]);

  useEffect(() => {
    setStrategySettings(null);
    setStrategyTypeSettings(false);
    setActivePage(0);

    const existedStrategy = strategiesPool[strategyId];

    if (existedStrategy) {
      if (existedStrategy.settings.average) {
        setStrategyType(StrategyType.DCA);
      } else {
        setStrategyType(StrategyType.FixedRisk);
      }

      setStrategy(existedStrategy);
      return;
    }

    apiStrategy.getStrategy(strategyId).then((result) => {
      strategiesPool[strategyId] = result;

      if (result.settings.average) {
        setStrategyType(StrategyType.DCA);
      } else {
        setStrategyType(StrategyType.FixedRisk);
      }

      setStrategy(result);
    });
  }, [strategyId]);

  useEffect(() => {
    if (!strategy) return;

    const settings = { ...strategy.settings };

    switch (strategyType) {
      case StrategyType.FixedRisk: {
        delete settings.average;
        if (!('stop_loss' in settings)) {
          settings.stop_loss = 1.0;
        }
        break;
      }
      case StrategyType.DCA: {
        delete settings.stop_loss;
        if (!settings.average) {
          settings.average = {
            orders_type: OrderTypes.Market,
            orders: [[1, 1.5]],
          }; //
        }
      }
    }

    setStrategy({ ...strategy, settings });
  }, [strategyType]);

  useEffect(() => {
    if (isStrategySettings || isStrategyTypeSettings) {
      setBackground('#F4F4F4');
    } else {
      setBackground('white');
    }
  }, [isStrategySettings, isStrategyTypeSettings]);

  useEffect(() => {
    if (strategy) {
      if (strategiesPool[strategy._id] && strategiesPool[strategy._id].name !== strategy.name) {
        updateHistoryName(strategy._id, strategy.name);
      }

      strategiesPool[strategy._id] = strategy;

      if (oldStrategy?._id !== strategy._id) {
        backTest(strategy);
      }
    } else {
      backTest(null);
    }

    oldStrategy = strategy;
  }, [strategy]);

  if (strategy === null) return;

  const saveStrategy = (): void => {
    if (strategyName !== strategy.name) {
      setStrategy({ ...strategy, name: strategyName });
    }

    apiStrategy
      .saveStrategy({ ...strategy, name: strategyName })
      .then(() => {
        backTest({ ...strategy, name: strategyName });
      })
      .catch(() => {});
  };

  const addNewCond = (groupId: number): void => {
    const indicators = strategy.indicators.slice();
    const group = indicators[groupId];

    if (!group) return;

    group.push({
      name: IndicatorsList.RSI_OverLevels,
      settings: {
        interval: 14,
        over_sold: 30,
        over_bought: 70,
        smooth_type: IndicatorsList.SMA,
        source: IndicatorSource.Close,
      },
    });

    setStrategy({ ...strategy, indicators });
  };
  const removeCond = (condId: number): void => {
    const indicators = strategy.indicators.slice();
    indicators.splice(condId, 1);
    setStrategy({ ...strategy, indicators });
  };
  const removeCondGroup = (groupId: number, condId: number): void => {
    const indicators = strategy.indicators.slice();
    const group = indicators[groupId];

    if (!group || group[condId] === undefined || (indicators.length === 1 && group.length === 1))
      return;

    group.splice(condId, 1);

    if (group.length === 0) {
      indicators.splice(groupId, 1);
    }

    setStrategy({ ...strategy, indicators });
  };

  const addNewCondGroup = (): void => {
    strategy.indicators.push([]);

    addNewCond(strategy.indicators.length - 1);
  };
  const changeCond = (groupId: number, condId: number, value: IndicatorsList): void => {
    const indicators = strategy.indicators.slice();
    const group = indicators[groupId];
    if (!group?.[condId]) return;

    group[condId] = {
      name: value,
      settings: { ...strategySettings[value] },
    };

    setStrategy({ ...strategy, indicators });
  };
  const changeSettings = (settings: ISettingsIndicator, name: number): void => {
    if (isStrategySettings === null) return;

    const indicators = strategy.indicators.slice();
    const group = indicators[isStrategySettings[0]];

    if (!group?.[isStrategySettings[1]]) return;
    group[isStrategySettings[1]].name = name;
    group[isStrategySettings[1]].settings = settings;

    setStrategy({ ...strategy, indicators });
  };

  return (
    <div className="strategy">
      {isStrategySettings === null && !isStrategyTypeSettings && (
        <>
          <div className="strategy__main">
            <StrategySwitcher
              options={constructorPages}
              activeOption={activePage}
              onChange={setActivePage}
              height={35}
              activeColor={'#694EF0'}
            />

            {activePage === 0 && (
              <>
                <StrategyName
                  onChange={(name) => {
                    setStrategyName(name);
                  }}
                  name={strategy?.name ? strategyName : ''}
                  strategyNames={strategyNames}
                />
                <StrategyConstructor
                  strategy={strategy}
                  setStrategyType={setStrategyType}
                  strategyType={strategyType}
                  setStrategyTypeSettings={setStrategyTypeSettings}
                  setStrategy={setStrategy}
                  addNewCondGroup={addNewCondGroup}
                  addNewCond={addNewCond}
                  removeCondGroup={removeCondGroup}
                  removeCond={removeCond}
                  changeCond={changeCond}
                  openSettings={(groupId, condId) => {
                    setStrategySettings([groupId, condId]);
                  }}
                />
                <StrategyOtherFilters />
              </>
            )}

            {activePage === 1 && (
              <>
                {' '}
                <StrategyProperties
                  strategy={strategy}
                  property={strategy.property}
                  setStrategy={setStrategy}
                />
              </>
            )}
          </div>
        </>
      )}

      {activePage === 1 ||
        (isStrategySettings === null && !isStrategyTypeSettings && (
          <div className="strategy__footer">
            <button className="strategy__footer-automatize">Automatize</button>
            <button
              className="strategy__footer-save"
              style={{
                backgroundColor:
                  isChartLoading ||
                  strategyName.length === 0 ||
                  strategyNames.findIndex(
                    (name) => name.toLowerCase().trim() === strategyName.toLowerCase().trim(),
                  ) !== -1
                    ? 'gray'
                    : '',
                cursor:
                  isChartLoading ||
                  strategyName.length === 0 ||
                  strategyNames.findIndex(
                    (name) => name.toLowerCase().trim() === strategyName.toLowerCase().trim(),
                  ) !== -1
                    ? 'not-allowed'
                    : '',
              }}
              onClick={() => {
                if (
                  isChartLoading ||
                  strategyName.length === 0 ||
                  strategyNames.findIndex(
                    (name) => name.toLowerCase().trim() === strategyName.toLowerCase().trim(),
                  ) !== -1
                )
                  return;
                saveStrategy();
              }}
            >
              Save settings
            </button>
          </div>
        ))}

      {isStrategySettings && (
        <StrategySettings
          onClose={() => {
            setStrategySettings(null);
          }}
          changeCond={(value) => {
            changeCond(isStrategySettings[0], isStrategySettings[1], value);
          }}
          onChange={changeSettings}
          indicator={strategy.indicators[isStrategySettings[0]]?.[isStrategySettings[1]]}
          isStrategySettings={isStrategySettings}
          indicators={strategy.indicators.slice()}
        />
      )}
      {isStrategyTypeSettings && (
        <StrategyTypeSettings
          strategy={strategy}
          setStrategy={setStrategy}
          type={strategyType}
          onClose={() => {
            // setting тут
            setStrategyTypeSettings(false);
          }}
        />
      )}
    </div>
  );
};
