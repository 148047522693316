import { type FC, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChartFibonacciLineIcon } from '../../../../../assets/icons/AngleIcon';
import classStyles from '../ChartWrapper.module.css';
import classNames from 'classnames';
import { type ChartEditorFigure } from '../../../../../enums/ChartEditorFigure';
import { PortalWrapper } from '../../../../../components/PortalWrapper';
import { AsidePopperItem } from './AsidePopperItem';

interface IProps {
  editorKeys: ChartEditorFigure[];
  onUpdateEditor: (newEditor: ChartEditorFigure) => void;
}

export const AsidePopper: FC<IProps> = ({ editorKeys, onUpdateEditor }) => {
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'right-start',
  });

  const togglePupUp = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  function handleDocumentClick(event: Event): void {
    if (popperRef.current.contains(event.target)) {
      return;
    }
    setIsOpen(false);
  }

  const selectEditor = (key: ChartEditorFigure): void => {
    onUpdateEditor(key);
    setIsOpen(false);
  };

  return (
    <>
      <div className={classStyles.asideArrowWrapper} ref={referenceRef} onClick={togglePupUp}>
        <ChartFibonacciLineIcon
          className={classNames(classStyles.asideArrowIcon, {
            [classStyles.asideArrowIconSelect]: isOpen,
          })}
        />
      </div>

      {
        <PortalWrapper wrapperId="aside-portal">
          <div
            ref={popperRef}
            className={classNames(classStyles.popupWindowWrapper, {
              [classStyles.popupWindowWrapperShow]: isOpen,
            })}
            style={styles.popper}
            {...attributes.popper}
          >
            {editorKeys.map((editorKey) => (
              <AsidePopperItem key={editorKey} {...{ editorKey, selectEditor }} />
            ))}
          </div>
        </PortalWrapper>
      }
    </>
  );
};
