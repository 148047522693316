import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IUpdateListEditor, type State } from './types';
import { ChartEditorFigure } from '../../enums/ChartEditorFigure';
import { type IChartConfig } from '../../interfaces/chartData';

const initialState: State = {
  selectedEditor: null,
  isLock: false,
  isVisible: true,
  isDestroy: false,
  listEditors: [
    ChartEditorFigure.HORIZONTAL_LINE,
    ChartEditorFigure.PRICE_CHANNEL_LINE,
    ChartEditorFigure.CIRCLE,
    ChartEditorFigure.FIBONACCI_LINE,
    ChartEditorFigure.XABCD,
    ChartEditorFigure.MAGNET,
  ],
  date: {
    dateFrom: null,
    dateTo: null,
  },
  isDynamicBackTest: false,
};

const chartReducer = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setSelectedEditor: (state, action: PayloadAction<ChartEditorFigure | null>) => {
      state.isDestroy = false;
      state.selectedEditor = action.payload;
    },
    updateListEditor: (state, action: PayloadAction<IUpdateListEditor>) => {
      const idx = state.listEditors.indexOf(action.payload.prevEditor);
      state.listEditors[idx] = action.payload.newEditor;
    },
    setIsLose(state) {
      state.isLock = !state.isLock;
    },
    setIsVisible(state) {
      state.isVisible = !state.isVisible;
    },
    setIsDestroy(state) {
      state.isDestroy = true;
    },

    setChartConfig: (state, action: PayloadAction<IChartConfig | null>) => {
      state.date = action.payload;
    },

    setDynamicBackTest: (state, action: PayloadAction<boolean>) => {
      state.isDynamicBackTest = action.payload;
    },
  },
});

export const {
  setSelectedEditor,
  updateListEditor,
  setIsLose,
  setIsVisible,
  setIsDestroy,
  setChartConfig,
  setDynamicBackTest,
} = chartReducer.actions;

export default chartReducer.reducer;
