import { type IChartHeaderProps } from '../../interfaces/chartData';
import { type HistorySectionModes } from '../../enums/HistorySectionMode';
import { type ICandleData } from '../../services/api/Strategy/types/IStrategyResponse';
import {
  Exchanges,
  type IStatistics,
  type ITradeData as TradesIndicatorValue,
} from '../../interfaces/IStrategy';
import { type StrategyTab } from '../../enums/StrategyTab';
import { TIMEFRAMES } from '../../configs/chart';
import { type ThemeState } from '../../redux/theme/reducer';
import useToggleTheme from './useToggle';

export interface IHomePageState {
  chartContainerSizes: {
    height: number;
    width: number;
  };
  historyContainerSize: {
    height: number;
    width: number;
  };
  chartHeaderData: IChartHeaderProps;
  historySectionMode: HistorySectionModes | null;
  candles: ICandleData[];
  trades: TradesIndicatorValue[];
  stats: IStatistics;
  strategyOn: boolean;
  isChartLoading: boolean;
  strategyActiveTab: StrategyTab | null;
  isFirstCreateStrategy: boolean;
  mobileContent: 'chart' | 'terminal' | 'strategy';
  theme: ThemeState;
  toggleTheme?: () => void;
  currentStrategyType?: string;
}

export const DefaultHomePageState: IHomePageState = {
  chartHeaderData: {
    data: {
      baseAsset: 'BTC',
      quoteAsset: 'USDT',
      exchange: Exchanges.BinanceFutures,
      timeframe: TIMEFRAMES['15m'],
    },
  },
  chartContainerSizes: {
    height: 500,
    width: 500,
  },
  historyContainerSize: {
    height: 500,
    width: 300,
  },
  historySectionMode: null,
  candles: [],
  trades: [],
  stats: {
    deposit: 0,
    net_profit: [0, 0], // USD, Percent
    trades_count: [0, 0], // Short, Long
    commission_paid: [0, 0], // Short, Long
    profit_percent: [0, 0, 0], // Short, Long, Both
    pnl_trades: [0, 0],
    liquidation_count: [0, 0, 0],
    max_drawdown: [
      [0, 0], // Short
      [0, 0], // Long
      [0, 0], // Both
    ],
    avg_trade_profit: [
      [0, 0], // Short
      [0, 0], // Long
      [0, 0], // Both
    ],
  },
  strategyOn: false,
  isChartLoading: false,
  strategyActiveTab: null,
  isFirstCreateStrategy: true,
  mobileContent: 'chart',
  theme: {
    homeBackground: '#0a081a',
    homeHeaderBackground: '#0a081a',
    textColor: 'white',
    chartBackground: '#0c1122',
    chartHeaderBakcground: '#0a081a',
    chartAsideMenuBackground: '#0a081a',
    chartAsideMenuIconFill: 'white',
    historyBackground: 'rgba(19, 14, 45, 1)',
  },
  toggleTheme: (): any => {
    useToggleTheme();
  },
  currentStrategyType: '',
};
