import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { IAuthFormProps } from '../Auth';
import { Components } from '../Auth';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';
import ReCaptchaComponent from '../../../components/ReCaptchaComponent/ReCaptchaComponent';

const PasswordRecovery: FC<IAuthFormProps> = ({ setComponent, goNext }) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const email = useRef<HTMLInputElement>();
  const recaptchaStatus = useRef<boolean>(false);

  useEffect(() => {
    localStorage.removeItem('recovery_recaptcha_token');
    localStorage.removeItem('recovery_email');
  }, []);

  const onChangeInput = (): void => {
    if (email.current.value) {
      localStorage.setItem('recovery_email', email.current.value);
    } else {
      localStorage.removeItem('recovery_email');
    }

    setDisabled(!email.current?.value || !recaptchaStatus.current);
  };

  return (
    <form
      className="auth-form"
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div
        className="auth-form__back"
        onClick={() => {
          setComponent(Components.LOGIN);
        }}
      >
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66699 7.00008L0.959885 6.29297L0.252779 7.00008L0.959885 7.70719L1.66699 7.00008ZM17.667 8.00008C18.2193 8.00008 18.667 7.55237 18.667 7.00008C18.667 6.4478 18.2193 6.00008 17.667 6.00008V8.00008ZM6.29322 0.959641L0.959885 6.29297L2.3741 7.70719L7.70743 2.37385L6.29322 0.959641ZM0.959885 7.70719L6.29322 13.0405L7.70743 11.6263L2.3741 6.29297L0.959885 7.70719ZM1.66699 8.00008H17.667V6.00008H1.66699V8.00008Z"
            fill="#288DF5"
          />
        </svg>

        <span>Sign In or Sign Up</span>
      </div>

      <div className="auth-form-header">
        <span className="auth-form-header__title">Password recovery</span>
        <p className="auth-form-header__description">Please enter your email</p>
      </div>

      <div className="auth-form-body">
        <GInput
          reference={email}
          type={InputTypes.EMAIL}
          name="email"
          placeholder="Email"
          onChange={onChangeInput}
        />
      </div>

      <ReCaptchaComponent
        class="auth-form-captcha"
        callback={(state, token) => {
          setDisabled(!email.current?.value || !state);

          recaptchaStatus.current = state;

          if (state) {
            localStorage.setItem('recovery_recaptcha_token', token);
          } else {
            localStorage.removeItem('recovery_recaptcha_token');
          }
        }}
      />

      <div className="auth-form-controls auth-form-controls_half">
        <GButton
          title="Send code"
          type={ButtonTypes.BUTTON}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={disabled}
          onClick={goNext}
        />
      </div>
    </form>
  );
};

export default PasswordRecovery;
