import React, { type FC } from 'react';
import classNames from 'classnames';

import { PanelResizeHandle, type PanelResizeHandleProps } from 'react-resizable-panels';

import styles from './ResizableHandleStyles.module.css';

export const ResizeHandle: FC<PanelResizeHandleProps> = (props) => {
  return (
    <PanelResizeHandle {...props} className={classNames(styles.resizeHandleOuter, props.className)}>
      <div className={styles.resizeHandleInner}>
        <svg className={styles.icon} viewBox="0 0 24 24">
          <path
            fill="white"
            d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
          />
        </svg>
      </div>
    </PanelResizeHandle>
  );
};
