import { type RootState, type Account } from './../../interfaces/MenuPage';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountData: {
    selectedAccountIndex: '1',
    accounts: [
      {
        id: '1',
        name: 'Account 1',
        selectedPlatform: 'Binance',
        platforms: [
          {
            name: 'Binance',
            accounts: [],
          },
        ],
      },
      {
        id: '2',
        name: 'Account 2',
        selectedPlatform: 'Binance',
        platforms: [
          {
            name: 'Binance',
            accounts: [],
          },
        ],
      },
    ],
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    switchActiveAccount: (state, action) => {
      state.accountData.selectedAccountIndex = action.payload;
    },
    createPlatformAccount: (state, action) => {
      const newAccount = {
        id: Math.random().toString(36).substr(2, 9),
        name: action.payload.name,
        status: false,
        future: false,
        spot: false,
        readOnly: false,
        apiKey: action.payload.apiKey,
        secretKey: action.payload.secretKey,
        isEdit: false,
      };

      // Find the Binance platform
      const binancePlatform = state.accountData.accounts
        .find((account) => account.id === state.accountData.selectedAccountIndex)
        ?.platforms.find((p) => p.name === 'Binance');

      // Check if there are any existing accounts in Binance platform
      const isFirstAccount = binancePlatform && binancePlatform.accounts.length === 0;

      // Set the status of all other accounts in Binance platform to false
      if (binancePlatform) {
        // Set status to true only for the first account
        newAccount.status = isFirstAccount;

        // Add the new account to Binance platform
        binancePlatform.accounts.push(newAccount);
      }
    },
    savePlatformAccountChanges: (state, action) => {
      const accountId = action.payload.id;
      const { accounts } =
        state.accountData.accounts
          .find((account) => account.id === state.accountData.selectedAccountIndex)
          .platforms.find(
            (p) =>
              p.name ===
              state.accountData.accounts.find(
                (account) => account.id === state.accountData.selectedAccountIndex,
              ).selectedPlatform,
          ) || {};
      const accountToUpdate = accounts?.find((a) => a.id === accountId);
      if (accountToUpdate) {
        accounts.forEach((a) => {
          a.isEdit = false;
          if (a.id === accountId) {
            a.apiKey = action.payload.apiKey;
            a.secretKey = action.payload.secretKey;
            a.name = action.payload.name;
          }
        });
      }
    },
    editPlatformAccount: (state, action) => {
      const accountId = action.payload;
      const { accounts } =
        state.accountData.accounts
          .find((account) => account.id === state.accountData.selectedAccountIndex)
          .platforms.find(
            (p) =>
              p.name ===
              state.accountData.accounts.find(
                (account) => account.id === state.accountData.selectedAccountIndex,
              ).selectedPlatform,
          ) || {};
      const accountToUpdate = accounts?.find((a) => a.id === accountId);

      if (accountToUpdate) {
        accounts.forEach((a) => {
          a.isEdit = a.id === accountId;
        });
      }
    },
    switchActivePlatformAccount: (state, action) => {
      const accountId = action.payload;
      const { accounts } =
        state.accountData.accounts
          .find((account) => account.id === state.accountData.selectedAccountIndex)
          .platforms.find(
            (p) =>
              p.name ===
              state.accountData.accounts.find(
                (account) => account.id === state.accountData.selectedAccountIndex,
              ).selectedPlatform,
          ) || {};
      const accountToUpdate = accounts?.find((a) => a.id === accountId);
      if (accountToUpdate) {
        accounts.forEach((a) => {
          a.status = a.id === accountId;
        });
      }
    },
    deletePlatformAccount: (state, action) => {
      const accountId = action.payload;
      const { accounts } =
        state.accountData.accounts
          .find((account) => account.id === state.accountData.selectedAccountIndex)
          .platforms.find(
            (p) =>
              p.name ===
              state.accountData.accounts.find(
                (account) => account.id === state.accountData.selectedAccountIndex,
              ).selectedPlatform,
          ) || {};
      const accountIndex = accounts?.findIndex((a) => a.id === accountId);
      if (accountIndex !== -1) {
        accounts.splice(accountIndex, 1);
      }
    },
  },
});

export const {
  createPlatformAccount,
  savePlatformAccountChanges,
  editPlatformAccount,
  switchActivePlatformAccount,
  deletePlatformAccount,
  switchActiveAccount,
} = accountSlice.actions;
export const selectBinanceAccounts = (state: RootState): Account[] =>
  state.accountData.accountData.accounts
    .find((account) => account.id === state.accountData.accountData.selectedAccountIndex)
    .platforms.find((p) => p.name === 'Binance')?.accounts || [];
export const selectUserAccounts = (state: RootState): { id: string; name: string }[] => {
  return state.accountData.accountData.accounts.map((account) => ({
    id: account.id,
    name: account.name,
  }));
};
export default accountSlice.reducer;
