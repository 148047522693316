import { ChartTime } from '../enums/HistoryChartTabs';

export const TIMEFRAMES: Record<string, number> = {
  '1s': 1000,
  '5s': 5000,
  '15s': 15000,
  '30s': 30000,
  '1m': 60000,
  '3m': 3 * 60000,
  '5m': 5 * 60000,
  '15m': 15 * 60000,
  '30m': 30 * 60000,
  '1h': 60 * 60000,
  '4h': 4 * 60 * 60000,
  '1d': 24 * 60 * 60000,
  '1w': 7 * 24 * 60 * 60000,
};

export const GET_TIMEFRAME_NAME = (tf: number): string | null => {
  for (const i in TIMEFRAMES) {
    if (TIMEFRAMES[i] === tf) return i;
  }
  return null;
};

export const stepPeriods: Partial<Record<ChartTime, number>> = {
  [ChartTime.DAY]: TIMEFRAMES['1w'],
  [ChartTime.MONTH]: TIMEFRAMES['1d'] * 365,
};
