import { type FC, useEffect, useState } from 'react';
import { StrategySelector } from '../StrategySelector/StrategySelector';
import {
  type IStrategy,
  MarginType,
  OrderSizeType,
  OrderTypes,
  BaseCurrencyType,
} from '../../../../../../interfaces/IStrategy';
import GTumbler from '../../../../../../components/GTumbler/GTumbler';
// import CustomNumberInput from '../CustomInput/CustomNumberInput';

interface IProps {
  strategy: IStrategy;
  property: IStrategy['property'];
  setStrategy: (strategy: IStrategy) => void;
}

const properties = [
  {
    key: 'deposit',
    name: 'Deposit',
    type: 'number',
    min: 10,
    max: 1000000,
  },
  {
    key: 'leverage',
    name: 'Leverage',
    suffix: 'X',
    type: 'number',
    min: 1,
    max: 200,
  },
  {
    key: 'order_size_type',
    name: 'Entry size type',
    type: 'select',
    options: [
      {
        id: OrderSizeType.Dollar,
        name: 'Fixed $',
      },
      {
        id: OrderSizeType.Percent,
        name: 'C.I. %',
      },
    ],
  },

  {
    key: 'order_size_dollar.long',
    name: 'Entry size: Long trades ($)',
    type: 'number',
    min: 10,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_dollar.short',
    name: 'Entry size: Short trades ($)',
    type: 'number',
    min: 10,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_percent.long',
    name: 'Entry size: Long trades (%)',
    type: 'number',
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },
  {
    key: 'order_size_percent.short',
    name: 'Entry size: Short trades (%)',
    type: 'number',
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },

  {
    key: 'base_currency',
    name: 'Base currency',
    type: 'select',
    options: [
      {
        id: BaseCurrencyType.USDT,
        name: 'USDT',
      },
    ],
  },
  {
    key: 'type_closing_orders',
    name: 'Type of closing orders',
    type: 'select',
    options: [
      {
        id: OrderTypes.Market,
        name: 'Market',
      },
      {
        id: OrderTypes.Limit,
        name: 'Limit',
      },
    ],
  },
  {
    key: 'commission_limit',
    name: 'Commission (Limit)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Limit,
  },
  {
    key: 'commission_market',
    name: 'Commission (Market)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Market,
  },
  {
    key: 'margin_type',
    name: 'Margin Mode',
    type: 'select',
    options: [
      {
        id: MarginType.Isolated,
        name: 'Isolated',
      },
      {
        id: MarginType.Cross,
        name: 'Cross',
      },
    ],
  },
  {
    key: 'infinite_balance',
    name: 'Infinite Balance',
    type: 'switch',
  },
];

export const StrategyProperties: FC<IProps> = (props) => {
  const [property, setProperty] = useState<IStrategy['property']>();
  useEffect(() => {
    setProperty({ ...props.property, base_currency: BaseCurrencyType.USDT });
  }, [props.property]);
  useEffect(() => {
    console.log('property', property);
  }, [property]);

  if (!property) return;

  const getValue = (key: string): string => {
    let val: unknown = property;

    key.split('.').forEach((e) => {
      val = val[e];
    });

    return val as string;
  };

  return (
    <>
      {properties.map(
        (e) =>
          (e.filter?.(property) ?? true) && (
            <div key={e.key + e.name} className="strategy__main__inline">
              <h4>{e.name}</h4>
              {e.type === 'switch' && (
                <GTumbler
                  name={e.name}
                  checked={property.infinite_deposit}
                  onToggle={() => {
                    setProperty({ ...property, infinite_deposit: !property.infinite_deposit });
                  }}
                  size="small"
                />
              )}
              {e.type === 'select' && (
                <StrategySelector
                  width="110px"
                  height={4}
                  options={e.options}
                  activeOption={property[e.key]}
                  onChange={(value) => {
                    if (e.key !== 'base_currency') {
                      setProperty({ ...property, [e.key]: value });
                      // props.setStrategy(e.key, value);
                    }
                  }}
                />
              )}
              {e.type === 'number' && (
                <>
                  <input
                    type="text"
                    className="strategy__main__inline-input"
                    value={`${getValue(e.key)}` + (e.suffix ?? '')}
                    onChange={(ev) => {
                      let value = parseFloat(ev.target.value);
                      if (isNaN(value)) value = e.min;
                      if (value < e.min) value = e.min;
                      if (value > e.max) value = e.max;
                      const temp = { ...property };
                      const keys = e.key.split('.');
                      // TODO: For Many Keys
                      if (keys.length === 2) {
                        temp[keys[0]][keys[1]] = value;
                      } else {
                        temp[keys[0]] = value;
                      }
                      setProperty(temp);
                    }}
                  />
                </>
              )}
            </div>
          ),
      )}
      <div
        style={{ color: 'white' }}
        className="strategy__saveButton"
        onClick={() => {
          props.setStrategy({ ...props.strategy, property });
        }}
      >
        Save
      </div>
    </>
  );
};
