import { useState } from 'react';

import './MenuAddAccount.scss';
import AddIcon from '../../../../assets/images/MenuPage/AddIcon.svg';
import AddAccountPopUp from '../AddAccountPopUp';

const AddAccount = (): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  // handling inputs

  if (!isOpened) {
    return (
      <button
        onClick={() => {
          setIsOpened((prev) => !prev);
        }}
        className="addButton"
      >
        <img src={AddIcon} alt="AddIcon" />
        Add account
      </button>
    );
  }
  return (
    <AddAccountPopUp
      closePopUp={() => {
        setIsOpened(false);
      }}
    />
  );
};
export default AddAccount;
