export enum Exchanges {
  BinanceFutures,
}

export enum StrategyModes {
  ONE_SIDE = 0,
  HEDGE = 1,
}

export enum IndicatorsList {
  RSI_OverLevels,
  ATR,
  SMA,
  EMA,
  WSMA,
  RSI_CenterCrossOver,
  RSI_CrossMA,
  RSI_DivergenceConvergence,
  RSI_ExtremeReversal,
}

export enum TradesDirections {
  Short,
  Long,
  Both,
}

export enum IndicatorSource {
  Close,
  Low,
  Open,
  High,
}

export enum OrderSizeType {
  Percent,
  Dollar,
}
export enum BaseCurrencyType {
  USDT,
}

export enum OrderTypes {
  Market,
  Limit,
}

export enum MarginType {
  Isolated,
  Cross,
}

export interface IChartConfig {
  date_from: number;
  date_to: number;
  theme?: any;
}

interface IBaseStrategySettings {
  deposit: number;
  order_size_type: OrderSizeType;
  // order_size_dollar: number;
  // order_size_percent: number;
  order_size_dollar: {
    long: number;
    short: number;
  };
  order_size_percent: {
    long: number;
    short: number;
  };

  leverage: number;
  type_closing_orders: OrderTypes;
  commission_limit: number;
  commission_market: number;
  base_currency?: BaseCurrencyType;
  infinite_deposit: boolean;
}

interface IStrategySettings {
  direction: TradesDirections;
  stop_loss?: number;
  take_profits: [number, number][]; // Quantity Percent, Length
  average?: {
    orders_type: OrderTypes;
    orders: [number, number][]; // Factor, Length
  };
}

export interface IStrategy {
  _id?: string;
  name: string;
  // base_asset: string;
  // quote_asset: string;
  // custom_ticker?: {
  //   id: string;
  //   assets: {
  //     base_asset: string;
  //     quote_asset: string;
  //     percent: number;
  //   }[];
  // };
  ticker?: {
    base_asset?: string;
    quote_asset?: string;
    id?: string;
    assets?: {
      base_asset: string;
      quote_asset: string;
      percent: number;
    }[];
  };
  // ticker?:
  //   | {
  //       base_asset: string;
  //       quote_asset: string;
  //     }
  //   | {
  //       id: string;
  //       assets: {
  //         base_asset: string;
  //         quote_asset: string;
  //         percent: number;
  //       }[];
  //     };
  timeframe: number;
  exchange: Exchanges;
  mode: StrategyModes;
  indicators: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];
  settings: IStrategySettings;
  property: IBaseStrategySettings;
}

interface IHedgeStrategyProperties {
  indicators: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];
  settings: IStrategySettings;
  timeframe: number;
}

interface IHedgeStrategiesData {
  long: IHedgeStrategyProperties;
  short: IHedgeStrategyProperties;
}

export interface IHedgeStrategyData {
  _id?: string;
  name: string;
  ticker?: {
    base_asset?: string;
    quote_asset?: string;
    id?: string;
    assets?: {
      base_asset: string;
      quote_asset: string;
      percent: number;
    }[];
  };
  exchange: Exchanges;
  mode: StrategyModes;
  property: IBaseStrategySettings;
  strategies: IHedgeStrategiesData;
}

//
//

export interface ITakeProfitData {
  time?: number;
  reach_price?: number;
  price: number[];
  qty: number;
  profit: number;
  qty_quote: number;
  qty_percent: number;
  length: number;
  fee?: number;
}

export interface IAverageData {
  time?: number;
  fee?: number;
  price: number;
  factor: number;
  qty: number;
}

export interface IStopLossData {
  time?: number;
  fee?: number;
  price: number;
}

export interface ILiquidationData {
  time?: number;
  fee?: number;
  price: number[];
}

export interface ITradeData {
  time: number; // Candle's time, when trade was open
  exit_time: number;
  enter_price: number;
  enter_fee: number;
  qty: number;
  qty_quote: number;
  take_profit_qty: number; // Amount that sold by TakeProfits
  average_qty?: number; // Amount that bought by DCA
  side: TradesDirections;
  take_profits: ITakeProfitData[];
  stop_loss?: IStopLossData;
  liquidation: ILiquidationData;
  averages?: IAverageData[];
  average_enter: number;
  average_exit: number;
  pnl: number;
}

export interface IStatistics {
  deposit: number;
  net_profit: [number, number]; // USD, Percent
  trades_count: [number, number]; // Short, Long
  commission_paid: [number, number]; // Short, Long
  profit_percent: [number, number, number]; // Short, Long, Both
  liquidation_count: [number, number, 0 | 1];
  pnl_trades: [number, number];
  max_drawdown: [
    [number, number], // Short
    [number, number], // Long
    [number, number], // Both
  ];
  avg_trade_profit: [
    [number, number], // Short
    [number, number], // Long
    [number, number], // Both
  ];
}

//
//
//

export interface ISettingsIndicator {
  interval: number;
  smooth_type?: IndicatorsList.SMA | IndicatorsList.EMA | IndicatorsList.WSMA;
  over_sold?: number;
  over_bought?: number;
  center_line?: number;
  source?: IndicatorSource;
  reverse?: boolean;
  ma_percent?: number;
  ma_type?: IndicatorsList.SMA | IndicatorsList.EMA | IndicatorsList.WSMA;
}
