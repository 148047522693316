import { type FC, useEffect, useState, useRef } from 'react';
import { IndicatorsList, type ISettingsIndicator } from '../../../../../../interfaces/IStrategy';
import './StrategyConditions.scss';
import { strategySettingsConfig } from '../../../../../../configs/strategySettings';
import { StrategySelectorXL } from '../StrategySelectorXL/StrategySelectorXL';
import CancelIcon from '../../../../../../assets/images/Strategy/CancelIcon.svg';
import AddIcon from '../../../../../../assets/images/Strategy/AddIcon.svg';

interface IProps {
  list: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];

  addNewCond: (groupId: number) => void;
  addNewCondGroup: () => void;
  removeCondGroup: (groupId: number, condId: number) => void;
  removeCond: (condId: number) => void;
  changeCond: (groupId: number, condId: number, value: IndicatorsList) => void;
  openSettings: (groupId: number, condId: number) => void;
}

const strategyList = [
  {
    id: IndicatorsList.RSI_OverLevels,
    name: 'RSI',
    desc: 'Overbought/Oversold Levels',
  },
  {
    id: IndicatorsList.ATR,
    name: 'ATR',
  },

  {
    id: IndicatorsList.SMA,
    name: 'MA',
    desc: 'Crossover/crossunder',
  },
];

export const StrategyConditions: FC<IProps> = (props) => {
  const [list, setList] = useState(props.list);
  const listToShow = list.map((e, index) => {
    return { ...e, name: `Condition #${index + 1}`, id: index };
  });
  const [selectedCondition, setSelectedCondition] = useState(listToShow[0]);
  const [deletedCondtitionIndex, setDeletedCondtitionIndex] = useState<number | null>(null);
  useEffect(() => {
    setList(props.list);
  }, [props.list]);
  const prevListLengthRef = useRef(props.list.length);
  useEffect(() => {}, [selectedCondition]);

  useEffect(() => {
    const prevListLength = prevListLengthRef.current;
    const currentListLength = props.list.length;
    if (prevListLength < currentListLength) {
      setSelectedCondition(listToShow[currentListLength - 1]);
    }
    if (prevListLength > currentListLength) {
      if (selectedCondition.id !== 0) {
        if (
          selectedCondition.id === currentListLength ||
          selectedCondition.id === deletedCondtitionIndex
        ) {
          setSelectedCondition(listToShow[selectedCondition.id - 1]);
        }
      }
    }
    // Оновлення значення попередньої довжини
    prevListLengthRef.current = currentListLength;
  }, [props.list.length]);

  return (
    <div className="strategy-cond">
      <div className="strategy-cond__menu">
        <div className="strategy-cond__menu__conditions">
          {listToShow.map((e, index) => {
            // console.log(e.id, selectedCondition.id);
            return (
              <div
                className={
                  'strategy-cond__menu__condition' +
                  (e.id === selectedCondition?.id ? ' strategy-cond__menu__condition_selected' : '')
                }
                onClick={() => {
                  setSelectedCondition(e);
                }}
                key={e.id}
              >
                {e.name}
                {e.id !== 0 && (
                  <img
                    src={CancelIcon}
                    alt="X"
                    onClick={(e) => {
                      e.stopPropagation();
                      // props.removeCond(index, 0);
                      setDeletedCondtitionIndex(index);
                      props.removeCond(index);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <button
          className="strategy-cond__menu__add"
          onClick={() => {
            props.addNewCondGroup();
          }}
        >
          <img src={AddIcon} alt="Add" />
        </button>
      </div>
      {list.map((e, groupId) => {
        if (groupId !== selectedCondition?.id && list.length > 1) {
          return null;
        }
        return (
          <div className="strategy-cond__group" key={groupId}>
            {e.map((strategy, condId) => {
              // console.log('strategy', strategy);
              const list = [];

              let activeName = '';
              let activeDesc = '';

              for (const groups of strategySettingsConfig) {
                for (const e of groups.conditions) {
                  if (e.id === strategy.name) {
                    activeName = groups.name;
                    activeDesc = e.name;
                    break;
                  }
                }
              }

              strategyList.forEach((e) => {
                if (e.name === activeName && e.id !== strategy.name) {
                  list.push({
                    id: strategy.name,
                    name: e.name,
                    desc: activeDesc ?? '',
                  });

                  return;
                }

                list.push({
                  id: e.id,
                  name: e.name,
                  desc: e.desc ?? '',
                });
              });

              return (
                <div className="strategy-cond__group__elem" key={condId}>
                  {condId > 0 ? (
                    <StrategySelectorXL
                      options={list}
                      activeOption={strategy.name}
                      onChange={(e) => {
                        props.changeCond(groupId, condId, e);
                      }}
                      openSettings={() => {
                        props.openSettings(groupId, condId);
                      }}
                      deleteOption={() => {
                        props.removeCondGroup(groupId, condId);
                      }}
                      width={'100%'}
                    />
                  ) : (
                    <StrategySelectorXL
                      options={list}
                      activeOption={strategy.name}
                      onChange={(e) => {
                        props.changeCond(groupId, condId, e);
                      }}
                      openSettings={() => {
                        props.openSettings(groupId, condId);
                      }}
                      width={'100%'}
                    />
                  )}

                  {/* {(groupId > 0 || condId > 0) && (
                    <button
                      className="strategy-cond__group__elem__delete"
                      onClick={() => {
                        console.log(groupId, condId);
                        props.removeCond(groupId, condId);
                      }}
                    >
                      Delete
                    </button>
                  )} */}
                </div>
              );
            })}

            <button
              className="strategy-cond__group__add"
              onClick={() => {
                props.addNewCond(groupId);
              }}
            >
              + Add new filter
            </button>

            <div className="strategy-cond__group__line"></div>
          </div>
        );
      })}

      {/* <button className="strategy-cond__add-group" onClick={props.addNewCondGroup}>
        + Add new condition
      </button> */}
    </div>
  );
};
