import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../redux/theme/reducer';

const useToggleTheme = (): any => {
  const dispatch = useDispatch();

  const toggleThemeHandler = (): any => {
    dispatch(toggleTheme());
  };

  return toggleThemeHandler;
};

export default useToggleTheme;
