import type { Dispatch, FC, SetStateAction } from 'react';

import GButton, { ButtonColors, ButtonTypes } from '../../../../../../components/GButton/GButton';

import { Components } from '../../Components';

import oneSidePreview from '../../../../../../assets/images/Strategy/OneWayModePreview.png';
import hedgePreview from '../../../../../../assets/images/Strategy/HedgeModePreview.png';

import './strategyEditorModes.scss';
import { apiStrategy } from '../../../../../../services/api/Strategy/ApiStrategy';
import { type IStrategyAllResponse } from '../../../../../../services/api/Strategy/types/IStrategyResponse';

interface IStrategyEditorModesProps {
  addStrategyHistory: (value: IStrategyAllResponse[0]) => void;
  setStrategyId: Dispatch<SetStateAction<string>>;
  setStrategyNode: Dispatch<SetStateAction<Components>>;
}

const StrategyEditorModes: FC<IStrategyEditorModesProps> = (props) => {
  return (
    <div className="strategy-modes">
      <div className="strategy-modes-mode">
        <span className="strategy-modes-mode__name">One-Way mode</span>

        <img
          className="strategy-modes-mode__preview"
          src={oneSidePreview}
          alt="One-Way mode preview"
        />

        <p className="strategy-modes-mode__description">
          You can only open long and short trades sequentially on the one chart. The type of
          strategy for all types of trades will be the same.
        </p>

        <div className="strategy-modes-mode__select">
          <GButton
            type={ButtonTypes.BUTTON}
            title="Select"
            color={ButtonColors.VIOLET}
            bordered={false}
            filled={true}
            onClick={() => {
              (async () => {
                try {
                  const resp = await apiStrategy.addStrategy();

                  if (resp) {
                    props.addStrategyHistory({ _id: resp._id, name: resp.name });
                    props.setStrategyId(resp._id);
                    props.setStrategyNode(Components.CONSTRUCTOR);
                  }
                } catch (e) {
                  console.log(e);
                }
              })();
            }}
          />
        </div>
      </div>

      <div className="strategy-modes-mode">
        <span className="strategy-modes-mode__name">Hedge mode</span>

        <img
          className="strategy-modes-mode__preview"
          src={hedgePreview}
          alt="One-Way mode preview"
        />

        <p className="strategy-modes-mode__description">
          You can open and hold long and short trades at the same time. You will see two different
          charts, on each you can separately setup long and short strategies.
        </p>

        <div className="strategy-modes-mode__select">
          <GButton
            type={ButtonTypes.BUTTON}
            title="Select"
            color={ButtonColors.VIOLET}
            bordered={false}
            filled={true}
            onClick={() => {
              // dispatch(setCreatorMode(StrategyModes.HEDGE));
              // props.setStrategyNode(Components.CONSTRUCTOR);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StrategyEditorModes;
