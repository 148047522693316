import { type Dispatch, type FC, type SetStateAction, useState } from 'react';
import { StrategySwitcher } from '../StrategySwitcher/StrategySwitcher';
import { StrategyConditions } from '../StrategyConditions/StrategyConditions';
import {
  type IndicatorsList,
  type IStrategy,
  TradesDirections,
} from '../../../../../../interfaces/IStrategy';
import { StrategyType } from '../../../../../../enums/Strategy';
import { StrategySelectorXL } from '../StrategySelectorXL/StrategySelectorXL';

const tradesDirections = [
  {
    name: 'Only Long',
    id: TradesDirections.Long,
  },
  {
    name: 'Both',
    id: TradesDirections.Both,
  },
  {
    name: 'Only Short',
    id: TradesDirections.Short,
  },
];

const strategyTypes = [
  {
    name: 'DCA',
    id: StrategyType.DCA,
  },
  {
    name: 'Fixed Risk',
    id: StrategyType.FixedRisk,
  },
];

const entryConditionTypes = [
  {
    name: 'Indicators',
    id: 0,
  },
  {
    name: 'Candles',
    id: 1,
    disabled: true,
  },
];

interface IProps {
  strategy: IStrategy;
  strategyType: StrategyType;
  setStrategyTypeSettings: Dispatch<SetStateAction<boolean>>;
  setStrategyType: Dispatch<SetStateAction<StrategyType>>;
  setStrategy: Dispatch<SetStateAction<IStrategy>>;
  addNewCond: (groupId: number) => void;
  addNewCondGroup: () => void;
  removeCondGroup: (groupId: number, condId: number) => void;
  removeCond: (condId: number) => void;
  changeCond: (groupId: number, condId: number, value: IndicatorsList) => void;
  openSettings: (groupId: number, condId: number) => void;
}

export const StrategyConstructor: FC<IProps> = (props) => {
  const [activeEntryCondPage, setActiveEntryCondPage] = useState(0);

  return (
    <>
      <div className="strategy__main__elem">
        <div className="strategy__main__elem__title">Trades Direction</div>
        <StrategySwitcher
          options={tradesDirections}
          activeOption={props.strategy.settings.direction}
          onChange={(value) => (props.strategy.settings.direction = value)}
          height={25}
          width={100}
          activeColor={'#9747FF'}
        />
      </div>
      <div style={{ alignItems: 'flex-start' }} className="strategy__main__elem">
        <div className="strategy__main__elem__title">Type of strategy</div>

        <StrategySelectorXL
          options={strategyTypes}
          activeOption={props.strategyType}
          onChange={(e) => {
            props.setStrategyType(e);
          }}
          openSettings={() => {
            props.setStrategyTypeSettings(true);
          }}
          width={'100%'}
        />
      </div>
      <div className="strategy__main__elem">
        <div className="strategy__main__elem__title">Entry Conditions</div>
        <StrategySwitcher
          options={entryConditionTypes}
          activeOption={activeEntryCondPage}
          onChange={(value) => {
            setActiveEntryCondPage(value);
          }}
          height={25}
          activeColor={'#9747FF'}
        />
      </div>
      <StrategyConditions
        list={props.strategy.indicators}
        addNewCond={props.addNewCond}
        addNewCondGroup={props.addNewCondGroup}
        removeCondGroup={props.removeCondGroup}
        removeCond={props.removeCond}
        changeCond={props.changeCond}
        openSettings={props.openSettings}
      />
    </>
  );
};
