import React, { type FC, useState } from 'react';
import styles from './TabSwitcher.module.css';
import classNames from 'classnames';

interface Props {
  options: {
    tabId: string;
    tabLabel: string;
    backgroundColor?: string;
  }[];
  defaultTab?: string;
  onChange: (tab: string) => void;
  className?: string;
}

export const TabSwitcher: FC<Props> = ({ options, defaultTab, onChange, className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab ?? '');

  return (
    <div className={classNames(styles.tabSwitchWrapper, className)}>
      {options.map((option) => {
        return (
          <button
            key={option.tabId}
            style={{
              backgroundColor: activeTab === option.tabId ? option.backgroundColor : '',
              width: (100 / options.length).toFixed(2) + '%',
            }}
            className={classNames(styles.tabSwitcherItem, {
              [styles.active]: activeTab === option.tabId,
            })}
            onClick={() => {
              setActiveTab(option.tabId);
              onChange(option.tabId);
            }}
          >
            {option.tabLabel}
          </button>
        );
      })}
    </div>
  );
};
