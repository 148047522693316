import { type FC } from 'react';
import { type IStatistics } from '../../../../../../interfaces/IStrategy';
import { type ThemeState } from '../../../../../../redux/theme/reducer';

type PageFC = FC<{ data: IStatistics; theme: ThemeState }>;

const RED = '#D04243';
const GREEN = '#30D95F';

const firstPage: PageFC[] = [
  // Net Profit
  ({ data: { net_profit: netProfit }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Net Profit:
      </h3>
      <p style={{ color: netProfit[0] > 0 ? GREEN : RED }}>
        {netProfit[0].toFixed(2)}$/
        <span>{netProfit[1].toFixed(2)}%</span>
      </p>
    </div>
  ),
  ({ data: { pnl_trades: pnlTrades }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: pnlTrades[1] > 0 ? GREEN : RED }}>{pnlTrades[1].toFixed(2)}$</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: pnlTrades[0] > 0 ? GREEN : RED }}>{pnlTrades[0].toFixed(2)}$</p>
    </div>
  ),
  // Trades Count
  ({ data: { trades_count: tradesCount }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor }}>
        Total closed trades:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>
        {tradesCount[0] + tradesCount[1]}
      </p>
    </div>
  ),
  ({ data: { trades_count: tradesCount }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: theme.textColor }}>{tradesCount[1]}</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: theme.textColor }}>{tradesCount[0]}</p>
    </div>
  ),
  // Open PnL
  ({ theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor }}>
        Open PNL:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>N/A</p>
    </div>
  ),
  ({ theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: theme.textColor }}>N/A</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: theme.textColor }}>N/A</p>
    </div>
  ),
];

//
// SECOND PAGE 1
//

const secondPage: PageFC[] = [
  // Max Drawdown
  ({ data: { max_drawdown: maxDrawdown }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Max drawdown:
      </h3>
      <p style={{ color: RED }}>{maxDrawdown[2][0].toFixed(2)}$</p>
    </div>
  ),
  ({ data: { max_drawdown: maxDrawdown }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: RED }}>{maxDrawdown[1][0].toFixed(2)}$</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: RED }}>{maxDrawdown[0][0].toFixed(2)}$</p>
    </div>
  ),
  // Avg Trade Profit
  ({ data: { avg_trade_profit: avgTradeProfit }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Avg Trade Profit:
      </h3>
      <p style={{ color: avgTradeProfit[2][0] > 0 ? GREEN : RED }}>
        {avgTradeProfit[2][0].toFixed(2)}$
      </p>
    </div>
  ),
  ({ data: { avg_trade_profit: avgTradeProfit }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: avgTradeProfit[1][0] > 0 ? GREEN : RED }}>
        {avgTradeProfit[1][0].toFixed(2)}$
      </p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: avgTradeProfit[0][0] > 0 ? GREEN : RED }}>
        {avgTradeProfit[0][0].toFixed(2)}$
      </p>
    </div>
  ),
  // Percent Profitable
  ({ data: { profit_percent: profitPercent }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Percent profitable:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>
        {profitPercent[2].toFixed(2)}%
      </p>
    </div>
  ),
  ({ data: { profit_percent: profitPercent }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: theme.textColor }}>{(profitPercent[1] ?? 0).toFixed(2)}%</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: theme.textColor }}>{(profitPercent[0] ?? 0).toFixed(2)}%</p>
    </div>
  ),
];

const thirdPage: PageFC[] = [
  // Commission Paid
  ({ data: { commission_paid: commissionPaid }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Commission paid:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>
        {(commissionPaid[0] + commissionPaid[1]).toFixed(2)}$
      </p>
    </div>
  ),
  ({ data: { commission_paid: commissionPaid }, theme }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: theme.textColor }}>{commissionPaid[1].toFixed(2)}$</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: theme.textColor }}>{commissionPaid[0].toFixed(2)}$</p>
    </div>
  ),
  // Trades Liquidation
  ({ theme, data: { liquidation_count: liquidationCount } }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Trades Liquidation:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>
        {liquidationCount[0] + liquidationCount[1]}
      </p>
    </div>
  ),
  ({ theme, data: { liquidation_count: liquidationCount } }) => (
    <div className="info__element">
      <h3 className="info__element-subname">Long:</h3>
      <p style={{ color: theme.textColor }}>{liquidationCount[1]}</p>
      <h3 className="info__element-subname">Short:</h3>
      <p style={{ color: theme.textColor }}>{liquidationCount[0]}</p>
    </div>
  ),
  // Liquidation Deposit
  ({ theme, data: { liquidation_count: liquidationCount } }) => (
    <div className="info__element">
      <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '16px' }}>
        Liquidation Deposit:
      </h3>
      <p style={{ fontWeight: 700, color: theme.textColor, fontSize: '16px' }}>
        {liquidationCount[2]}
      </p>
    </div>
  ),
];

export const pages: PageFC[][] = [firstPage, secondPage, thirdPage];
