import { type FC } from 'react';

import btnBg from './head-btns.png';

import './NonStrategy.scss';
export const NonStrategy: FC = () => {
  return (
    <div className="non-strategy">
      <img className="non-strategy__bg" src={btnBg} alt="" />
      <h2 className="non-strategy__title">No data</h2>
      <p className="non-strategy__descr">
        Create a new strategy or select a saved strategy to display data
      </p>
    </div>
  );
};
