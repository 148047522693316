import { type FC } from 'react';
import { type IPassIconProps, passIconProps } from './passIconProps';

export const ChartVerticalLineSegmentIcon: FC<IPassIconProps> = (props) => {
  return (
    <svg {...passIconProps(props)} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.165603637695312,6.91465C11.748203637695312,6.70873,12.165603637695312,6.15311,12.165603637695312,5.5C12.165603637695312,4.671573,11.494033637695313,4,10.665603637695312,4C9.837176637695313,4,9.165603637695312,4.671573,9.165603637695312,5.5C9.165603637695312,6.15311,9.583007637695312,6.70873,10.165603637695312,6.91465L10.165603637695312,16.0854C9.583007637695312,16.2913,9.165603637695312,16.846899999999998,9.165603637695312,17.5C9.165603637695312,18.328400000000002,9.837176637695313,19,10.665603637695312,19C11.494033637695313,19,12.165603637695312,18.328400000000002,12.165603637695312,17.5C12.165603637695312,16.846899999999998,11.748203637695312,16.2913,11.165603637695312,16.0854L11.165603637695312,6.91465Z"
        strokeOpacity="0"
        fillRule="evenodd"
        fillOpacity="1"
      ></path>
    </svg>
  );
};
