import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
import { type IStrategyTickerData } from '../../services/api/Strategy/types/IStrategyResponse';

interface Ticker {
  baseAsset: string;
  exchange: number;
  percent: number;
  quoteAsset: string;
  since: number;
}

export interface CreatedTicker {
  id: string;
  name: string;
  tickers: Ticker[];
}

interface InitialState {
  createdTickers: IStrategyTickerData[];
}

const initialState: InitialState = {
  createdTickers: [],
};

export const fetchCustomTickers = createAsyncThunk<
  IStrategyTickerData[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void, // Define the type of the payload
  { rejectValue: string } // Define the type of the thunkApiConfig
>('customTickers/fetchCustomTickers', async () => {
  // No arguments, so use () => ReturnType
  const data = await apiStrategy.getCustomTickers();
  return data;
});

const chartTickersReducer = createSlice({
  name: 'chartTickers',
  initialState,
  reducers: {
    addCreatedTicker: (state, action: PayloadAction<IStrategyTickerData>) => {
      state.createdTickers.push(action.payload);
    },
    updateTicker: (state, action: PayloadAction<{ _id: string; ticker: Ticker }>) => {
      const { _id, ticker } = action.payload;
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === _id);
      if (tickerIndex >= 0) {
        state.createdTickers[tickerIndex] = { ...state.createdTickers[tickerIndex], ...ticker };
      }
    },
    setQualPercentsTicker: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === id);
      const tickerPercent =
        100 / state.createdTickers.find((ticker) => ticker._id === id).tickers.length;
      if (tickerIndex >= 0) {
        state.createdTickers[tickerIndex] = {
          ...state.createdTickers[tickerIndex],
          tickers: state.createdTickers[tickerIndex].tickers.map((ticker) => ({
            ...ticker,
            percent: tickerPercent,
          })),
        };
      }
    },
    deleteTicker: (state, action: PayloadAction<string>) => {
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === action.payload);
      if (tickerIndex >= 0) {
        state.createdTickers.splice(tickerIndex, 1);
      }
    },
  },
  extraReducers: {
    [fetchCustomTickers.fulfilled.toString()]: (state, action) => {
      state.createdTickers = action.payload;
    },
  },
});

export const { addCreatedTicker, updateTicker, setQualPercentsTicker, deleteTicker } =
  chartTickersReducer.actions;

export const selectCreatedTickers = (state: any): CreatedTicker[] => {
  return state.chartTickers.createdTickers;
};

export default chartTickersReducer.reducer;
