import { type FC } from 'react';
import { passIconProps, type IPassIconProps } from './passIconProps';

export const ChartFibonacciCirclesIcon: FC<IPassIconProps> = (props) => {
  return (
    <svg {...passIconProps(props)} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10.5" cy="11.5" rx="1.5" ry="1.5" strokeOpacity="0" stroke="none" />
      <ellipse cx="17.5" cy="11.5" rx="1.5" ry="1.5" strokeOpacity="0" stroke="none" />
      <ellipse
        cx="10.5"
        cy="11.5"
        rx="7"
        ry="7"
        fillOpacity="0"
        strokeOpacity="1"
        fill="none"
        strokeWidth="1"
      />
      <ellipse
        cx="10.5"
        cy="11.5"
        rx="5"
        ry="5"
        fillOpacity="0"
        strokeOpacity="1"
        fill="none"
        strokeWidth="1"
      />
      <ellipse
        cx="10.5"
        cy="11.5"
        rx="3"
        ry="3"
        fillOpacity="0"
        strokeOpacity="1"
        fill="none"
        strokeWidth="1"
      />
    </svg>
  );
};
