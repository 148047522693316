import { ApiServiceBase } from '../../ApiServiceBase';
import {
  type IChartConfig,
  type IStatistics,
  type IStrategy,
  type ITradeData,
} from '../../../interfaces/IStrategy';

interface IBacktestDto {
  chart: IChartConfig;
  strategy: IStrategy;
}

interface IOneWayBackTestResponse {
  trades: ITradeData[];
  stats: IStatistics;
}

interface IHedgeBackTestResponse {
  trades: [ITradeData[], ITradeData[]];
  stats: IStatistics;
}

class ApiBacktest extends ApiServiceBase {
  protected baseUrl = '/api/backtest/';

  public async backtest(
    data: IBacktestDto,
  ): Promise<(IOneWayBackTestResponse | IHedgeBackTestResponse) | null> {
    try {
      const response = await this.post<
        IBacktestDto,
        IOneWayBackTestResponse | IHedgeBackTestResponse
      >('query', data);

      return response.data ?? null;
    } catch (_) {
      return null;
    }
  }
}

export const apiBacktest = new ApiBacktest();
