import styles from './ChartInfoSelector.module.css';
import { type FC, useState } from 'react';
import { TickerSelector } from '../TickerSelector';
import { parseTicker } from '../../utils/parseTicker';
import { TimeframeSelector } from '../TimeframeSelector';
import { type IChartHeaderProps } from '../../interfaces/chartData';
import { GET_TIMEFRAME_NAME } from '../../configs/chart';
import CustomIndex from '../../assets/images/CustomIndexIcon.svg';

export const ChartInfoSelector: FC<IChartHeaderProps> = ({ data, setData }) => {
  const [isVisibleTickerSelector, setVisibleTickerSelector] = useState(false);
  return (
    <div className={styles.chartInfoSelector}>
      <div
        className={styles.chartInfoSelectorTicker}
        onClick={() => {
          setVisibleTickerSelector(true);
        }}
      >
        {data.customIndex && <img src={CustomIndex} alt="" />}
        {!data.customIndex && (
          <img src={`${process.env.PUBLIC_URL}/coins/${data.baseAsset}.png`} alt="" />
        )}
        {data.customIndex && <span>{data.customIndex.name}</span>}
        {!data.customIndex && (
          <span>{parseTicker(data.exchange, data.baseAsset + data.quoteAsset)}</span>
        )}
      </div>
      {/* <div className={styles.chartInfoSelectorTimeframe}>
        <span>{GET_TIMEFRAME_NAME(data.timeframe)}</span>
      </div> */}

      <TimeframeSelector
        selectedTimeframe={GET_TIMEFRAME_NAME(data.timeframe)}
        onChange={(tf) => {
          setData({ ...data, timeframe: tf });
        }}
      />
      {isVisibleTickerSelector && (
        <TickerSelector
          onClose={() => {
            setVisibleTickerSelector(false);
          }}
          onChange={(ticker) => {
            setData({ ...ticker, timeframe: data.timeframe });
          }}
          data={data}
        />
      )}
    </div>
  );
};
