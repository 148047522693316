import { type IStatistics, type ITradeData } from '../interfaces/IStrategy';

class TradesWebSocket {
  private readonly url = 'wss://beta.cr-algo.com/api/backtest/ws';
  private readonly heartbeatInterval = 5000;
  private readonly reconnectTimeout = 12000;
  private ws: WebSocket;
  private heartbeat: NodeJS.Timer;
  public active = false;
  private handler: ((trades: [string, [ITradeData[], IStatistics]]) => void) | null;

  public open(handler: (trades: [string, [ITradeData[], IStatistics]]) => void): void {
    // this.close();
    // this.active = true;
    // this.handler = handler;
    // this.ws = new WebSocket(this.url);
    // this.ws.onopen = this.onOpen.bind(this);
    // this.ws.onmessage = this.onMessage.bind(this);
    // this.ws.onclose = this.onClose.bind(this);
  }

  public close(): void {
    // this.active = false;
    // this.ws?.close();
    // if (this.heartbeat) clearInterval(this.heartbeat);
  }

  private onOpen(): void {
    console.log('WS - open');
    this.ws.send('pong');
    this.heartbeat = setInterval(() => {
      this.ws.send('pong');
    }, this.heartbeatInterval);
  }

  private onMessage(data: MessageEvent): void {
    const msg = data.data?.toString();

    if (msg === 'ping') return;

    try {
      this.handler(JSON.parse(msg));
    } catch (e) {}
  }

  private onClose(): void {
    console.log('WS - closed');
    if (!this.active) this.close();
    setTimeout(() => {
      if (!this.active) return;
      this.open(this.handler);
    }, this.reconnectTimeout);
  }
}

export const tradesWebSocket = new TradesWebSocket();
