import type { FC, MutableRefObject, SyntheticEvent } from 'react';
import { useState } from 'react';

import './ginput.scss';

export enum InputTypes {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface IGInputProps {
  type: InputTypes;
  name: string;
  reference?: MutableRefObject<HTMLInputElement>;
  id?: string | number;
  placeholder?: string;
  default?: string;
  max?: number;
  disabled?: boolean;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void;
}

const GInput: FC<IGInputProps> = (props) => {
  const [type, setType] = useState<InputTypes>(props.type);

  return (
    <div className="g-input">
      <input
        ref={props.reference}
        id={props.id?.toString()}
        name={props.name}
        type={type}
        placeholder={props.placeholder}
        defaultValue={props.default}
        maxLength={props.max}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        disabled={props.disabled}
      />

      {props.type === InputTypes.PASSWORD && !props.disabled && (
        <div
          className="g-input__eye"
          onClick={() => {
            setType((prev) => {
              if (prev === InputTypes.PASSWORD) {
                return InputTypes.TEXT;
              }

              return InputTypes.PASSWORD;
            });
          }}
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 5C7.55576 5 4.53109 9.23425 3.45554 11.1164C3.23488 11.5025 3.12456 11.6956 3.1367 11.9836C3.14885 12.2716 3.27857 12.4598 3.53799 12.8362C4.8182 14.6935 8.29389 19 13 19C17.7061 19 21.1818 14.6935 22.462 12.8362C22.7214 12.4598 22.8511 12.2716 22.8633 11.9836C22.8754 11.6956 22.7651 11.5025 22.5445 11.1164C21.4689 9.23425 18.4442 5 13 5Z"
              stroke="#838383"
              strokeWidth="2"
            />
            <circle cx="13" cy="12" r="4" fill="#838383" />
            {type === InputTypes.PASSWORD && (
              <path d="M1.5 3L23.5 21" stroke="#838383" strokeWidth="2" />
            )}
          </svg>
        </div>
      )}
    </div>
  );
};

export default GInput;
