import { useState } from 'react';
import './Dropdown.scss';
import Arrow from '../../../../assets/images/MenuPage/DropDownIcon.svg';
import BinanceIcon from '../../../../assets/images/MenuPage/BinanceIcon.svg';
// import WhiteBitIcon from "../../assets/images/MenuPage/WhiteBitIcon.svg";
// import CoinBaseIcon from "../../assets/images/MenuPage/CoinBaseIcon.svg";

// you can remove comment to see ful version woth available platforms
const PlatformDropDown = (): JSX.Element => {
  const platforms: { id: number; name: string; imgSrc: string }[] = [
    { id: 1, name: 'Binance', imgSrc: BinanceIcon },
    // { id: 2, name: "WhiteBit", imgSrc: WhiteBitIcon },
    // { id: 3, name: "CoinBase", imgSrc: CoinBaseIcon }
  ];
  const [selectedplatform, setSelectedPlatform] = useState(platforms[0]);
  const [isOpened, setIsOpened] = useState(false);

  const selectPlatformHandler = (platform: { id: number; name: string; imgSrc: string }): void => {
    setSelectedPlatform(platform);
    setIsOpened(false);
    // закрывает выпадающее меню и сетает выбраную платформу
  };
  return (
    <div className="dropdownContainer">
      <div
        className={`dropdownContainer__dropdown ${
          isOpened ? 'dropdownContainer__dropdown_opened' : ''
        }`}
        onBlur={() => {
          setIsOpened(false);
        }}
      >
        <div
          className={`dropdownContainer__dropdown__selected ${
            isOpened ? 'dropdownContainer__dropdown__selected_active' : ''
          }`}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          <img
            className="dropdownContainer__dropdown__selected__icon"
            src={selectedplatform.imgSrc}
            alt={selectedplatform.name}
          />
          {selectedplatform.name}
          <img className="dropdownContainer__dropdown__selected__arrow" src={Arrow} alt="arrow" />
        </div>
        {platforms.map((platform: { id: number; name: string; imgSrc: string }) => (
          <div
            className="dropdownContainer__dropdown__item"
            key={platform.id}
            onClick={() => {
              selectPlatformHandler(platform);
            }}
          >
            <img
              className="dropdownContainer__platformIcon"
              src={platform.imgSrc}
              alt={platform.name}
            />
            {platform.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlatformDropDown;
