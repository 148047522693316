import { accountSlice } from './../redux/testMenuData/account-slice';
import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import historyReducer from './history/reducer';
import chartReducer from './chart/reducer';
import chartTickersReducer from './chartTickers/reducer';
import authReducer from './auth/reducer';
import strategyReducer from './strategy/reducer';
import themeReducer from './theme/reducer';

export const store = configureStore({
  reducer: {
    history: historyReducer,
    chart: chartReducer,
    chartTickers: chartTickersReducer,
    auth: authReducer,
    strategy: strategyReducer,
    theme: themeReducer,
    accountData: accountSlice.reducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
