import { type FC } from 'react';
import { TabSwitcher } from '../../../../../components/TabSwitcher';
import styles from '../History.module.css';
import { ChartTabs, ChartTime } from '../../../../../enums/HistoryChartTabs';
import { DatePicker } from './DatePicker/DatePicker';

// import { stepPeriods } from '../../../../../configs/chart';

interface IProps {
  selectedCharTab: ChartTabs;
  selectedCharTimeTab: ChartTime;
  currChartTime: number;
  setCurrChartTime: (value: number) => void;
  changeChatTap: (mode: ChartTabs) => void;
  changeCharTimeTab: (mode: ChartTime) => void;
}

export const StrategyTesterExtraTabs: FC<IProps> = (props) => {
  const {
    selectedCharTab,
    selectedCharTimeTab,
    changeChatTap,
    changeCharTimeTab,
    currChartTime,
    setCurrChartTime,
  } = props;
  return (
    <div className={styles.strategyTesterExtraTabs}>
      {selectedCharTab === ChartTabs.BARS && (
        <>
          <DatePicker
            period={selectedCharTimeTab}
            value={currChartTime}
            setValue={setCurrChartTime}
          />
          <TabSwitcher
            className={styles.chartTimeTab}
            defaultTab={selectedCharTimeTab}
            onChange={(tabKey: ChartTime) => {
              setCurrChartTime(Date.now());
              changeCharTimeTab(tabKey);
            }}
            options={[
              { tabId: ChartTime.DAY, tabLabel: 'Day' },
              { tabId: ChartTime.MONTH, tabLabel: 'Month' },
              { tabId: ChartTime.YEAR, tabLabel: 'Year' },
            ]}
          />
        </>
      )}
      <TabSwitcher
        className={styles.chartTab}
        defaultTab={selectedCharTab}
        onChange={(tabKey: ChartTabs) => {
          changeChatTap(tabKey);
        }}
        options={[
          { tabId: ChartTabs.BARS, tabLabel: 'bar' },
          { tabId: ChartTabs.LINES, tabLabel: 'Line' },
        ]}
      />
    </div>
  );
};
