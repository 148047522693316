import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import type { IAuthFormProps } from '../Auth';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';

const PasswordRecovery: FC<IAuthFormProps> = ({ goBack }) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [timer, setTimer] = useState(60);

  const code = useRef<HTMLInputElement>();

  const resendInterval = useRef<NodeJS.Timeout>();

  const email = localStorage.getItem('recovery_email');

  const onChangeInput = (): void => {
    setDisabled(!email || !code.current?.value);
  };

  const startTimer = (): void => {
    setTimer(60);

    resendInterval.current = setInterval(() => {
      setTimer((prev) => {
        if (prev - 1 === 0) {
          clearInterval(resendInterval.current);
        }

        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => {
      setTimer(60);
      clearInterval(resendInterval.current);
    };
  }, []);

  const minutes = Math.floor(timer / 60).toString();
  const seconds = Math.floor(timer % 60)
    .toString()
    .padStart(2, '0');

  return (
    <form
      className="auth-form"
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="auth-form__back" onClick={goBack}>
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66699 7.00008L0.959885 6.29297L0.252779 7.00008L0.959885 7.70719L1.66699 7.00008ZM17.667 8.00008C18.2193 8.00008 18.667 7.55237 18.667 7.00008C18.667 6.4478 18.2193 6.00008 17.667 6.00008V8.00008ZM6.29322 0.959641L0.959885 6.29297L2.3741 7.70719L7.70743 2.37385L6.29322 0.959641ZM0.959885 7.70719L6.29322 13.0405L7.70743 11.6263L2.3741 6.29297L0.959885 7.70719ZM1.66699 8.00008H17.667V6.00008H1.66699V8.00008Z"
            fill="#288DF5"
          />
        </svg>

        <span>Back</span>
      </div>

      <div className="auth-form-header">
        <span className="auth-form-header__title">Password recovery</span>
        <p className="auth-form-header__description">Please enter your email</p>
      </div>

      <div className="auth-form-body">
        <div className="auth-form-body__group">
          <GInput
            type={InputTypes.EMAIL}
            name="email"
            placeholder="Email"
            default={email}
            disabled={true}
          />

          <div className="auth-form-body-tip">
            <span className="auth-form-body-tip__title">Dont take a code?</span>

            <div className="auth-form-body-tip-timer">
              <span className="auth-form-body-tip-timer__time">{`${minutes}:${seconds}`}</span>

              <div
                className={cn('link', timer > 0 && 'link_disabled')}
                onClick={() => {
                  if (timer === 0) {
                    startTimer();
                  }
                }}
              >
                Send code again
              </div>
            </div>
          </div>
        </div>

        <GInput
          reference={code}
          type={InputTypes.TEXT}
          name="code"
          placeholder="Secret code"
          onChange={onChangeInput}
        />
      </div>

      <div className="auth-form-controls auth-form-controls_half">
        <GButton
          title="Confirm"
          type={ButtonTypes.SUBMIT}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={disabled}
        />
      </div>
    </form>
  );
};

export default PasswordRecovery;
