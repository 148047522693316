import { type FC, useEffect, useState } from 'react';
import { DayPicker } from '../../../../../components/DayPicker';
import styles from '../ChartWrapper.module.css';
import { Button } from '../../../../../components/Button/Button';
import stylesButton from '../../../../../components/Button/Button.module.css';
import classNames from 'classnames';
import { setChartConfig } from '../../../../../redux/chart/reducer';
import { useDispatch } from 'react-redux';

export const HeaderDayPicker: FC = () => {
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const disabledDaysFrom = [{ after: to ?? undefined }];
  const disabledDaysTo = [{ before: from ?? undefined }];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setChartConfig({
        dateTo: to?.getTime() ?? null,
        dateFrom: from?.getTime() ?? null,
      }),
    );
  }, [from, to]);

  return (
    <div className={styles.rangeDatePicker}>
      <div className={styles.datePickerWrapper}>
        <DayPicker
          placeholder="from"
          toDate={new Date()}
          onSelect={(data) => {
            setFrom(data);
          }}
          disabled={disabledDaysFrom}
          value={from}
          fromYear={2015}
          footer={
            <Button className={classNames(stylesButton.primaryDark, styles.pickerButtonButton)}>
              Select the first available day
            </Button>
          }
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.datePickerWrapper}>
        <DayPicker
          placeholder="to"
          fromDate={from ?? new Date()}
          toDate={new Date()}
          onSelect={(data) => {
            setTo(data);
          }}
          value={to}
          disabled={disabledDaysTo}
          fromYear={2015}
          footer={
            <Button
              onClick={() => {
                setTo(new Date());
              }}
              className={classNames(stylesButton.primaryDark, styles.pickerButtonButton)}
            >
              Today
            </Button>
          }
        />
      </div>
    </div>
  );
};
