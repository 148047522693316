import { useDispatch } from 'react-redux';
import {
  deleteTicker,
  setQualPercentsTicker,
  updateTicker,
} from '../../redux/chartTickers/reducer';
import styles from './TickerSelector.module.css';
import CustomIndex from '../../assets/images/CustomIndexIcon.svg';
import DotsIcon from '../../assets/images/DotsIcon.svg';
import CancelIcon from '../../assets/images/CancelIcon.svg';
import EditTickerIcon from '../../assets/images/EditTickerIcon.svg';
import DeleteTickerIcon from '../../assets/images/DeleteTickerIcon.svg';
import { useState } from 'react';
import { parseTicker } from '../../utils/parseTicker';
import { type Exchanges } from '../../interfaces/IStrategy';
import { type onChangeProps } from '../../interfaces/chartData';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
const SavedTickerItem = ({
  item,
  onChange,
  onClose,
  data,
}: {
  item: any;
  onChange: (data: onChangeProps) => void;
  onClose: () => void;
  data: {
    exchange: Exchanges;
    baseAsset: string;
    quoteAsset: string;
    timeframe: number;
  };
}): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const [newHeader, setNewHeader] = useState(item.name);
  const [currentItemState, setCurrentItemState] = useState(item);

  const changeDataCustom = (
    e: string,
    takeIndex: number,
    dataIndex: number,
    min: number,
    max: number,
    element?: any,
  ): void => {
    let value = parseFloat(e);

    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }
    let arr;
    if (element) {
      arr = element.tickers.map((el, index) => [index, el.percent]);
    }
    if (!arr[takeIndex]) {
      return;
    }
    arr[takeIndex][dataIndex] = value;
    const updatedArray = arr.map((e: [number, number]) => e[1]);

    if (dataIndex === 1) {
      const diff = (arr[takeIndex][dataIndex] - value) / (arr.length - 1);

      arr.forEach((e: [number, number], i) => {
        if (i === takeIndex) return;
        e[1] += diff;

        if (e[1] < 0) {
          e[1] = 0;
        } else {
          const sum = arr.reduce((a: number, b: [number, number]) => a + b[1], 0);

          if (sum > 100) {
            e[1] -= sum - 100;

            if (e[1] < 0) e[1] = 0;
          } else if (sum < 100) {
            e[1] += 100 - sum;
          }
        }
      });
      updatedArray.forEach((e: number, i) => {
        if (i === takeIndex) return;
        e += diff;

        if (e < 0) {
          e = 0;
        } else {
          const sum = updatedArray.reduce((a: number, b: number) => a + b, 0);
          if (sum > 100) {
            e -= sum - 100;
            if (e < 0) e = 0;
          } else if (sum < 100) {
            e += 100 - sum;
          }
        }
      });
    }
    if (element) {
      const updatedElement = {
        ...element,
        tickers: element.tickers.map((item, index) => ({
          ...item,
          percent: arr[index][1],
        })),
      };
      setCurrentItemState(updatedElement);
    }
  };
  return (
    <span
      className={styles.savedTicker}
      style={
        !isOpened
          ? { height: '48px', maxHeight: '48px', overflow: 'visible' }
          : {
              background: '#130E2D',
            }
      }
    >
      <span
        className={styles.savedTickerHeader}
        onClick={() => {
          if (!isOpened && !isMenuOpened) {
            onChange({
              quoteAsset: '',
              baseAsset: '',
              exchange: 0,
              timeframe: 0,
              customIndex: {
                _id: item._id,
                name: newHeader,
                tickers: currentItemState.tickers,
              },
            });
            onClose();
          }
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsMenuOpened(false);
          }, 2500);
        }}
      >
        <span className={styles.savedTickerHeaderName} style={isOpened ? { width: '75%' } : {}}>
          <img src={CustomIndex} alt="custom" />
          {isOpened ? (
            <input
              type="text"
              style={{ height: '90%', minHeight: '90%', maxHeight: '90%' }}
              value={newHeader}
              onChange={(e) => {
                setNewHeader(e.target.value);
              }}
            />
          ) : (
            <span>{item.name}</span>
          )}
        </span>
        <span
          className={styles.savedTickerHeaderOpenButton}
          onClick={(e) => {
            e.stopPropagation();
            if (isOpened) {
              setIsOpened(false);
              setIsMenuOpened(false);
            } else {
              setIsMenuOpened(!isMenuOpened);
            }
          }}
        >
          <img src={isOpened ? CancelIcon : DotsIcon} alt="dots" />
          <div
            className={styles.savedTickerMenu}
            style={!isMenuOpened ? { width: 0, height: 0, overflow: 'hidden' } : {}}
          >
            <button
              onClick={(e) => {
                setIsOpened(true);
                setIsMenuOpened(false);
                setIsEditMode(true);
              }}
              tabIndex={0}
              onBlur={() => {
                setIsMenuOpened(false);
              }}
              className={styles.savedTickerMenuEditButton}
            >
              Edit <img src={EditTickerIcon} alt="edit" />
            </button>
            <button
              className={styles.savedTickerMenuDeleteButton}
              onClick={() => {
                apiStrategy.deleteCustomTicker(item._id).then((isDeleted) => {
                  if (isDeleted) {
                    dispatch(deleteTicker(item._id));
                  }
                });
              }}
            >
              Delete
              <img src={DeleteTickerIcon} alt="delete" />
            </button>
          </div>
        </span>
      </span>
      {isOpened && (
        <>
          {currentItemState.tickers.map(
            (
              ticker: {
                baseAsset: string;
                quoteAsset: string;
                since: number;
                percent: number;
                exchange: number;
              },
              index,
            ) => {
              const name = parseTicker(ticker.exchange, `${ticker.baseAsset}${ticker.quoteAsset}`);
              return (
                <div key={index} className={styles.savedTickerListItem} onClick={() => {}}>
                  <span>
                    <img
                      className={styles.tickerSelectorContentImage}
                      src={`${process.env.PUBLIC_URL}/coins/${ticker.baseAsset}.png`}
                      alt=""
                    />
                    <span>
                      {name} | From {new Date(ticker.since).toLocaleDateString()}
                    </span>
                  </span>
                  <span>
                    <input
                      type="number"
                      style={{
                        border: ticker.percent === 0 ? '1px solid red' : 'none',
                      }}
                      value={Number(ticker.percent).toFixed(
                        ticker.percent.toString().includes('.') ? 2 : 0,
                      )}
                      step={0.1}
                      onChange={(e) => {
                        changeDataCustom(e.target.value, index, 1, 0, 100, item);
                      }}
                    />
                    %
                  </span>
                </div>
              );
            },
          )}
          <span className={styles.savedTickerButtonsContainer}>
            <div style={{ display: 'flex', width: '55%' }}>
              <button
                className={styles.savedTickerCancelButton}
                onClick={() => {
                  setIsOpened(false);
                  setCurrentItemState(item);
                }}
              >
                Cancel
              </button>
              <button
                className={styles.savedTickerSaveButton}
                onClick={() => {
                  if (currentItemState.tickers.every((item) => item.percent !== 0)) {
                    setIsOpened(false);
                    apiStrategy
                      .saveCustomTicker({
                        _id: item._id,
                        name: newHeader,
                        tickers: currentItemState.tickers,
                      })
                      .then((isTickerUpdated) => {
                        if (isTickerUpdated) {
                          dispatch(
                            updateTicker({
                              _id: currentItemState._id,
                              ticker: { ...currentItemState, name: newHeader },
                            }),
                          );
                          if (item.name.trim() === data.quoteAsset.trim()) {
                            onChange({
                              quoteAsset: '',
                              baseAsset: '',
                              exchange: 0,
                              timeframe: 0,
                              customIndex: {
                                _id: item._id,
                                name: newHeader,
                                tickers: currentItemState.tickers,
                              },
                            });
                          }
                        }
                      });
                  }
                }}
              >
                Save
              </button>
            </div>
            <button
              className={styles.savedTickerSeyEqual}
              onClick={() => {
                dispatch(setQualPercentsTicker({ id: currentItemState.id }));
                setCurrentItemState(item);
              }}
            >
              Set equally
            </button>
          </span>
        </>
      )}
    </span>
  );
};

export default SavedTickerItem;
