import { type FC } from 'react';
import Loader from '../../assets/images/Loader/purpleTest2.gif';
import './LoaderOverlay.css';

interface IProps {
  position: 'absolute' | 'fixed';
  imageWidth: number;
  isShow: boolean;
}

export const LoaderOverlay: FC<IProps> = ({ position, imageWidth, isShow }) => {
  if (!isShow) return null;

  return (
    <div className={'overlayBackground'} style={{ position }}>
      <img src={Loader} alt="loader image" style={{ maxWidth: imageWidth }} />
    </div>
  );
};
