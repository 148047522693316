import { type FC, useState } from 'react';
import styles from './History.module.css';
import { TabSwitcher } from '../../../../components/TabSwitcher';
import { FullScreenIcon } from '../../../../assets/icons/FullScreenIcon';
import { DefaultScreenIcon } from '../../../../assets/icons/DefaultScreenIcon';
import { CollapseIcon } from '../../../../assets/icons/CollapseIcon';
import { HistorySectionModes } from '../../../../enums/HistorySectionMode';
import { ChartTabs, ChartTime } from '../../../../enums/HistoryChartTabs';
import { StrategyTesterExtraTabs } from './components/StrategyTesterExtraTabs';
import { StrategyTesterContent } from './components/StrategyTesterTable';
import { ExpandIcon } from '../../../../assets/icons/ExpandIcon';
import { NonStrategy } from './components/NonStrategy';
import { type IStatistics, type ITradeData } from '../../../../interfaces/IStrategy';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../redux/theme/reducer';

enum HistoryTabs {
  STRATEGY_TESTER = 'strategyTester',
  POSITION = 'position',
  TRADES = 'trades',
  AUTOMATED_STRATEGIES = 'automatedStrategies',
}

interface IProps {
  containerSize: {
    height: number;
    width: number;
  };
  setHistorySectionMode: (mode: HistorySectionModes | null) => void;
  historyMode: HistorySectionModes | null;
  stats: IStatistics;
  trades: ITradeData[];
  strategyOn: boolean;
}

export const History: FC<IProps> = (props) => {
  const { setHistorySectionMode, historyMode, stats, containerSize, strategyOn, trades } = props;
  const [selectedMainTab, setSelectedMainTab] = useState(HistoryTabs.STRATEGY_TESTER);
  const [selectedCharTab, setSelectedCharTab] = useState(ChartTabs.BARS);
  const [selectedCharTimeTab, setSelectedCharTimeTab] = useState(ChartTime.DAY);

  const [currChartTime, setCurrChartTime] = useState<number>(Date.now());

  const theme = useSelector(selectTheme);

  const content = {
    [HistoryTabs.STRATEGY_TESTER]: (
      <StrategyTesterContent
        {...{ stats, containerSize, trades, chartTime: selectedCharTimeTab, currChartTime }}
      />
    ),
    [HistoryTabs.POSITION]: <h1>Position</h1>,
    [HistoryTabs.AUTOMATED_STRATEGIES]: <h1>AUTOMATED STRATEGIES</h1>,
  };

  const changeChatTap = (mode: ChartTabs): void => {
    setSelectedCharTab(mode);
  };

  const changeCharTimeTab = (mode: ChartTime): void => {
    setSelectedCharTimeTab(mode);
  };

  const showFullScreen = (): void => {
    if (historyMode !== HistorySectionModes.FULL_SCREEN) {
      setHistorySectionMode(HistorySectionModes.FULL_SCREEN);
      return;
    }
    setHistorySectionMode(null);
  };

  const collapseHistorySection = (): void => {
    if (historyMode !== HistorySectionModes.COLLAPSE) {
      setHistorySectionMode(HistorySectionModes.COLLAPSE);
      return;
    }
    setHistorySectionMode(null);
  };

  const isStrategyTester = HistoryTabs.STRATEGY_TESTER === selectedMainTab;

  const getTemplateAdditionalTabs = (): JSX.Element => {
    if (isStrategyTester) {
      return (
        <StrategyTesterExtraTabs
          {...{
            selectedCharTab,
            selectedCharTimeTab,
            changeChatTap,
            changeCharTimeTab,
            setCurrChartTime,
            currChartTime,
          }}
        />
      );
    }
    return null;
  };

  const isCollapseSection = HistorySectionModes.COLLAPSE === historyMode;

  const fullScreenBtnIcon =
    HistorySectionModes.FULL_SCREEN === historyMode ? <DefaultScreenIcon /> : <FullScreenIcon />;
  const collapseBtnTextIcon = isCollapseSection ? <ExpandIcon /> : <CollapseIcon />;

  // const isShowNonStrategy = !strategyActiveTab ? isStrategyTester : !strategyOn && isStrategyTester;
  const isShowNonStrategy = !strategyOn;

  return (
    <div
      style={{ backgroundColor: theme.historyBackground }}
      className={styles.historyWrapper}
      id="history_container"
    >
      <div className={styles.historyHeadWrapper}>
        {!isCollapseSection && (
          <div style={{ backgroundColor: theme.historyBackground }} className={styles.tabsWrapper}>
            <TabSwitcher
              className={styles.mainTab}
              defaultTab={selectedMainTab}
              onChange={(tabKey: HistoryTabs) => {
                setSelectedMainTab(tabKey);
              }}
              options={[
                { tabId: HistoryTabs.STRATEGY_TESTER, tabLabel: 'Strategy tester' },
                { tabId: HistoryTabs.TRADES, tabLabel: 'Trades' },
                // { tabId: HistoryTabs.AUTOMATED_STRATEGIES, tabLabel: 'Automated Strategies' },
              ]}
            />
            {getTemplateAdditionalTabs()}
          </div>
        )}
        <div className={styles.additionalButtonWrapper}>
          <button className={styles.downloadBtn} onClick={collapseHistorySection}>
            {collapseBtnTextIcon}
          </button>
          <button className={styles.fullScreenBtn} onClick={showFullScreen}>
            {fullScreenBtnIcon}
          </button>
        </div>
      </div>

      {!isCollapseSection && (
        <>
          <div
            style={{ backgroundColor: theme.historyBackground }}
            className={styles.mainContentWrapper}
          >
            {content[selectedMainTab] ?? null}
          </div>
          {isShowNonStrategy && <NonStrategy />}
        </>
      )}
    </div>
  );
};
