import GTumbler from '../../../../../../components/GTumbler/GTumbler';
import { StrategySelectorXL } from '../StrategySelectorXL/StrategySelectorXL';
import Settings from '../../../../../../assets/images/Strategy/SettingsIcon.svg';
import './StrategyOtherFilters.scss';
import { useState } from 'react';
import { StrategyOtherFiltersSetting } from '../StrategyOtherFiltersSettings/StrategyOtherFilterSettings';
const StrategyOtherFilters = (): JSX.Element => {
  const [isOtherFilters, setIsOtherFilters] = useState(false);
  return (
    <>
      {!isOtherFilters && (
        <div className="strategy-other-filters">
          <div className="strategy-other-filters__title">Other filters</div>
          <div className="strategy-other-filters__content">
            <GTumbler name="strategyTumbler" />
            <div className="strategy-other-filters__selector">
              <StrategySelectorXL
                options={[{ id: 0, name: 'Sessions filter' }]}
                activeOption={0}
                onChange={() => {}}
              />
            </div>
            <button
              onClick={() => {
                setIsOtherFilters(true);
              }}
            >
              <img src={Settings} alt="S" />
            </button>
          </div>
        </div>
      )}
      {isOtherFilters && (
        <StrategyOtherFiltersSetting
          onClose={() => {
            setIsOtherFilters(false);
          }}
        />
      )}
    </>
  );
};

export default StrategyOtherFilters;
