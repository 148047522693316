import { useState, type ChangeEvent, type Dispatch, type FC, type SetStateAction } from 'react';
import './StrategyTypeSettings.scss';
import { OrderTypes, type IStrategy } from '../../../../../../interfaces/IStrategy';
import { StrategyType } from '../../../../../../enums/Strategy';
import { StrategySettingsHeader } from '../StrategySettingsHeader/StrategySettingsHeader';
import { StrategyTypeSettingsTable } from './StrategyTypeSettingsTable';
import { StrategySelector } from '../StrategySelector/StrategySelector';
import CustomNumberInput from '../CustomInput/CustomNumberInput';

interface IProps {
  strategy: IStrategy;
  setStrategy: Dispatch<SetStateAction<IStrategy>>;
  type: StrategyType;
  onClose: () => void;
}

const strategyTypesNames: Record<StrategyType, string> = {
  [StrategyType.FixedRisk]: 'Fixed Risk',
  [StrategyType.DCA]: 'DCA',
};

export const StrategyTypeSettings: FC<IProps> = (props) => {
  const [currentStrategySettings, setCurrentStrategySettings] = useState(props.strategy);
  const changeAmountData = (
    e: ChangeEvent<HTMLInputElement>,
    dataType: 'take_profits' | 'average',
    defValue: number,
  ): void => {
    const value = parseInt(e.target.value);

    if (isNaN(value) || value < 1 || value > 10) return;

    const arr = (
      dataType === 'take_profits'
        ? currentStrategySettings.settings.take_profits
        : currentStrategySettings.settings.average.orders
    ).slice();

    if (value > arr.length) {
      for (let i = 0; i < value - arr.length; i++) {
        arr.push([arr[arr.length - 1][0] + 1, defValue]);
      }
    } else if (value < arr.length) {
      const diff = arr.length - value;

      arr.splice(arr.length - diff, diff);
    }
    const data = {
      ...currentStrategySettings,
      settings: {
        ...currentStrategySettings.settings,
        [dataType]:
          dataType === 'take_profits'
            ? arr
            : {
                ...currentStrategySettings.settings.average,
                orders: arr,
              },
      },
    };

    // props.setStrategy(data);
    setCurrentStrategySettings(data);
  };

  const changeData = (
    e: string,
    takeIndex: number,
    dataType: 'take_profits' | 'average',
    dataIndex: number,
    min: number,
    max: number,
  ): void => {
    let value = parseFloat(e);

    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }

    const arr = (
      dataType === 'take_profits'
        ? currentStrategySettings.settings.take_profits
        : currentStrategySettings.settings.average.orders
    ).slice();

    if (!arr[takeIndex]) return;

    arr[takeIndex][dataIndex] = value;

    if (dataType === 'take_profits' && dataIndex === 1) {
      const diff = (arr[takeIndex][dataIndex] - value) / (arr.length - 1);

      arr.forEach((e, i) => {
        if (i === takeIndex) return;
        e[1] += diff;

        if (e[1] < 0) {
          e[1] = 0;
        } else {
          const sum = arr.reduce((a, b) => a + b[1], 0);

          if (sum > 100) {
            e[1] -= sum - 100;

            if (e[1] < 0) e[1] = 0;
          } else if (sum < 100) {
            e[1] += 100 - sum;
          }
        }
      });
    }

    // props.setStrategy({
    //   ...props.strategy,
    //   settings: {
    //     ...props.strategy.settings,
    //     [dataType]:
    //       dataType === 'take_profits'
    //         ? arr
    //         : {
    //             ...props.strategy.settings.average,
    //             orders: arr,
    //           },
    //   },
    // });
    setCurrentStrategySettings({
      ...currentStrategySettings,
      settings: {
        ...currentStrategySettings.settings,
        [dataType]:
          dataType === 'take_profits'
            ? arr
            : {
                ...currentStrategySettings.settings.average,
                orders: arr,
              },
      },
    });
  };

  const options = [
    {
      id: OrderTypes.Market,
      name: 'Market',
    },
    {
      id: OrderTypes.Limit,
      name: 'Limit',
    },
  ];
  const [activeOption, setActiveOption] = useState(options[0].id);
  return (
    <>
      <div className="strategy-type-settings">
        <StrategySettingsHeader name={strategyTypesNames[props.type]} onClose={props.onClose} />

        <div className="strategy-type-settings__content">
          {props.type === StrategyType.FixedRisk && (
            <div className="strategy-type-settings__content__elem">
              <h4>Stop loss length</h4>
              <CustomNumberInput
                step={0.1}
                value={currentStrategySettings.settings.stop_loss}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value > 100) return;
                  // props.setStrategy({
                  //   ...props.strategy,
                  //   settings: {
                  //     ...props.strategy.settings,
                  //     stop_loss: value,
                  //   },
                  // });
                  setCurrentStrategySettings({
                    ...currentStrategySettings,
                    settings: {
                      ...currentStrategySettings.settings,
                      stop_loss: value,
                    },
                  });
                }}
                onBlur={() => {
                  if (isNaN(currentStrategySettings.settings.stop_loss)) {
                    // props.setStrategy({
                    //   ...props.strategy,
                    //   settings: {
                    //     ...props.strategy.settings,
                    //     stop_loss: 1,
                    //   },
                    // });
                    setCurrentStrategySettings({
                      ...currentStrategySettings,
                      settings: {
                        ...currentStrategySettings.settings,
                        stop_loss: 1,
                      },
                    });
                  }
                }}
              />
            </div>
          )}

          {props.type === StrategyType.DCA && (
            <>
              <div className="strategy-type-settings__content__elem">
                <h4>Amount of averages</h4>
                <CustomNumberInput
                  step={1}
                  value={currentStrategySettings.settings.average.orders.length}
                  onChange={(e) => {
                    changeAmountData(e, 'average', 1.5);
                  }}
                />
              </div>
              <StrategyTypeSettingsTable
                list={currentStrategySettings.settings.average.orders}
                prefix="DCA "
                secondColumn="Factor"
                changeData={(e, takeIndex, dataIndex, min, max) => {
                  changeData(e, takeIndex, 'average', dataIndex, min, max);
                }}
              />
            </>
          )}

          <div className="strategy-type-settings__content__elem">
            <h4>Amount of takes</h4>
            <CustomNumberInput
              value={currentStrategySettings.settings.take_profits.length}
              step={1}
              onChange={(e) => {
                const value =
                  100 - currentStrategySettings.settings.take_profits.reduce((a, b) => a + b[1], 0);

                changeAmountData(e, 'take_profits', value < 0 ? 0 : value);
              }}
            />
          </div>

          <StrategyTypeSettingsTable
            prefix="TP "
            secondColumn="Quantity(%)"
            list={currentStrategySettings.settings.take_profits}
            changeData={(e, takeIndex, dataIndex, min, max) => {
              changeData(e, takeIndex, 'take_profits', dataIndex, min, max);
            }}
          />
          {props.type === StrategyType.DCA && (
            <div className="strategy-type-settings__content__elem">
              <h4 style={{ fontSize: '13px' }}>Type of averaging orders</h4>
              <StrategySelector
                width="100px"
                options={options}
                activeOption={activeOption}
                onChange={(e) => {
                  setActiveOption(e);
                }}
              />
            </div>
          )}
        </div>
        <button
          className="strategy-type-settings__saveButton"
          onClick={() => {
            props.setStrategy(currentStrategySettings);
          }}
        >
          Save
        </button>
      </div>
    </>
  );
};
