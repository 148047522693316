import { type FC, useEffect, useState } from 'react';
import './StrategySwitcher.scss';

interface IProps {
  options: { id: number; name: string; disabled?: boolean }[];
  activeOption: number;
  onChange: (id: number) => void;
  height: number;
  width?: number;
  activeColor: string;
}
export const StrategySwitcher: FC<IProps> = (props) => {
  const [activeOption, setActiveOption] = useState(props.activeOption);

  useEffect(() => {
    setActiveOption(props.activeOption);
  }, [props.activeOption]);

  return (
    <div style={{ width: props.width ? `${props.width}%` : null }} className="strategy-switcher">
      {props.options.map((e) => (
        <div
          style={{
            // height: `${props.height}px`,
            // lineHeight: `${props.height}px`,
            // color: e.id === activeOption ? 'white' : 'black',
            background: e.id === activeOption ? props.activeColor : 'none',
          }}
          key={e.id}
          className={'strategy-switcher__option'}
          onClick={() => {
            if (e.disabled) return;

            setActiveOption(e.id);
            props.onChange(e.id);
          }}
        >
          {e.name}
        </div>
      ))}
    </div>
  );
};
