import { ApiServiceBase } from '../../ApiServiceBase';
import { type IStrategyAllResponse, type IStrategyTickerData } from './types/IStrategyResponse';
import { type Exchanges, type IStrategy } from '../../../interfaces/IStrategy';

interface Ticker {
  baseAsset: string;
  quoteAsset: string;
  exchange: Exchanges;
  timeframe: number;
  percent: number;
}
class ApiStrategy extends ApiServiceBase {
  protected baseUrl = '/api/strategy/';

  public async getStrategies(): Promise<IStrategyAllResponse> {
    try {
      const response = await this.get('get_all');

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async getStrategy(strategyId: string): Promise<IStrategy | null> {
    try {
      const response = await this.post('get', { _id: strategyId });

      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async saveStrategy(data: IStrategy): Promise<boolean> {
    try {
      const response = await this.post('save', data);

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async addStrategy(): Promise<IStrategy | null> {
    try {
      const response = await this.post('add');

      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async removeStrategy(strategyId: string): Promise<boolean> {
    try {
      const response = await this.post('remove', { strategy_id: strategyId });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async getCustomTickers(): Promise<IStrategyTickerData[]> {
    try {
      const response = await this.get('custom_ticker/get');
      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async addCustomTicker(ticker: {
    name: string;
    tickers: Ticker[];
  }): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('custom_ticker/create', {
        data: {
          name: ticker.name,
          tickers: ticker.tickers,
        },
      });
      const { _id, name, tickers } = response.data.data ?? null;
      return { _id, name, tickers };
    } catch (_) {
      return null;
    }
  }

  public async saveCustomTicker(ticker: {
    _id: string;
    name: string;
    tickers: Ticker[];
  }): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('custom_ticker/save', {
        data: {
          _id: ticker._id,
          name: ticker.name,
          tickers: ticker.tickers,
        },
      });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async deleteCustomTicker(_id: string): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('custom_ticker/remove', {
        id: _id,
      });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }
}

export const apiStrategy = new ApiStrategy();
