import type { RouteObject } from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';

import HomePage from '../pages/HomePage';
import Auth from '../pages/Auth/Auth';
import MenuPage from '../pages/MenuPage';

const routes: Array<RouteObject> = [
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/menu',
    element: <MenuPage />,
  },
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
    ],
  },
];

export default routes;
