import type { FC } from 'react';
// import GButton, { ButtonColors, ButtonTypes } from '../../../../../../components/GButton/GButton';
import StrategyElement from '../../../../../../components/StrategyElement/StrategyElement';
import { apiStrategy } from '../../../../../../services/api/Strategy/ApiStrategy';
import { type IStrategyAllResponse } from '../../../../../../services/api/Strategy/types/IStrategyResponse';
import { Components } from '../../Components';
import './savedStrategies.scss';

interface IProps {
  strategies: IStrategyAllResponse;
  setStrategies: (value: IStrategyAllResponse) => void;
  setStrategyNode: (value: Components | null) => void;
  setSelectedStrategy: (value: string) => void;
  addStrategyHistory: (value: { _id: string; name: string }) => void;
}

const SavedStrategies: FC<IProps> = ({
  strategies,
  setStrategies,
  setSelectedStrategy,
  setStrategyNode,
  addStrategyHistory,
}) => {
  const removeStrategyHandler = async (id: string): Promise<void> => {
    const result = await apiStrategy.removeStrategy(id);

    if (result) {
      const index = strategies.findIndex((e) => e._id === id);

      if (index !== -1) {
        setStrategies(
          strategies.slice(0, index).concat(strategies.slice(index + 1, strategies.length)),
        );
      }
    }
  };

  return (
    <>
      {/* <GButton
        type={ButtonTypes.BUTTON}
        bordered={false}
        filled={true}
        title="Back"
        color={ButtonColors.BLUE}
        onClick={() => {}}
      /> */}
      {strategies.map((strategy) => {
        return (
          <StrategyElement
            key={strategy._id}
            _id={strategy._id}
            name={strategy.name}
            editStrategy={() => {
              addStrategyHistory(strategy);
              setSelectedStrategy(strategy._id);
              setStrategyNode(Components.CONSTRUCTOR);
            }}
            removeHandler={(id) => {
              removeStrategyHandler(id);
            }}
          />
        );
      })}
    </>
  );
};

export default SavedStrategies;
