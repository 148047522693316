import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { State } from './types';

const initialState: State = {
  strategies: [],
  editStrategies: [],
  currentStrategyType: '',
};

const strategyReducer = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    setStrategies: (state, action: PayloadAction<Array<{ _id: string; name: string }>>) => {
      state.strategies = action.payload;
    },
    addStrategy: (state, action: PayloadAction<{ _id: string; name: string }>) => {
      state.strategies.push(action.payload);
    },
    removeStrategy: (state, action: PayloadAction<string>) => {
      const id = state.strategies.findIndex((strategy) => strategy._id === action.payload);

      if (id >= 0) {
        state.strategies.splice(id, 1);
      }
    },
    openStrategy: (state, action: PayloadAction<number>) => {
      if (state.editStrategies.length < 3) {
        state.editStrategies.push(action.payload);
      }
    },
    closeStrategy: (state, action: PayloadAction<number>) => {
      const elementId = state.editStrategies.findIndex((id) => id === action.payload);

      if (elementId >= 0) {
        state.editStrategies.splice(elementId, 1);
      }
    },
    setCurrentStrategyType: (state, action: PayloadAction<string>) => {
      state.currentStrategyType = action.payload;
    },
  },
});

export const {
  setStrategies,
  addStrategy,
  removeStrategy,
  openStrategy,
  closeStrategy,
  setCurrentStrategyType,
} = strategyReducer.actions;

export default strategyReducer.reducer;
