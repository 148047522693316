import { type FC } from 'react';
import CustomNumberInput from '../CustomInput/CustomNumberInput';

interface IProps {
  prefix: string;
  secondColumn: string;
  list: [number, number][];
  changeData: (e: string, takeIndex: number, dataIndex: number, min: number, max: number) => void;
}

export const StrategyTypeSettingsTable: FC<IProps> = (props) => {
  return (
    <div className="strategy-type-settings__content__take-profits">
      {props.list.map((e, i) => (
        <div key={i} className="strategy-type-settings__content__take-profits__item">
          <div className="strategy-type-settings__content__take-profits__itemHeader">
            {' '}
            {props.prefix}
            {i + 1}
          </div>
          <div className="strategy-type-settings__content__take-profits__itemInput">
            <span>Length(%)</span>
            <CustomNumberInput
              value={e[0]}
              step={0.1}
              onChange={(e) => {
                props.changeData(e.target.value, i, 0, 0.01, 1000);
              }}
            />
          </div>
          <div className="strategy-type-settings__content__take-profits__itemInput">
            <span>{props.secondColumn}</span>
            <CustomNumberInput
              value={e[1]}
              step={0.1}
              onChange={(e) => {
                props.changeData(e.target.value, i, 1, 1, 100);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
