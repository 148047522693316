import { ApiServiceBase } from '../../ApiServiceBase';
import type { IUser, IUserLoginRequest, IUserRegisterRequest } from '../../../interfaces/IUser';
import type { ISuccessResponse } from '../../../interfaces/IResponse';

class ApiAuth extends ApiServiceBase {
  protected baseUrl = '/api/auth/';

  public login = async (data: IUserLoginRequest): Promise<IUser | false> => {
    try {
      const response = await this.post<IUserLoginRequest, ISuccessResponse<IUser>>('login', data);

      if (response.status === 200) {
        return response.data.data;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  public register = async (data: IUserRegisterRequest): Promise<IUser | false> => {
    try {
      const response = await this.post<IUserRegisterRequest, ISuccessResponse<IUser>>(
        'register',
        data,
      );

      if (response.status === 200) {
        return response.data.data;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  public getUser = async (): Promise<IUser> => {
    const response = await this.get<ISuccessResponse<IUser>>('user');

    return response.data.data;
  };
}

export const apiAuth = new ApiAuth();
