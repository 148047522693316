import { useState, type FC } from 'react';
import styles from './TimeframeSelector.module.css';
import TimeFrameIcon from '../../assets/images/ChartTimeFrameIcon.svg';
import TimeFrameIconSelected from '../../assets/images/ChartTimeSelectedFrameIcon.svg';
import TimeFrameIconSaved from '../../assets/images/ChartTimeSavedFrameIcon.svg';
import OpenTimeFrame from '../../assets/images/OpenTimeFrame.svg';

interface IProps {
  onChange: (tf: number) => void;
  selectedTimeframe: string;
}

export const TimeframeSelector: FC<IProps> = ({ onChange, selectedTimeframe }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showStars, setShowStars] = useState(false);
  const [timeframes, setTimeframes] = useState([
    { id: 1, time: 1000, value: '1s', disabled: true, marked: false },
    { id: 2, time: 5000, value: '5s', disabled: true, marked: false },
    { id: 3, time: 15000, value: '15s', disabled: true, marked: false },
    { id: 4, time: 30000, value: '30s', disabled: true, marked: false },
    { id: 5, time: 60000, value: '1m', disabled: false, marked: false },
    { id: 6, time: 3 * 60000, value: '3m', disabled: false, marked: true },
    { id: 7, time: 5 * 60000, value: '5m', disabled: false, marked: false },
    { id: 8, time: 15 * 60000, value: '15m', disabled: false, marked: true },
    { id: 9, time: 30 * 60000, value: '30m', disabled: false, marked: false },
    { id: 10, time: 60 * 60000, value: '1h', disabled: false, marked: false },
    { id: 11, time: 4 * 60 * 60000, value: '4h', disabled: false, marked: true },
    { id: 12, time: 24 * 60 * 60000, value: '1d', disabled: false, marked: false },
    { id: 13, time: 7 * 24 * 60 * 60000, value: '1w', disabled: false, marked: false },
  ]);
  const allTimeFramesSelected = timeframes.filter((item) => item.marked).length;
  const timeFrameItemClickHandler = (item: {
    id: number;
    time: number;
    value: string;
    disabled: boolean;
    marked: boolean;
  }): void => {
    if (!showStars && !item.disabled) {
      onChange(item.time);
      setIsModalOpen(false);
    }
    if (showStars && allTimeFramesSelected === 5 && item.marked) {
      setTimeframes((prev) => {
        return prev.map((i) => {
          if (i.id === item.id) {
            return { ...i, marked: !item.marked };
          } else {
            return i;
          }
        });
      });
    }
    if (showStars && allTimeFramesSelected < 5) {
      setTimeframes((prev) => {
        return prev.map((i) => {
          if (i.id === item.id && !item.disabled) {
            if (!item.marked) return { ...i, marked: !item.marked };
            if (item.marked && allTimeFramesSelected !== 1) {
              return { ...i, marked: !item.marked };
            } else {
              return { ...i, marked: item.marked };
            }
          } else {
            return i;
          }
        });
      });
    }
  };
  return (
    <div>
      <div className={styles.timeframeSelectorHeader}>
        <div style={{ width: '65px' }}>Active TF:</div>
        <div
          className={styles.timeFrameItem}
          style={{
            cursor: 'pointer',
            backgroundColor: 'rgba(151, 71, 255, 1)',
          }}
          onClick={() => {
            setShowStars(false);
            setIsModalOpen(!isModalOpen);
          }}
        >
          {selectedTimeframe}
        </div>
        <img style={{ margin: '10px' }} src={TimeFrameIconSelected} alt="image" />
        {timeframes
          .filter((item) => item.marked)
          .map((item) => (
            <div
              key={item.id}
              style={{
                backgroundColor: selectedTimeframe === item.value && 'rgba(151, 71, 255, 1)',
              }}
              className={styles.timeFrameItem}
              onClick={() => {
                onChange(item.time);
                setIsModalOpen(false);
              }}
            >
              {item.value}
            </div>
          ))}
        <div
          className={styles.timeFrameToggler}
          onClick={() => {
            setShowStars(true);
            setIsModalOpen(!isModalOpen);
          }}
        >
          <img src={OpenTimeFrame} alt="" />
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.timeframeSelector}>
          <div className={styles.timeframeHeader}>Select Timeframe </div>
          <div className={styles.timeframeSelectorList}>
            {timeframes.map((item, key) => (
              <div
                style={{
                  backgroundColor: selectedTimeframe === item.value && 'rgba(151, 71, 255, 1)',
                  cursor: item.disabled ? 'not-allowed' : 'pointer',
                }}
                key={key}
                onClick={() => {
                  timeFrameItemClickHandler(item);
                }}
              >
                {showStars && allTimeFramesSelected === 5 && item.marked && (
                  <>
                    <img
                      src={
                        item.marked
                          ? selectedTimeframe === item.value
                            ? TimeFrameIconSelected
                            : TimeFrameIconSaved
                          : TimeFrameIcon
                      }
                      alt="star"
                    />{' '}
                  </>
                )}
                {showStars && allTimeFramesSelected < 5 && (
                  <>
                    <img
                      src={
                        item.marked
                          ? selectedTimeframe === item.value
                            ? TimeFrameIconSelected
                            : TimeFrameIconSaved
                          : TimeFrameIcon
                      }
                      alt=""
                    />{' '}
                  </>
                )}
                {item.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
