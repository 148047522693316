import { type FC, useEffect, useState } from 'react';
import styles from './TickerSelector.module.css';
import { TickerSelectorCloseIcon } from '../../assets/icons/TickerSelectorCloseIcon';
import { apiExchange } from '../../services/api/Exchange/ApiExchange';
import { type ITickersResponse } from '../../interfaces/strategyEditor';
import { type Exchanges } from '../../interfaces/IStrategy';
import { parseTicker } from '../../utils/parseTicker';
import CustomIndex from '../../assets/images/CustomIndexIcon.svg';
import NavigationArrow from '../../assets/images/NavigationArrow.svg';
import NotSelectedCircle from '../../assets/images/NotSelectedCircle.svg';
import SelectedCircle from '../../assets/images/SelectedCircle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addCreatedTicker, selectCreatedTickers } from '../../redux/chartTickers/reducer';
import SavedTickerItem from './SavedTickerItem';
import { type onChangeProps } from '../../interfaces/chartData';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
interface IProps {
  onChange: (data: onChangeProps) => void;
  onClose: () => void;
  data: {
    exchange: Exchanges;
    baseAsset: string;
    quoteAsset: string;
    timeframe: number;
  };
}

export const TickerSelector: FC<IProps> = ({ onClose, onChange, data }) => {
  const MODE_SEARCHING_TICKET = 'searching';
  const MODE_LIST_OF_INDEX_TICKERS = 'indexes';
  const MODE_CREATING_INDEX_STEP_ONE = 'creating';
  const MODE_SETTING_INDEX_STEP_TWO = 'setting';

  const [tickerSelectorState, setTickerSelectorState] = useState<
    | typeof MODE_SEARCHING_TICKET
    | typeof MODE_LIST_OF_INDEX_TICKERS
    | typeof MODE_CREATING_INDEX_STEP_ONE
    | typeof MODE_SETTING_INDEX_STEP_TWO
  >(MODE_SEARCHING_TICKET);

  const dispatch = useDispatch();

  const [selectedIndexTicker, setSelectedIndexTicker] = useState<ITickersResponse>([]);

  const [selectedTickersPercentsArray, setSelectedTickersPercentsArray] = useState([]);

  const [search, setSearch] = useState('');
  const [customIndexesSearch, setCustomIndexesSearch] = useState('');
  const [values, setValues] = useState<ITickersResponse>([]);

  const createdTickers = useSelector(selectCreatedTickers);
  const [headerName, setHeaderName] = useState(`Custom index ${createdTickers.length + 1}`);
  const [createdTicker, setCreatedTicker] = useState<{ name: string; tickers: any[] }>({
    name: headerName,
    tickers: [],
  });

  const changeDataCustom = (
    e: string,
    takeIndex: number,
    dataIndex: number,
    min: number,
    max: number,
    element?: any,
  ): void => {
    let value = parseFloat(e);

    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }
    let arr = [...selectedTickersPercentsArray];
    if (element) {
      arr = element.tickers.map((el, index) => [index, el.percent]);
    }
    if (!arr[takeIndex]) {
      return;
    }
    arr[takeIndex][dataIndex] = value;
    const updatedArray = arr.map((e: [number, number]) => e[1]);

    if (dataIndex === 1) {
      const diff = (arr[takeIndex][dataIndex] - value) / (arr.length - 1);

      arr.forEach((e: [number, number], i) => {
        if (i === takeIndex) return;
        e[1] += diff;

        if (e[1] < 0) {
          e[1] = 0;
        } else {
          const sum = arr.reduce((a: number, b: [number, number]) => a + b[1], 0);

          if (sum > 100) {
            e[1] -= sum - 100;

            if (e[1] < 0) e[1] = 0;
          } else if (sum < 100) {
            e[1] += 100 - sum;
          }
        }
      });
      updatedArray.forEach((e, i) => {
        if (i === takeIndex) return;
        e += diff;

        if (e < 0) {
          e = 0;
        } else {
          const sum = updatedArray.reduce((a: number, b: number) => a + b, 0);
          if (sum > 100) {
            e -= sum - 100;
            if (e < 0) e = 0;
          } else if (sum < 100) {
            e += 100 - sum;
          }
        }
      });
    }
    if (element) {
      // const updatedElement = {
      //   ...element,
      //   tickers: element.tickers.map((item, index) => ({
      //     ...item,
      //     percent: arr[index][1],
      //   })),
      // };
      // dispatch(updateTicker({ id: element.id, ticker: updatedElement }));
    } else {
      setSelectedTickersPercentsArray(arr);
      setCreatedTicker({
        ...createdTicker,
        tickers: createdTicker.tickers.map((item, index) => ({
          ...item,
          percent: arr[index][1],
        })),
      });
    }
  };

  useEffect(() => {
    const getTickers = async (): Promise<void> => {
      const tickers = await apiExchange.getTickers();
      tickers.sort((a, b) => {
        if (a.baseAsset < b.baseAsset) return -1;
        if (a.baseAsset > b.baseAsset) return 1;
        return 0;
      });
      setValues(tickers);
    };
    getTickers();
  }, []);
  useEffect(() => {
    const selectedTickerPercent = Number(100 / selectedIndexTicker.length).toFixed(2);
    const array = [];
    for (let i = 0; i < selectedIndexTicker.length; i++) {
      array.push([i, Number(selectedTickerPercent)]);
    }
    setSelectedTickersPercentsArray(array);
    let newIndex = {
      name: headerName,
      tickers: [],
    };
    selectedIndexTicker.forEach((e, i) => {
      newIndex = {
        ...newIndex,
        tickers: [
          ...newIndex.tickers,
          {
            exchange: e.exchange,
            baseAsset: e.baseAsset,
            quoteAsset: e.quoteAsset,
            percent: Number(selectedTickerPercent),
            since: e.since,
          },
        ],
      };
    });
    setCreatedTicker(newIndex);
  }, [selectedIndexTicker, headerName]);
  return (
    <div className={styles.tickerSelectorOverlay}>
      <div className={styles.tickerSelector}>
        {tickerSelectorState === MODE_SEARCHING_TICKET && (
          <>
            <div className={styles.tickerSelectorHeader}>
              <span>Ticker Search</span>
              <TickerSelectorCloseIcon
                className={styles.tickerSelectorHeaderClose}
                onClick={onClose}
              />
            </div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value.replace(/\s/g, ''));
              }}
            />
            <div
              onClick={() => {
                if (createdTickers.length === 0) {
                  setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
                } else {
                  setTickerSelectorState(MODE_LIST_OF_INDEX_TICKERS);
                }
              }}
              className={styles.tickerSelectorSearchingHeader}
            >
              <div>
                <img src={CustomIndex} alt="customIndex" />
                Custom index
              </div>
              <img src={NavigationArrow} alt="navigationArrow" />
            </div>
            <div className={styles.tickerSelectorContent}>
              {values.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
                const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
                return search === '' || name.includes(search.toUpperCase()) ? (
                  <div
                    key={key}
                    onClick={() => {
                      onChange({
                        baseAsset,
                        quoteAsset,
                        exchange,
                        timeframe: 900000,
                      });
                      onClose();
                    }}
                  >
                    <span>
                      <img
                        className={styles.tickerSelectorContentImage}
                        src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                        alt=""
                      />
                      <span>
                        {name} | From {new Date(since).toLocaleDateString()}
                      </span>
                    </span>
                  </div>
                ) : null;
              })}
            </div>
          </>
        )}
        {tickerSelectorState === MODE_LIST_OF_INDEX_TICKERS && (
          <>
            {' '}
            <div className={styles.tickerSelectorHeader}>
              <span>Сustom Indexes</span>
              <TickerSelectorCloseIcon
                className={styles.tickerSelectorHeaderClose}
                onClick={onClose}
              />
            </div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setCustomIndexesSearch(e.target.value.replace(/\s/g, ''));
              }}
            />
            <div className={styles.savedTickerListContent}>
              {createdTickers
                .filter((item) =>
                  customIndexesSearch !== '' ? item.name.includes(customIndexesSearch) : true,
                )
                .map((item, key) => {
                  return (
                    <SavedTickerItem
                      item={item}
                      key={Number(item.id)}
                      onChange={onChange}
                      data={data}
                      onClose={onClose}
                    />
                  );
                })}
            </div>
            <div className={styles.tickerSelectorFooter}>
              <button
                className={styles.tickerSelectorFooterClear}
                onClick={() => {
                  setTickerSelectorState(MODE_SEARCHING_TICKET);
                }}
              >
                Back
              </button>
              <button
                className={styles.tickerSelectorFooterNext}
                onClick={() => {
                  setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
                }}
              >
                Create new
              </button>
            </div>
          </>
        )}
        {tickerSelectorState === MODE_CREATING_INDEX_STEP_ONE && (
          <>
            <div className={styles.tickerSelectorHeader}>
              <span>Create a new custom index</span>
              <TickerSelectorCloseIcon
                className={styles.tickerSelectorHeaderClose}
                onClick={onClose}
              />
            </div>
            <input
              type="text"
              placeholder="Name of the custom index"
              style={{
                border: createdTicker.name.trim() === '' ? '1px solid red' : 'none',
              }}
              onChange={(e) => {
                setHeaderName(e.target.value);
              }}
              defaultValue={createdTicker.name}
            />
            <div
              onClick={() => {
                setTickerSelectorState(MODE_SEARCHING_TICKET);
                setSelectedIndexTicker([]);
              }}
              className={styles.tickerSelectorCreatingHeader}
            >
              <img src={NavigationArrow} alt="navigationArrow" />
              <div>Back</div>
            </div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value.replace(/\s/g, ''));
              }}
            />
            {selectedIndexTicker.length !== 0 && (
              <>
                <div className={styles.tickerSelectedTickersSubHeader}>Selected Tickers</div>
                <div
                  style={{
                    minHeight: `${
                      selectedIndexTicker.length > 3 ? 17 : selectedIndexTicker.length * 6 + 0.5
                    }vh`,
                  }}
                  className={styles.tickerSelectedTickersContent}
                >
                  {selectedIndexTicker.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
                    const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          if (tickerSelectorState === MODE_CREATING_INDEX_STEP_ONE) {
                            // Check if the element already exists in selectedIndexTicker
                            const exists = selectedIndexTicker.some(
                              (item) =>
                                item.since === since &&
                                item.baseAsset === baseAsset &&
                                item.quoteAsset === quoteAsset &&
                                item.exchange === exchange,
                            );

                            if (!exists) {
                              if (selectedIndexTicker.length < 10) {
                                setSelectedIndexTicker([
                                  ...selectedIndexTicker,
                                  { baseAsset, quoteAsset, exchange, since },
                                ]);
                              }
                            } else {
                              setSelectedIndexTicker((prevState) =>
                                prevState.filter(
                                  (item) =>
                                    !(
                                      item.since === since &&
                                      item.baseAsset === baseAsset &&
                                      item.quoteAsset === quoteAsset &&
                                      item.exchange === exchange
                                    ),
                                ),
                              );
                            }
                          }
                        }}
                      >
                        <span>
                          <img
                            className={styles.tickerSelectorContentImage}
                            src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                            alt=""
                          />
                          <span>
                            {name} | From {new Date(since).toLocaleDateString()}
                          </span>
                        </span>
                        {tickerSelectorState === MODE_CREATING_INDEX_STEP_ONE ? (
                          selectedIndexTicker.some(
                            (item) =>
                              item.since === since &&
                              item.baseAsset === baseAsset &&
                              item.quoteAsset === quoteAsset &&
                              item.exchange === exchange,
                          ) ? (
                            <span>
                              {' '}
                              {key + 1}{' '}
                              <img
                                className={styles.tickerSelectionIndicatorImage}
                                style={{ width: '28px', height: '28px' }}
                                src={SelectedCircle}
                                alt="notSelected"
                              />
                            </span>
                          ) : (
                            <img
                              style={{ margin: '4.5px' }}
                              src={NotSelectedCircle}
                              alt="notSelected"
                            />
                          )
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                <div className={styles.tickerSelectedTickersSubHeader}>All</div>
              </>
            )}
            <div className={styles.tickerSelectorContent}>
              {values.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
                const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);

                return search === '' || name.includes(search.toUpperCase()) ? (
                  <div
                    key={key}
                    onClick={() => {
                      // Check if the element already exists in selectedIndexTicker
                      const exists = selectedIndexTicker.some(
                        (item) =>
                          item.since === since &&
                          item.baseAsset === baseAsset &&
                          item.quoteAsset === quoteAsset &&
                          item.exchange === exchange,
                      );

                      if (!exists) {
                        if (selectedIndexTicker.length < 10) {
                          setSelectedIndexTicker([
                            ...selectedIndexTicker,
                            { baseAsset, quoteAsset, exchange, since },
                          ]);
                        }
                      } else {
                        setSelectedIndexTicker((prevState) =>
                          prevState.filter(
                            (item) =>
                              !(
                                item.since === since &&
                                item.baseAsset === baseAsset &&
                                item.quoteAsset === quoteAsset &&
                                item.exchange === exchange
                              ),
                          ),
                        );
                      }
                    }}
                  >
                    <span>
                      <img
                        className={styles.tickerSelectorContentImage}
                        src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                        alt=""
                      />
                      <span>
                        {name} | From {new Date(since).toLocaleDateString()}
                      </span>
                    </span>
                    {tickerSelectorState === MODE_CREATING_INDEX_STEP_ONE ? (
                      selectedIndexTicker.some(
                        (item) =>
                          item.since === since &&
                          item.baseAsset === baseAsset &&
                          item.quoteAsset === quoteAsset &&
                          item.exchange === exchange,
                      ) ? (
                        <span>
                          {selectedIndexTicker.findIndex(
                            (item) =>
                              item.since === since &&
                              item.baseAsset === baseAsset &&
                              item.quoteAsset === quoteAsset &&
                              item.exchange === exchange,
                          ) + 1}
                          <img
                            className={styles.tickerSelectionIndicatorImage}
                            style={{ width: '28px', height: '28px' }}
                            src={SelectedCircle}
                            alt="notSelected"
                          />
                        </span>
                      ) : (
                        <img
                          style={{ margin: '4.5px' }}
                          src={NotSelectedCircle}
                          alt="notSelected"
                        />
                      )
                    ) : null}
                  </div>
                ) : null;
              })}
            </div>

            <div className={styles.tickerSelectorFooter}>
              <button
                className={styles.tickerSelectorFooterClear}
                onClick={() => {
                  setSelectedIndexTicker([]);
                }}
              >
                Clear
              </button>
              <button
                className={styles.tickerSelectorFooterNext}
                onClick={() => {
                  if (selectedIndexTicker.length > 1 && createdTicker.name.trim() !== '') {
                    setTickerSelectorState(MODE_SETTING_INDEX_STEP_TWO);
                  }
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
        {tickerSelectorState === MODE_SETTING_INDEX_STEP_TWO && (
          <>
            <div style={{ padding: '4.5vh 3vh' }} className={styles.tickerSelectorHeader}>
              <span>Proportionality setting</span>
              <TickerSelectorCloseIcon
                className={styles.tickerSelectorHeaderClose}
                onClick={onClose}
              />
            </div>
            <div className={styles.tickerSettingsTickersContent}>
              {selectedIndexTicker.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
                const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
                return (
                  <div
                    key={key}
                    onClick={() => {}}
                    className={styles.tickerSettingsTickersContentItem}
                  >
                    <span>
                      <img
                        className={styles.tickerSelectorContentImage}
                        src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                        alt=""
                      />
                      <span>
                        {name} | From {new Date(since).toLocaleDateString()}
                      </span>
                    </span>
                    <span>
                      <input
                        key={key}
                        type="number"
                        style={{
                          border:
                            selectedTickersPercentsArray[key][1] === 0 ? '1px solid red' : 'none',
                        }}
                        value={Number(selectedTickersPercentsArray[key][1]).toFixed(
                          selectedTickersPercentsArray[key][1].toString().includes('.') ? 2 : 0,
                        )}
                        step={0.1}
                        onChange={(e) => {
                          changeDataCustom(e.target.value, key, 1, 0, 100);
                        }}
                      />
                      %
                    </span>
                  </div>
                );
              })}
              <button
                className={styles.tickerSelectorRevert}
                onClick={() => {
                  const selectedTickerPercent = Number(100 / selectedIndexTicker.length).toFixed(2);
                  const array = [];
                  for (let i = 0; i < selectedIndexTicker.length; i++) {
                    array.push([i, Number(selectedTickerPercent)]);
                  }
                  setSelectedTickersPercentsArray(array);
                }}
              >
                Set equally
              </button>
            </div>
            <div className={styles.tickerSelectorFooter}>
              <button
                className={styles.tickerSelectorFooterClear}
                onClick={() => {
                  setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
                }}
              >
                Back
              </button>
              <button
                className={styles.tickerSelectorFooterNext}
                onClick={() => {
                  if (createdTicker.tickers.every((item) => item.percent !== 0)) {
                    setTickerSelectorState(MODE_LIST_OF_INDEX_TICKERS);
                    const created = {
                      ...createdTicker,
                    };
                    apiStrategy.addCustomTicker(created).then((data) => {
                      dispatch(addCreatedTicker(data));
                    });
                    setSelectedIndexTicker([]);
                    setSelectedTickersPercentsArray([]);
                  }
                }}
              >
                Create
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
