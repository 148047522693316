import './MenuPageStyles.scss';
import Logo from '../../assets/images/MenuPage/Logo.svg';
import ProfileIcon from '../../assets/images/MenuPage/ProfileIcon.svg';
import TradingPanelIcon from '../../assets/images/MenuPage/TradingIcon.svg';
import LeaderBoardIcon from '../../assets/images/MenuPage/LeaderBoardIcon.svg';
import PricingIcon from '../../assets/images/MenuPage/PricingIcon.svg';
import DocsIcon from '../../assets/images/MenuPage/DocsIcon.svg';
import LogoutIcon from '../../assets/images/MenuPage/LogOutIcon.svg';
import TelegramIcon from '../../assets/images/MenuPage/TelegramIcon.svg';
import InstagramIcon from '../../assets/images/MenuPage/InstagramIcon.svg';
import FacebookIcon from '../../assets/images/MenuPage/FacebookIcon.svg';
import DialogIcon from '../../assets/images/MenuPage/DialogIcon.svg';
import MenuAccountDropDown from './components/MenuAccountDropdown';
import PlatformPopUp from './components/MenuPlatformPopUp';
import { Provider } from 'react-redux';
import { store } from '../../redux/rootReducer';
import { Link } from 'react-router-dom';

const MenuPage = (): JSX.Element => {
  document.documentElement.style.setProperty('zoom', '100%');
  return (
    <Provider store={store}>
      <div className="pageContainer">
        <div className="pageContainer__logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="pageContainer__logoName">ALGO LAB</div>
        <MenuAccountDropDown />
        <div className="pageContainer__menu">
          <button className="pageContainer__menuItem">
            <img src={ProfileIcon} alt="profile" />
            <div>Profile</div>
          </button>
          <Link to="/">
            <button className="pageContainer__menuItem">
              <img src={TradingPanelIcon} alt="trading" />
              <div>Trading panel</div>
            </button>
          </Link>

          <PlatformPopUp />
          <button className="pageContainer__menuItem">
            <img src={LeaderBoardIcon} alt="leaderboard" />
            <div>Leaderboard</div>
          </button>
          <button className="pageContainer__menuItem">
            <img src={PricingIcon} alt="pricing" />
            <div>Pricing</div>
          </button>
          <button className="pageContainer__menuItem">
            <img src={DocsIcon} alt="docs" />
            <div>Docs</div>
          </button>
        </div>
        <button className="pageContainer__logoutButton">
          <img src={LogoutIcon} alt="log out" />
          <div>Log out</div>
        </button>
        <div className="pageContainer__socialMedias">
          <a className="pageContainer__socialMediaLink">
            <img src={TelegramIcon} alt="telegram" />
            <div>Telegram</div>
          </a>
          <a className="pageContainer__socialMediaLink">
            <img src={InstagramIcon} alt="instagram" />
            <div>Instagram</div>
          </a>
          <a className="pageContainer__socialMediaLink">
            <img src={FacebookIcon} alt="facebook" />
            <div>Facebook</div>
          </a>
        </div>
        <a className="pageContainer__terms">Terms of privacy</a>
        <button className="pageContainer__helperDialog">
          <img src={DialogIcon} alt="dialog icon" />
          <div>Need help?</div>
        </button>
      </div>
    </Provider>
  );
};

export default MenuPage;
