import { useDispatch, useSelector } from 'react-redux';
import { selectTheme, toggleTheme } from '../../../../redux/theme/reducer';

const Header = (): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  return (
    <div
      style={{
        backgroundColor: theme.homeHeaderBackground,
        color: theme.textColor,
        height: '100%',
      }}
      onClick={() => dispatch(toggleTheme())}
    >
      Switch theme
    </div>
  );
};

export default Header;
