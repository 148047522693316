import React, { type FC, type RefObject, useEffect } from 'react';
import {
  type ImperativePanelGroupHandle,
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from 'react-resizable-panels';
import styles from './HomePageStyles.module.css';
import { ResizeHandle } from '../../components/ResizeHandle';
import Chart from './components/Chart';
import classNames from 'classnames';
import {
  type IChartConfig,
  type IStatistics,
  type IStrategy,
  type ITradeData,
} from '../../interfaces/IStrategy';
import { LoaderOverlay } from '../../components/LoaderOverlay';
import { History } from './components/History';
// import { Log } from './components/Log';
import { HistorySectionModes } from '../../enums/HistorySectionMode';
import StrategyEditor from './components/StrategyEditor/StrategyEditor';
import { apiBacktest } from '../../services/api/Backtest/ApiBacktest';
import { type IHomePageState as IState, DefaultHomePageState } from './DefaultState';
import { type IChartHeaderProps } from '../../interfaces/chartData';
import { apiExchange } from '../../services/api/Exchange/ApiExchange';
import { GET_TIMEFRAME_NAME } from '../../configs/chart';
import { tradesWebSocket } from '../../services/TradesWebSocket';
import { useSelector } from '../../redux/rootReducer';
import Header from './components/Header';
import { connect } from 'react-redux';
import { selectTheme, toggleTheme } from '../../redux/theme/reducer';
import {
  type IStrategyTickerData,
  type ICandleData,
  type CustomTicker,
} from '../../services/api/Strategy/types/IStrategyResponse';
import { selectCreatedTickers } from '../../redux/chartTickers/reducer';

class HomePage extends React.Component<unknown, IState> {
  public chartContainerRef: RefObject<ImperativePanelHandle>;
  public historyContainerRef: RefObject<ImperativePanelHandle>;
  public groupPanelSection: RefObject<ImperativePanelGroupHandle>;
  public mobileContent: 'chart' | 'terminal' | 'strategy';
  public state: IState = DefaultHomePageState;
  public theme: { primaryColor: string; secondaryColor: string };
  private ro: ResizeObserver | null = null;
  private historyRo: ResizeObserver | null = null;
  private chartSocket: WebSocket | null = null;
  private isLoadingMore = false;
  private backTestId: string | null = null;
  private isDynamicBackTest = false;
  private visibleRange: { from: number; to: number } = { from: 0, to: 0 };
  private customTickers: IStrategyTickerData[] = [];
  private activeTickerSince: number | null = null;
  private chartDate: IChartConfig = {
    date_to: null,
    date_from: null,
  };

  constructor(props: unknown) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.historyContainerRef = React.createRef();
    this.groupPanelSection = React.createRef<ImperativePanelGroupHandle>();
    this.mobileContent = 'strategy';
  }

  public componentDidMount(): void {
    const chartContainer = document.querySelector('.chart_container');
    const historyContainer = document.querySelector('#history_container');
    if (chartContainer) {
      this.setState({
        chartContainerSizes: {
          width: chartContainer.clientWidth, // width of chart header
          height: chartContainer.clientHeight - 37, // height of chart header
        },
      });

      this.ro = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.target === chartContainer) {
          setTimeout(() => {
            this.setState({
              chartContainerSizes: {
                width: chartContainer.clientWidth, // width of chart header
                height: chartContainer.clientHeight - 37, // height of chart header
              },
            });
          }, 0);
        }
      });

      this.ro.observe(chartContainer);
    }

    if (historyContainer) {
      this.historyRo = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.target === historyContainer) {
          setTimeout(() => {
            this.setState({
              historyContainerSize: {
                width: historyContainer.clientWidth,
                height: historyContainer.clientHeight,
              },
            });
          }, 0);
        }
      });

      this.historyRo.observe(historyContainer);
    }

    this.updateChartData(this.state.chartHeaderData.data);
    if (!tradesWebSocket.active) {
      tradesWebSocket.open(this.onTradesUpdate.bind(this));
    }
  }

  public componentWillUnmount(): void {
    this.chartSocket?.close();
    tradesWebSocket.close();
  }

  public changeMobileContent = (showTerminalValue: 'chart' | 'terminal' | 'strategy'): void => {
    this.setState({
      mobileContent: showTerminalValue,
    });
  };

  private onTradesUpdate([strategyId, [trades, stats]]: [
    string,
    [ITradeData[], IStatistics],
  ]): void {
    if (strategyId !== this.backTestId) return;
    let stateTrades = [...this.state.trades];

    trades.forEach((trade) => {
      const index = stateTrades.findIndex((e) => e.time === trade.time);
      if (index !== -1) {
        stateTrades = [
          ...stateTrades.slice(0, index),
          trade,
          ...stateTrades.slice(index + 1, stateTrades.length),
        ];
      } else {
        stateTrades = [...stateTrades, trade];
      }
    });

    this.setState({ trades: stateTrades, stats });
  }

  public createChartSocket(): void {
    this.chartSocket?.close();
    this.chartSocket = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${(
        this.state.chartHeaderData.data.baseAsset + this.state.chartHeaderData.data.quoteAsset
      ).toLowerCase()}@kline_${GET_TIMEFRAME_NAME(this.state.chartHeaderData.data.timeframe)}`,
    );

    this.chartSocket.onmessage = (rawData) => {
      if (this.state.candles.length === 0) return;

      try {
        const { data } = JSON.parse(rawData.data);

        const isLast = this.state.candles[this.state.candles.length - 1].time === data.k.t;

        this.setState({
          candles: [
            ...this.state.candles.slice(0, this.state.candles.length - (isLast ? 1 : 0)),
            {
              time: data.k.t,
              open: parseFloat(data.k.o),
              high: parseFloat(data.k.h),
              low: parseFloat(data.k.l),
              close: parseFloat(data.k.c),
              volume: parseFloat(data.k.v),
              trades: data.k.n,
            },
          ],
        });
      } catch (_) {}
    };
  }

  public updateChartData = (data: IChartHeaderProps['data']): void => {
    this.setState({
      candles: [],
      trades: [],
    });
    const timeframeName = GET_TIMEFRAME_NAME(data.timeframe);
    if (data.baseAsset === '') {
      // TODO: ???1
      const customTicker = this.customTickers.find((e) => e._id === data.customIndex?._id);
      if (!customTicker) return;

      this.getCustomTickerCandles(customTicker, timeframeName)
        .then((candles) => {
          this.chartSocket?.close();
          this.setState({ candles });
          // this.createChartSocket();
        })
        .catch(() => {});
      return;
    }

    apiExchange
      .getCandles(
        data.exchange,
        { base_asset: data.baseAsset, quote_asset: data.quoteAsset },
        timeframeName,
      )
      .then((candles) => {
        this.setState({ candles });
        this.createChartSocket();
      });
  };

  private async getCustomTickerCandles(
    customTicker: IStrategyTickerData,
    timeframeName: string,
    endTime?: number,
  ): Promise<ICandleData[]> {
    const tickersArray: CustomTicker = customTicker.tickers.map((ticker) => {
      return {
        base_asset: ticker.baseAsset,
        quote_asset: ticker.quoteAsset,
        percent: ticker.percent,
      };
    });
    const candles: ICandleData[] = await apiExchange.getCandles(
      0,
      tickersArray,
      timeframeName,
      endTime,
    );
    return candles;
  }

  public loadMoreChartData = (): void => {
    if (this.isLoadingMore) return;

    this.isLoadingMore = true;

    const timestamp = this.state.candles[0]?.time;

    if (!timestamp || this.activeTickerSince === timestamp) {
      this.isLoadingMore = false;
      return;
    }

    const { data: oldChartData } = this.state.chartHeaderData;
    const timeframeName = GET_TIMEFRAME_NAME(this.state.chartHeaderData.data.timeframe);
    if (this.state.chartHeaderData.data.baseAsset === '') {
      const customTicker = this.customTickers.find(
        (e) => e._id === this.state.chartHeaderData.data.customIndex?._id,
      );

      if (!customTicker) {
        this.isLoadingMore = false;
        return;
      }

      this.getCustomTickerCandles(customTicker, timeframeName, timestamp).then((candlesApi) => {
        const candles = candlesApi.slice(0, -1);
        if (this.state.chartHeaderData.data.quoteAsset !== oldChartData.quoteAsset) return;

        const oldCandles = this.state.candles.slice(1);
        this.isLoadingMore = false;
        if (
          candles.find(({ time: newTime }) =>
            oldCandles.find(({ time: oldTime }) => newTime === oldTime),
          )
        ) {
          return;
        }

        this.setState({ candles: [...candles, ...oldCandles] });
      });
      return;
    }

    apiExchange
      .getCandles(
        this.state.chartHeaderData.data.exchange,
        {
          base_asset: this.state.chartHeaderData.data.baseAsset,
          quote_asset: this.state.chartHeaderData.data.quoteAsset,
        },
        timeframeName,
        timestamp,
      )
      .then((candlesApi) => {
        if (candlesApi.length === 0) {
          this.activeTickerSince = this.state.candles[0]?.time ?? null;
          return;
        } else {
          this.activeTickerSince = null;
        }

        const { data: newChartData } = this.state.chartHeaderData;
        if (
          oldChartData.baseAsset !== newChartData.baseAsset ||
          oldChartData.quoteAsset !== newChartData.quoteAsset ||
          oldChartData.timeframe !== newChartData.timeframe ||
          oldChartData.exchange !== newChartData.exchange
        ) {
          return;
        }

        this.isLoadingMore = false;

        const firstTime = this.state.candles[0]?.time;
        let candles: ICandleData[];

        if (firstTime) {
          const lastTime = candlesApi[candlesApi.length - 1].time;

          if (lastTime >= firstTime) {
            candles = [...candlesApi.slice(0, -((lastTime - firstTime) / 100 + 1))];
          } else {
            candles = [...candlesApi, ...this.state.candles];
          }
        } else {
          candles = candlesApi;
        }
        this.setState({ candles });
      })
      .catch(() => (this.isLoadingMore = false));
  };

  public onChangeChartLoader = (value: boolean): void => {
    this.setState({ isChartLoading: value });
  };

  public setHistorySectionMode = (mode: HistorySectionModes | null): void => {
    if (mode === HistorySectionModes.FULL_SCREEN) {
      this.groupPanelSection.current.setLayout([0, 100]);
    }
    if (mode === HistorySectionModes.COLLAPSE) {
      this.groupPanelSection.current.setLayout([95, 5]);
    }
    if (!mode) {
      this.groupPanelSection.current.setLayout([75, 25]);
    }

    this.setState({ historySectionMode: mode });
  };

  public collapseConstructor = (): void => {
    this.groupPanelSection.current.setLayout([100, 0]);
  };

  public backTest = (strategy: IStrategy | null): void => {
    this.backTestId = strategy?._id ?? null;

    if (strategy === null) {
      this.setState({
        trades: [],
        stats: DefaultHomePageState.stats,
      });
      return;
    }
    this.onChangeChartLoader(true);
    // console.log(
    //   this.visibleRange,
    //   this.isDynamicBackTest,
    //   this.state.candles[this.visibleRange.from]?.time,
    //   this.state.candles[this.visibleRange.to - 1]?.time,
    // );
    apiBacktest
      .backtest({
        chart: this.isDynamicBackTest
          ? {
              date_from: this.state.candles[this.visibleRange.from]?.time,
              date_to: this.state.candles[this.visibleRange.to - 1]?.time,
            }
          : this.chartDate,
        strategy,
      })
      .then((result) => {
        if (!result) {
          this.onChangeChartLoader(false);
          this.setState({
            trades: [],
            stats: DefaultHomePageState.stats,
          });
          return;
        }
        if (this.backTestId === strategy._id) {
          if (Array.isArray(result.trades[0])) return; // TODO: Handle Hedge Mode

          this.setState({
            stats: result.stats,
            trades: result.trades as ITradeData[],
          });

          this.onChangeChartLoader(false);
        }
      });
  };

  public setChartHeaderData = (data: IChartHeaderProps['data']): void => {
    if (
      this.state.chartHeaderData.data.baseAsset !== data.baseAsset ||
      this.state.chartHeaderData.data.quoteAsset !== data.quoteAsset ||
      this.state.chartHeaderData.data.exchange !== data.exchange ||
      this.state.chartHeaderData.data.timeframe !== data.timeframe ||
      this.state.chartHeaderData.data?.customIndex?._id !== data.customIndex?._id
    ) {
      this.updateChartData(data);
    }
    this.setState({
      chartHeaderData: { data },
    });
  };

  public Hook: FC = () => {
    const selector = useSelector((state) => state.chart);
    const theme = useSelector((state) => state.theme);
    const customTicker = useSelector((state) => state.chartTickers);

    useEffect(() => {
      this.setState({ theme });
    }, [theme]);
    useEffect(() => {
      this.setState({ theme });
      this.chartDate = {
        date_to: selector.date.dateTo,
        date_from: selector.date.dateFrom,
      };
    }, [selector.date]);
    useEffect(() => {
      this.isDynamicBackTest = selector.isDynamicBackTest;
    }, [selector.isDynamicBackTest]);
    useEffect(() => {
      this.customTickers = customTicker.createdTickers;
    }, [customTicker.createdTickers]);
    return <></>;
  };

  public render(): React.ReactNode {
    const showMobileVersion = window.innerWidth < 768;
    const contentStateValue = this.state.mobileContent;
    const { theme } = this.state;
    return (
      <div style={{ backgroundColor: theme.homeBackground }} className={styles.root}>
        <this.Hook />
        <nav className={styles.navigation}>
          <Header />
        </nav>
        {!showMobileVersion && (
          <PanelGroup direction={`${showMobileVersion ? 'vertical' : 'horizontal'}`}>
            <>
              <Panel className={styles.rowContainer} defaultSize={85}>
                <PanelGroup direction="horizontal" ref={this.groupPanelSection}>
                  <Panel defaultSize={75}>
                    <PanelGroup direction="vertical">
                      <Panel
                        collapsible={true}
                        className={classNames(styles.rowContainer, 'chart_container')}
                        ref={this.chartContainerRef}
                        defaultSize={55}
                        minSize={30}
                        id="main"
                      >
                        <div className={styles.content}>
                          <Chart
                            id="main"
                            width={this.state.chartContainerSizes.width}
                            height={this.state.chartContainerSizes.height}
                            chartData={{
                              candles: this.state.candles,
                              header: {
                                ...this.state.chartHeaderData,
                                setData: this.setChartHeaderData,
                              },
                            }}
                            trades={this.state.strategyOn ? this.state.trades : []}
                            loadMore={this.loadMoreChartData}
                            setChartLoading={this.onChangeChartLoader}
                            setVisibleRange={(value) => {
                              this.visibleRange = value;
                            }}
                          />
                          <LoaderOverlay
                            position={'absolute'}
                            isShow={this.state.isChartLoading}
                            imageWidth={140}
                          />
                        </div>
                      </Panel>
                      <ResizeHandle className={styles.resizeHandleHorizontal} />
                      <Panel
                        collapsible
                        defaultSize={45}
                        maxSize={100}
                        minSize={10}
                        ref={this.historyContainerRef}
                      >
                        <History
                          historyMode={this.state.historySectionMode}
                          setHistorySectionMode={this.setHistorySectionMode}
                          stats={this.state.stats}
                          trades={this.state.trades}
                          containerSize={this.state.historyContainerSize}
                          strategyOn={this.state.strategyOn && this.state.trades.length > 0}
                        />
                        {/* <Log /> */}
                      </Panel>
                    </PanelGroup>
                  </Panel>
                  <ResizeHandle
                    className={classNames(styles.resizeHandleVertical, {
                      invisible: this.state.historySectionMode,
                    })}
                  />
                  <Panel
                    className={styles.rowContainer}
                    maxSize={40}
                    minSize={18}
                    collapsible={true}
                    defaultSize={25}
                  >
                    <div className={classNames(styles.content)}>
                      <StrategyEditor
                        chartHeaderData={{
                          ...this.state.chartHeaderData,
                          setData: this.setChartHeaderData,
                        }}
                        backTest={this.backTest}
                        setStrategyEnabled={(value) => {
                          this.setState({ strategyOn: value as boolean });
                        }}
                        strategyEnabled={this.state.strategyOn}
                        isChartLoading={this.state.isChartLoading}
                        collapseConstructor={this.collapseConstructor}
                      />
                    </div>
                  </Panel>
                </PanelGroup>
              </Panel>
            </>
          </PanelGroup>
        )}
        {showMobileVersion && (
          <div className={styles.mobileContainer}>
            <div className={styles.mobileContentSwitcher}>
              <button
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                className={`${styles.mobileContentSwitcherItem} ${
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  contentStateValue === 'chart' && styles.mobileContentSwitcherItemActive
                }`}
                onClick={() => {
                  this.changeMobileContent('chart');
                }}
              >
                Chart
              </button>
              <button
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                className={`${styles.mobileContentSwitcherItem} ${
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  contentStateValue === 'strategy' && styles.mobileContentSwitcherItemActive
                }`}
                onClick={() => {
                  this.changeMobileContent('strategy');
                }}
              >
                Strategy
              </button>
              <button
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                className={`${styles.mobileContentSwitcherItem} ${
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  contentStateValue === 'terminal' && styles.mobileContentSwitcherItemActive
                }`}
                onClick={() => {
                  this.changeMobileContent('terminal');
                }}
              >
                Terminal
              </button>
            </div>
            <PanelGroup direction="vertical">
              {contentStateValue === 'chart' && (
                <Panel
                  collapsible={true}
                  className={classNames(styles.rowContainer, 'chart_container')}
                  ref={this.chartContainerRef}
                  defaultSize={100}
                  minSize={100}
                  id="main"
                >
                  <div className={styles.content}>
                    <Chart
                      id="main"
                      width={this.state.chartContainerSizes.width}
                      height={this.state.chartContainerSizes.height}
                      chartData={{
                        candles: this.state.candles,
                        header: {
                          ...this.state.chartHeaderData,
                          setData: this.setChartHeaderData,
                        },
                      }}
                      trades={this.state.strategyOn ? this.state.trades : []}
                      loadMore={this.loadMoreChartData}
                      setChartLoading={this.onChangeChartLoader}
                      setVisibleRange={(value) => {
                        this.visibleRange = value;
                      }}
                    />
                    <LoaderOverlay
                      position={'absolute'}
                      isShow={this.state.isChartLoading}
                      imageWidth={140}
                    />
                  </div>
                </Panel>
              )}
              {contentStateValue === 'terminal' && (
                <PanelGroup direction="vertical" disablePointerEventsDuringResize={true}>
                  <Panel className={styles.columnContainer} defaultSize={75}>
                    <PanelGroup direction="vertical">
                      <Panel defaultSize={100}>
                        <div className={styles.content}>Terminal</div>
                      </Panel>
                      <ResizeHandle
                        className={classNames(styles.resizeHandleHorizontal, 'invisible')}
                      />
                    </PanelGroup>
                  </Panel>
                </PanelGroup>
              )}
              {contentStateValue === 'strategy' && (
                <Panel defaultSize={75} collapsible>
                  <PanelGroup direction="horizontal">
                    <Panel
                      className={styles.rowContainer}
                      collapsible={true}
                      maxSize={25}
                      defaultSize={20}
                    >
                      <div className={classNames(styles.content)}>
                        <StrategyEditor
                          chartHeaderData={{
                            ...this.state.chartHeaderData,
                            setData: this.setChartHeaderData,
                          }}
                          backTest={this.backTest}
                          setStrategyEnabled={(value) => {
                            this.setState({ strategyOn: value as boolean });
                          }}
                          strategyEnabled={this.state.strategyOn}
                          isChartLoading={this.state.isChartLoading}
                        />
                      </div>
                    </Panel>
                  </PanelGroup>
                </Panel>
              )}
            </PanelGroup>
            <div style={{ height: '400px' }}></div>
          </div>
        )}
      </div>
    );
  }
}

// mapStateToProps function to select data from the store
const mapStateToProps = (state): any => ({
  theme: selectTheme(state),
  customIndexes: selectCreatedTickers(state),
});

// mapDispatchToProps function to dispatch actions
const mapDispatchToProps = (dispatch): any => ({
  toggleTheme: () => dispatch(toggleTheme()),
});

// Connect the component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
