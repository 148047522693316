import {
  type ILiquidationData,
  type ITakeProfitData,
  type ITradeData,
} from '../../../../interfaces/IStrategy';
import { type Axis } from 'klinecharts';
import { type ITradeCache } from '../../../../interfaces/chartData';
import { TRADES_COLORS } from './config';

export const drawText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  textAlign: CanvasTextAlign = 'right',
  color = 'black',
  size = 10,
  fontWeight = 'normal',
): void => {
  ctx.fillStyle = color;
  ctx.textAlign = textAlign;
  ctx.font = `${fontWeight} ${size}px Michroma, sans-serif`;
  ctx.fillText(text, x, y);
};

export const drawPriceLine = (
  ctx: CanvasRenderingContext2D,
  yAxis: Axis,
  price: number,
  color: string,
  startX: number,
  endX: number,
  prefix: string,
  precision = 3,
  isHovered = false,
  amt?: number,
): void => {
  const y = yAxis.convertToPixel(price);
  ctx.fillStyle = color;
  ctx.fillRect(startX, y, endX - startX, 1);

  if (isHovered) {
    drawText(ctx, `${prefix}: ${price.toFixed(precision)}`, endX + 5, y, 'left', 'white', 7.5);
    if (amt !== undefined && !prefix.startsWith('DCA')) {
      drawText(ctx, `${amt.toFixed(2)}$`, startX - 5, y, 'right', '#E10000', 7.5, '500');
    }
  }
};

export const drawBackground = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  endX: number,
  color: string,
  y: number,
  h: number,
  isDown = false,
): void => {
  const gradient = ctx.createLinearGradient(startX, y, startX, y + h);
  gradient.addColorStop(isDown ? 0 : 1, '#0E64000A');
  gradient.addColorStop(!isDown ? 0 : 1, color);
  ctx.beginPath();
  ctx.fillStyle = gradient;
  ctx.fillRect(startX, y, endX - startX, h);
  ctx.closePath();
};

export const drawTakeProfit = (
  ctx: CanvasRenderingContext2D,
  xAxis: Axis,
  yAxis: Axis,
  takeProfitData: ITakeProfitData | ILiquidationData,
  trade: ITradeData,
  tradeCache: ITradeCache,
  startX: number,
  endX: number,
  index: number,
  precision = 3,
  isHovered: boolean,
): void => {
  const prices = takeProfitData.price;
  let lastX = startX;
  let lastY = yAxis.convertToPixel(prices[0]);

  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.moveTo(lastX, lastY);

  let averageIndex = 0;

  for (const price of prices.slice(1)) {
    const y = yAxis.convertToPixel(price);
    const x = trade.averages
      ? xAxis.convertToPixel(tradeCache.hitCandles.averages?.[trade.averages[averageIndex]?.price])
      : endX;

    averageIndex++;

    ctx.lineTo(x, lastY);
    ctx.lineTo(x, y);

    lastY = y;
    lastX = x;
  }

  ctx.lineTo(endX, lastY);
  ctx.lineWidth = 1;
  ctx.strokeStyle = 'qty' in takeProfitData ? 'rgb(68,183,7)' : TRADES_COLORS.LIQUIDATION_LINE;
  ctx.stroke();
  ctx.closePath();

  if (isHovered) {
    drawText(
      ctx,
      `${'qty' in takeProfitData ? `TP${index + 1}` : 'LIQUIDATION'}: ${prices[
        prices.length - 1
      ].toFixed(precision)}`,
      endX + 5,
      lastY,
      'left',
      'white',
      7.5,
    );
  }
};
