import { type FC, useState } from 'react';
import StrategyElement from '../../../../../../components/StrategyElement/StrategyElement';
// import { apiStrategy } from '../../../../../../services/api/Strategy/ApiStrategy';
import { type IStrategyAllResponse } from '../../../../../../services/api/Strategy/types/IStrategyResponse';
import { Components } from '../../Components';
import ToggleIcon from '../../../../../../assets/images/Strategy/ToggleIcon.svg';
import './savedStrategies.scss';

interface IProps {
  strategies: IStrategyAllResponse;
  setStrategies: (value: IStrategyAllResponse) => void;
  setStrategyNode: (value: Components | null) => void;
  setSelectedStrategy: (value: string) => void;
  addStrategyHistory: (value: { _id: string; name: string }) => void;
}

const StrategyLibrary: FC<IProps> = ({
  //   strategies,
  setStrategies,
  setSelectedStrategy,
  setStrategyNode,
  addStrategyHistory,
}) => {
  const DCA_TYPE = 'DCA Strategies';
  const FIXED_RISK_TYPE = 'Fixed risk strategies';
  const VOLUME_INCREASE_TYPE = 'Volume Increase strategies';
  const strategies = [
    // { _id: '1', name: 'Strategy 1', type: DCA_TYPE },
    // { _id: '2', name: 'Strategy 2', type: FIXED_RISK_TYPE },
    // { _id: '3', name: 'Strategy 3', type: VOLUME_INCREASE_TYPE },
    // { _id: '4', name: 'Strategy 4', type: DCA_TYPE },
    // { _id: '5', name: 'Strategy 5', type: FIXED_RISK_TYPE },
    // { _id: '6', name: 'Strategy 6', type: VOLUME_INCREASE_TYPE },
    // { _id: '7', name: 'Strategy 7', type: DCA_TYPE },n
    // { _id: '8', name: 'Strategy 8', type: FIXED_RISK_TYPE },
    // { _id: '9', name: 'Strategy 9', type: VOLUME_INCREASE_TYPE },
  ];
  //   const removeStrategyHandler = async (id: string): Promise<void> => {
  //     const result = await apiStrategy.removeStrategy(id);
  //     if (result) {
  //       const index = strategies.findIndex((e) => e._id === id);

  //       if (index !== -1) {
  //         setStrategies(
  //           strategies.slice(0, index).concat(strategies.slice(index + 1, strategies.length)),
  //         );
  //       }
  //     }
  //   };
  const [openedTypes, setOpenedTypes] = useState([]);

  return (
    <>
      <div className="strategyTypeTitle">Type of Strategy</div>
      <button
        className={
          'strategyTypeToggleButton' +
          (openedTypes.includes(DCA_TYPE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategies.some((strategy) => strategy.type === DCA_TYPE)
            ? ''
            : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategies.some((strategy) => strategy.type === DCA_TYPE)}
        onClick={() => {
          if (strategies.some((strategy) => strategy.type === DCA_TYPE)) {
            if (!openedTypes.includes(DCA_TYPE)) {
              setOpenedTypes([...openedTypes, DCA_TYPE]);
            } else {
              setOpenedTypes(openedTypes.filter((type) => type !== DCA_TYPE));
            }
          }
        }}
      >
        DCA Strategies <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(DCA_TYPE) &&
        strategies.map((strategy) => {
          if (strategy.type !== DCA_TYPE) {
            return null;
          }
          return (
            <StrategyElement
              key={strategy._id}
              _id={strategy._id}
              name={strategy.name}
              editStrategy={() => {
                addStrategyHistory(strategy);
                setSelectedStrategy(strategy._id);
                setStrategyNode(Components.CONSTRUCTOR);
              }}
              removeHandler={(id) => {
                //   removeStrategyHandler(id);
              }}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton' +
          (openedTypes.includes(FIXED_RISK_TYPE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategies.some((strategy) => strategy.type === FIXED_RISK_TYPE)
            ? ''
            : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategies.some((strategy) => strategy.type === FIXED_RISK_TYPE)}
        onClick={() => {
          if (!openedTypes.includes(FIXED_RISK_TYPE)) {
            setOpenedTypes([...openedTypes, FIXED_RISK_TYPE]);
          } else {
            setOpenedTypes(openedTypes.filter((type) => type !== FIXED_RISK_TYPE));
          }
        }}
      >
        Fixed risk strategies <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(FIXED_RISK_TYPE) &&
        strategies.map((strategy) => {
          if (strategy.type !== FIXED_RISK_TYPE) {
            return null;
          }
          return (
            <StrategyElement
              key={strategy._id}
              _id={strategy._id}
              name={strategy.name}
              editStrategy={() => {
                addStrategyHistory(strategy);
                setSelectedStrategy(strategy._id);
                setStrategyNode(Components.CONSTRUCTOR);
              }}
              removeHandler={(id) => {
                //   removeStrategyHandler(id);
              }}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton' +
          (openedTypes.includes(VOLUME_INCREASE_TYPE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategies.some((strategy) => strategy.type === VOLUME_INCREASE_TYPE)
            ? ''
            : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategies.some((strategy) => strategy.type === VOLUME_INCREASE_TYPE)}
        onClick={() => {
          if (!openedTypes.includes(VOLUME_INCREASE_TYPE)) {
            setOpenedTypes([...openedTypes, VOLUME_INCREASE_TYPE]);
          } else {
            setOpenedTypes(openedTypes.filter((type) => type !== VOLUME_INCREASE_TYPE));
          }
        }}
      >
        Volume Increase strategies <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(VOLUME_INCREASE_TYPE) &&
        strategies.map((strategy) => {
          if (strategy.type !== VOLUME_INCREASE_TYPE) {
            return null;
          }
          return (
            <StrategyElement
              key={strategy._id}
              _id={strategy._id}
              name={strategy.name}
              editStrategy={() => {
                addStrategyHistory(strategy);
                setSelectedStrategy(strategy._id);
                setStrategyNode(Components.CONSTRUCTOR);
              }}
              removeHandler={(id) => {
                //   removeStrategyHandler(id);
              }}
            />
          );
        })}
    </>
  );
};

export default StrategyLibrary;
