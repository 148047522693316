import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useSelector } from '../../../redux/rootReducer';

import type { Exchanges, IExchange } from '../../../interfaces/IExchange';

import type { IAuthFormProps } from '../Auth';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GSelect from '../../../components/GSelect/GSelect';

import exchanges from '../../../configs/exchanges';

const ExchangeApiForm: FC<IAuthFormProps> = ({ goBack }) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const authStore = useSelector((state) => state.auth);

  const twoFactorEnabled = authStore.user?.two_factor;

  const exchange = useRef<IExchange>(null);
  const account = useRef<HTMLInputElement>();
  const apiKey = useRef<HTMLInputElement>();
  const secret = useRef<HTMLInputElement>();

  const onChangeInput = (): void => {
    setDisabled(!account.current?.value || !apiKey.current?.value || !secret.current?.value);
  };

  const ExchangesSelect = GSelect<Exchanges, IExchange>;

  return (
    <form
      className={cn('auth-form', !twoFactorEnabled && 'auth-form_disabled')}
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {twoFactorEnabled && (
        <div className="auth-form__back" onClick={goBack}>
          <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66699 7.00008L0.959885 6.29297L0.252779 7.00008L0.959885 7.70719L1.66699 7.00008ZM17.667 8.00008C18.2193 8.00008 18.667 7.55237 18.667 7.00008C18.667 6.4478 18.2193 6.00008 17.667 6.00008V8.00008ZM6.29322 0.959641L0.959885 6.29297L2.3741 7.70719L7.70743 2.37385L6.29322 0.959641ZM0.959885 7.70719L6.29322 13.0405L7.70743 11.6263L2.3741 6.29297L0.959885 7.70719ZM1.66699 8.00008H17.667V6.00008H1.66699V8.00008Z"
              fill="#288DF5"
            />
          </svg>

          <span>2FA Authentication</span>
        </div>
      )}

      <div className="auth-form-header">
        <span className="auth-form-header__title">Add API data</span>
        <p className="auth-form-header__description">Please connect your exchange</p>
      </div>

      <div className="auth-form-body">
        <ExchangesSelect reference={exchange} source={exchanges} label="Crypto exchange:" />

        <GInput
          reference={account}
          type={InputTypes.TEXT}
          name="account"
          placeholder="Account Name"
          onChange={onChangeInput}
        />

        <GInput
          reference={apiKey}
          type={InputTypes.TEXT}
          name="api_key"
          placeholder="API key"
          onChange={onChangeInput}
        />

        <GInput
          reference={secret}
          type={InputTypes.PASSWORD}
          name="secret"
          placeholder="Secret"
          onChange={onChangeInput}
        />
      </div>

      {!twoFactorEnabled && (
        <div className="auth-form-modal">
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="2" width="80" height="80" rx="40" fill="#288DF5" />
            <path
              d="M48.9459 81.3923C54.119 80.4802 59.0618 78.558 63.492 75.7357C67.9222 72.9133 71.7532 69.246 74.7661 64.9431C77.779 60.6402 79.9149 55.7859 81.0518 50.6576C82.1888 45.5292 82.3045 40.2271 81.3923 35.0541C80.4802 29.881 78.558 24.9382 75.7357 20.508C72.9133 16.0778 69.246 12.2468 64.9431 9.23392C60.6401 6.22099 55.7859 4.08509 50.6576 2.94816C45.5292 1.81123 40.2271 1.69554 35.0541 2.60769C29.881 3.51984 24.9382 5.44198 20.508 8.26435C16.0778 11.0867 12.2468 14.754 9.23391 19.0569C6.22099 23.3599 4.08509 28.2141 2.94816 33.3424C1.81123 38.4708 1.69554 43.7729 2.60769 48.9459C3.51984 54.119 5.44198 59.0618 8.26435 63.492C11.0867 67.9222 14.754 71.7532 19.057 74.7661C23.3599 77.779 28.2141 79.9149 33.3424 81.0518C38.4708 82.1888 43.7729 82.3045 48.9459 81.3923L48.9459 81.3923Z"
              stroke="#0049FF"
              strokeWidth="4"
            />
            <path d="M42 42L42 18" stroke="white" strokeWidth="4" strokeLinecap="square" />
            <path d="M42 62L42 66" stroke="white" strokeWidth="4" strokeLinecap="square" />
          </svg>

          <div className="auth-form-modal__title">
            You need to set up 2FA to connect your exchange
          </div>
          <div className="auth-form-modal__description">
            We care about the security of your data, so to add an API, please set up two-factor
            authentication!
          </div>
        </div>
      )}

      <div className="auth-form-controls auth-form-controls_half">
        {twoFactorEnabled ? (
          <GButton
            title="Connect"
            type={ButtonTypes.BUTTON}
            color={ButtonColors.BLUE}
            bordered={false}
            filled={true}
            disabled={disabled}
          />
        ) : (
          <GButton
            title="2FA Authentication"
            type={ButtonTypes.BUTTON}
            color={ButtonColors.BLUE}
            bordered={false}
            filled={true}
            onClick={goBack}
          />
        )}
      </div>

      <Link to="/" className="auth-form__later">
        <span>Set up later</span>

        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.334 7.00016L18.0411 7.70727L18.7482 7.00016L18.0411 6.29306L17.334 7.00016ZM1.33398 6.00016C0.781701 6.00016 0.333985 6.44788 0.333985 7.00016C0.333985 7.55245 0.781701 8.00016 1.33398 8.00016L1.33398 6.00016ZM12.7078 13.0406L18.0411 7.70727L16.6269 6.29306L11.2935 11.6264L12.7078 13.0406ZM18.0411 6.29306L12.7078 0.959722L11.2935 2.37394L16.6269 7.70727L18.0411 6.29306ZM17.334 6.00016L1.33398 6.00016L1.33398 8.00016L17.334 8.00016L17.334 6.00016Z"
            fill="#B4B4B4"
          />
        </svg>
      </Link>
    </form>
  );
};

export default ExchangeApiForm;
