import {
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';

import StrategyEditorHeader from './components/StrategyEditorHeader/StrategyEditorHeader';
import StrategyEditorList from './components/StrategyEditorList/StrategyEditorList';
import StrategyEditorEmpty from './components/StrategyEditorEmpty/StrategyEditorEmpty';
import StrategyEditorModes from './components/StrategyModes/StrategyEditorModes';
import SavedStrategies from './components/SavedStrategies/SavedStrategies';
import ComeBackArrow from '../../../../assets/images/Strategy/ComeBackArrow.svg';
import './strategyEditor.scss';
import { Strategy } from './components/Strategy/Strategy';
import {
  type IStrategyAllData,
  type IStrategyAllResponse,
} from '../../../../services/api/Strategy/types/IStrategyResponse';
import { apiStrategy } from '../../../../services/api/Strategy/ApiStrategy';
import { Components } from './Components';
import { type IChartHeaderProps } from '../../../../interfaces/chartData';
import { type IStrategy } from '../../../../interfaces/IStrategy';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../redux/theme/reducer';
import StrategyLibrary from './components/StrategyLibrary/StrategyLibrary';

interface IComponentData {
  title: string;
  node: ReactNode;
  listHidden: boolean;
}

interface IProps {
  chartHeaderData: IChartHeaderProps;
  backTest: (data: IStrategy) => void;
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  isChartLoading: boolean;
  collapseConstructor?: () => void;
}

const StrategyEditor: FC<IProps> = (props) => {
  const [strategyNode, setStrategyNode] = useState<Components | null>(null);
  const [selectedStrategy, setSelectedStrategy] = useState<string | null>(null);
  const [strategies, setStrategies] = useState<IStrategyAllResponse>([]);
  const [strategyHistory, setStrategyHistory] = useState<IStrategyAllResponse>([]);

  const theme = useSelector(selectTheme);

  const addStrategyHistory = (data: IStrategyAllData): void => {
    if (strategyHistory.find((e) => e._id === data._id)) return;

    setStrategyHistory([...strategyHistory, data]);
  };

  const removeStrategyHistory = (data: IStrategyAllData): void => {
    const history = strategyHistory.filter((e) => e._id !== data._id);

    setStrategyHistory(history);
  };

  useEffect(() => {
    if (strategyHistory.length === 0) {
      setStrategyNode(null);
    } else {
      if (selectedStrategy && !strategyHistory.find(({ _id }) => _id === selectedStrategy)) {
        setSelectedStrategy(strategyHistory[0]?._id ?? null);
      }
    }
  }, [strategyHistory]);

  useEffect(() => {
    if (strategyHistory.length === 0) {
      setStrategyNode(null);
    } else {
      if (selectedStrategy && !strategyHistory.find(({ _id }) => _id === selectedStrategy)) {
        setSelectedStrategy(strategyHistory[0]?._id ?? null);
      }
    }
  }, [strategyHistory]);

  const strategyNodes: Record<Components, IComponentData> = {
    [Components.MODES]: {
      title: 'Constructor',
      node: (
        <StrategyEditorModes
          addStrategyHistory={addStrategyHistory}
          setStrategyId={setSelectedStrategy}
          setStrategyNode={setStrategyNode}
        />
      ),
      listHidden: true,
    },
    [Components.CONSTRUCTOR]: {
      title: 'Constructor',
      node: (
        <Strategy
          strategyId={strategyHistory.find((e) => e._id === selectedStrategy)?._id}
          setBackground={() => {}}
          backTest={props.backTest}
          chartHeaderData={props.chartHeaderData}
          updateHistoryName={(id, name) => {
            const history = strategyHistory.slice();
            const value = history.find((e) => e._id === id);

            if (value) {
              value.name = name;
              setStrategyHistory(history);
            }
          }}
          isChartLoading={props.isChartLoading}
          strategyNames={strategies.filter((e) => e._id !== selectedStrategy).map((e) => e.name)}
        />
      ),
      listHidden: false,
    },
    [Components.SAVED_STRATEGIES]: {
      title: 'Saved strategies',
      node: (
        <>
          <div
            className="strategy-editor__back"
            onClick={() => {
              setStrategyNode(null);
            }}
          >
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
          <SavedStrategies
            setStrategies={setStrategies}
            strategies={strategies}
            setStrategyNode={setStrategyNode}
            setSelectedStrategy={setSelectedStrategy}
            addStrategyHistory={addStrategyHistory}
          />
        </>
      ),
      listHidden: true,
    },
    [Components.LIBRARY]: {
      title: 'Library',
      node: (
        <>
          <div
            className="strategy-editor__back"
            onClick={() => {
              setStrategyNode(null);
            }}
          >
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
          <StrategyLibrary
            setStrategies={setStrategies}
            strategies={strategies}
            setStrategyNode={setStrategyNode}
            setSelectedStrategy={setSelectedStrategy}
            addStrategyHistory={addStrategyHistory}
          />
        </>
      ),
      listHidden: true,
    },
  };

  const strategyNodeData = strategyNodes[strategyNode] ?? null;
  const StrategyNode = strategyNodeData ? strategyNodeData.node : null;

  useEffect(() => {
    setStrategyNode(null);
    setSelectedStrategy(null);
  }, [props.strategyEnabled]);

  useEffect(() => {
    if (props.strategyEnabled || strategyNode === Components.SAVED_STRATEGIES) {
      apiStrategy.getStrategies().then((result) => {
        setStrategies(result);
      });
    }

    if (strategyNode !== Components.CONSTRUCTOR && selectedStrategy !== null) {
      setSelectedStrategy(null);
      props.backTest(null);
    }
  }, [props.strategyEnabled, strategyNode]);

  return (
    <>
      {props.isChartLoading && <div className="strategy-loader"></div>}
      <div style={{ backgroundColor: theme.historyBackground }} className="strategy-editor">
        <StrategyEditorHeader
          title={strategyNodeData ? strategyNodeData.title : 'Constructor'}
          strategyEnabled={props.strategyEnabled}
          setStrategyEnabled={props.setStrategyEnabled}
          collapseConstructor={props.collapseConstructor}
        />
        <StrategyEditorList
          strategies={strategyHistory}
          existedStrategiesNumber={strategies.length}
          hidden={strategyNodeData ? strategyNodeData.listHidden : false}
          strategyEnabled={props.strategyEnabled}
          setStrategyNode={setStrategyNode}
          selectedStrategy={selectedStrategy}
          setSelectedStrategy={setSelectedStrategy}
          removeStrategyHistory={removeStrategyHistory}
        />

        <div
          style={{
            justifyContent: strategyNode ? ' ' : 'center',
            backgroundColor: theme.historyBackground,
          }}
          className="strategy-editor__main"
        >
          {strategyNode ? (
            StrategyNode
          ) : (
            <StrategyEditorEmpty
              strategies={strategies}
              strategyEnabled={props.strategyEnabled}
              setStrategyEnabled={props.setStrategyEnabled}
              setStrategyNode={setStrategyNode}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StrategyEditor;
