import { type FC } from 'react';
import './DatePicker.scss';
import { ChartTime } from '../../../../../../enums/HistoryChartTabs';

import arrowIcon from './arrow.svg';

interface IProps {
  period: ChartTime;
  value: number;
  setValue: (value: number) => void;
}

export const DatePicker: FC<IProps> = (props) => {
  if (props.period === ChartTime.YEAR) return;
  let value: string | null = null;

  switch (props.period) {
    case ChartTime.DAY: {
      const startDate = new Date(props.value);
      const start = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC',
      }).format(startDate);

      value = `${start}`;
      break;
    }
    case ChartTime.MONTH: {
      const startDate = new Date(props.value);
      const startYear = startDate.getFullYear().toString();

      value = `${startYear}`;
      break;
    }
  }

  const next = (): void => {
    const currentDate = new Date(props.value);

    switch (props.period) {
      case ChartTime.DAY:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case ChartTime.MONTH:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
    }

    if (currentDate.getTime() > Date.now()) {
      props.setValue(Date.now());
    } else {
      props.setValue(currentDate.getTime());
    }
  };

  const prev = (): void => {
    const currentDate = new Date(props.value);

    switch (props.period) {
      case ChartTime.DAY:
        currentDate.setMonth(currentDate.getMonth() - 1);
        break;
      case ChartTime.MONTH:
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        break;
    }

    props.setValue(currentDate.getTime());
  };

  return (
    <div className="date-picker">
      <button className="date-picker__btn-left" onClick={prev}>
        <img src={arrowIcon} alt="" />
      </button>
      <div className="date-picker__content">{value ?? 'none'}</div>
      <button className="date-picker__right" onClick={next}>
        <img src={arrowIcon} alt="" />
      </button>
    </div>
  );
};
