import { type ChangeEvent, type FC, useRef, useState } from 'react';
import Minus from '../../../../../../assets/images/Strategy/NumberInputArrow.svg';
import './styles.scss';

interface IProps {
  value: number;
  step: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: any) => void;
  min?: number;
  max?: number;
}

const CustomNumberInput: FC<IProps> = ({ value, step, onChange, onBlur }): JSX.Element => {
  const inputRef = useRef(null);
  const triggerInputChange = (): void => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const event = new Event('input', { bubbles: true });
      inputElement.dispatchEvent(event);
    }
  };

  const handleStepDown = (): void => {
    if (inputRef.current) {
      inputRef.current.stepDown();
      triggerInputChange();
    }
  };

  const handleStepUp = (): void => {
    if (inputRef.current) {
      inputRef.current.stepUp();
      triggerInputChange();
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div
      className="input-container"
      onMouseEnter={() => {
        setIsVisible(true);
      }}
      onMouseLeave={() => {
        setIsVisible(false);
      }}
    >
      {isVisible && (
        <>
          <button onClick={handleStepUp} className="plus">
            <img src={Minus} alt="+" />
          </button>
          <button onClick={handleStepDown} className="minus">
            <img src={Minus} alt="-" />
          </button>
        </>
      )}
      <input
        tabIndex={0}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        className="input"
        type="number"
        value={value}
        step={step}
        onChange={(e) => {
          onChange(e);
        }}
        ref={inputRef}
      />
    </div>
  );
};

export default CustomNumberInput;
