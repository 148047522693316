import type { Dispatch, FC, SetStateAction } from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { Components } from '../../Components';
import './strategyEditorList.scss';
import {
  type IStrategyAllData,
  type IStrategyAllResponse,
} from '../../../../../../services/api/Strategy/types/IStrategyResponse';
import CloseButton from '../../../../../../assets/images/Strategy/StrategyCancel.svg';
import AddIcon from '../../../../../../assets/images/Strategy/AddIcon.svg';

interface IStrategyEditorListProps {
  strategies: IStrategyAllResponse;
  strategyEnabled: boolean;
  hidden?: boolean;
  setStrategyNode: Dispatch<SetStateAction<Components | null>>;
  selectedStrategy: string;
  setSelectedStrategy: Dispatch<SetStateAction<string>>;
  removeStrategyHistory: Dispatch<SetStateAction<IStrategyAllData>>;
  existedStrategiesNumber: number;
}

const StrategyEditorList: FC<IStrategyEditorListProps> = (props) => {
  const [hoveredStrategy, setHoveredStrategy] = useState<string | null>(null);
  // useEffect(() => {
  //   if (props.strategies.length > 0) {
  //     if (!props.strategies.find((e) => e._id === props.selectedStrategy)) {
  //       props.setSelectedStrategy(props.strategies[0]._id);
  //     }
  //   } else {
  //     props.setStrategyNode(null);
  //   }
  // }, [props.strategies]);

  return (
    <div
      className={cn(
        'strategy-editor-list',
        !props.strategyEnabled && 'strategy-editor-list_disabled',
        props.strategies.length > 0 && 'strategy-editor-list_no-wrap',
      )}
    >
      {props.hidden ? (
        <>
          {' '}
          <div className="strategy-editor-list-strategies">
            {props.strategies.map((strategy) => {
              return (
                <div
                  key={strategy._id}
                  className={cn(
                    'strategy-editor-list-strategies__element',
                    props.selectedStrategy === strategy._id &&
                      'strategy-editor-list-strategies__element_active',
                  )}
                  onClick={() => {
                    if (props.strategyEnabled) {
                      props.setStrategyNode(Components.CONSTRUCTOR);
                      props.setSelectedStrategy(strategy._id);
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveredStrategy(strategy._id);
                  }}
                  onMouseLeave={() => {
                    if (strategy._id === hoveredStrategy) setHoveredStrategy(null);
                  }}
                >
                  {strategy.name}
                  <img
                    src={CloseButton}
                    alt="X"
                    onClick={() => {
                      props.removeStrategyHistory(strategy);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className={cn(
              'strategy-editor-list__create',
              props.strategies.length > 0 && 'strategy-editor-list__create_circle',
            )}
            style={{ backgroundColor: props.strategyEnabled ? '#694EF0' : '' }}
            disabled={!props.strategyEnabled || props.existedStrategiesNumber > 24}
            onClick={() => {
              if (props.strategyEnabled) {
                if (props.strategies.length > 0) {
                  props.setStrategyNode(null);
                } else {
                  props.setStrategyNode(Components.MODES);
                }
              }
            }}
          >
            <img src={AddIcon} alt="+" />
            {/* {props.strategies.length > 0 ? '+' : '+ Create'} */}
          </button>
        </>
      ) : (
        <>
          {props.strategies.length > 0 ? (
            <div className="strategy-editor-list-strategies">
              {props.strategies.map((strategy) => {
                return (
                  <div
                    key={strategy._id}
                    className={cn(
                      'strategy-editor-list-strategies__element',
                      props.selectedStrategy === strategy._id &&
                        'strategy-editor-list-strategies__element_active',
                    )}
                    onClick={() => {
                      if (props.strategyEnabled) {
                        props.setStrategyNode(Components.CONSTRUCTOR);
                        props.setSelectedStrategy(strategy._id);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredStrategy(strategy._id);
                    }}
                    onMouseLeave={() => {
                      if (strategy._id === hoveredStrategy) setHoveredStrategy(null);
                    }}
                  >
                    {strategy.name}
                    {/* {hoveredStrategy === strategy._id && (
                      <img
                        src={CloseButton}
                        alt="X"
                        onClick={() => {
                          props.removeStrategyHistory(strategy);
                        }}
                      />
                    )} */}
                    <img
                      src={CloseButton}
                      alt="X"
                      onClick={() => {
                        props.removeStrategyHistory(strategy);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span className="strategy-editor-list__empty">You no have strategy!</span>
          )}
          <button
            type="button"
            className={cn(
              'strategy-editor-list__create',
              props.strategies.length > 0 && 'strategy-editor-list__create_circle',
            )}
            style={{ backgroundColor: props.strategyEnabled ? '#694EF0' : '' }}
            disabled={!props.strategyEnabled || props.existedStrategiesNumber > 24}
            onClick={() => {
              if (props.strategyEnabled) {
                if (props.strategies.length > 0) {
                  props.setStrategyNode(null);
                } else {
                  props.setStrategyNode(Components.MODES);
                }
              }
            }}
          >
            <img src={AddIcon} alt="+" />
            {/* {props.strategies.length > 0 ? '+' : '+ Create'} */}
          </button>
        </>
      )}
    </div>
  );
};

export default StrategyEditorList;
