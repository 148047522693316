import type { FC } from 'react';
import { useState } from 'react';
import './strategyElement.scss';
import cn from 'classnames';
import GButton, { ButtonColors, ButtonTypes } from '../GButton/GButton';

interface IStrategyElementProps {
  _id: string;
  name: string;
  removeHandler: (id: string) => void;
  editStrategy: () => void;
}

const StrategyElement: FC<IStrategyElementProps> = (props) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  return (
    <div className="strategy-element">
      <span className="strategy-element__name">{props.name}</span>

      <div
        className="strategy-element-controls"
        onMouseLeave={() => {
          setOptionsVisible(false);
        }}
      >
        <div className="strategy-element-controls-misc">
          <div
            className="strategy-element-controls-misc-dots"
            onMouseOver={() => {
              setOptionsVisible(true);
            }}
          >
            <div className="strategy-element-controls-misc-dots__dot" />
            <div className="strategy-element-controls-misc-dots__dot" />
            <div className="strategy-element-controls-misc-dots__dot" />
          </div>

          <div
            className={cn(
              'strategy-element-controls-misc-options',
              optionsVisible && 'strategy-element-controls-misc-options_visible',
            )}
          >
            <div className="strategy-element-controls-misc-options__option">Add to library</div>
            <div
              className="strategy-element-controls-misc-options__option"
              onClick={props.editStrategy}
            >
              Edit
            </div>
            <div
              className="strategy-element-controls-misc-options__option strategy-element-controls-misc-options__option_red"
              onClick={() => {
                props.removeHandler(props._id);
              }}
            >
              Delete
            </div>
          </div>
        </div>

        <GButton
          type={ButtonTypes.BUTTON}
          title="Open"
          color={ButtonColors.VIOLET}
          bordered={false}
          onClick={props.editStrategy}
          filled={true}
          tiny={true}
          height={28}
        />
      </div>
    </div>
  );
};

export default StrategyElement;
