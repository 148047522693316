import { type CustomTicker, type DefaultTicker } from './../Strategy/types/IStrategyResponse';
import { ApiServiceBase } from '../../ApiServiceBase';
import { type ISuccessResponse } from '../../../interfaces/IResponse';
import { type ITickersResponse } from '../../../interfaces/strategyEditor';
import { type ICandleData } from '../Strategy/types/IStrategyResponse';
import { Exchanges } from '../../../interfaces/IStrategy';
import axios from 'axios';

class ApiExchange extends ApiServiceBase {
  protected baseUrl = '/api/strategy/';

  // For Unauthorized users
  public async getTickers(): Promise<ITickersResponse> {
    try {
      const response = await this.get<ISuccessResponse<ITickersResponse>>('get_tickers');

      return response.data.data ?? ([] as ITickersResponse);
    } catch (_) {
      return [] as ITickersResponse;
    }
  }

  public async getCandles(
    exchange: Exchanges,
    ticker: DefaultTicker | CustomTicker,
    tf: string,
    endTime?: number,
  ): Promise<ICandleData[]> {
    let candles: ICandleData[] = [];

    try {
      switch (exchange) {
        case Exchanges.BinanceFutures: {
          const response = await axios.post('https://beta.cr-algo.com/api/candles/fetch', {
            exchange: exchange,
            ticker: ticker,
            timeframe: tf,
            end_time: endTime,
          });
          candles = response.data.map((e) => ({
            time: e.time,
            open: parseFloat(e.open),
            high: parseFloat(e.high),
            low: parseFloat(e.low),
            close: parseFloat(e.close),
            volume: parseFloat(e.volume),
            trades: e.trades,
          }));
        }
      }
    } catch (e) {}

    return candles;
  }
}

export const apiExchange = new ApiExchange();
