import { useEffect, useState, type FC } from 'react';
import Refresh from '../../../../../../assets/images/strategy-name-refresh.svg';
import './StrategyName.scss';
interface IProps {
  onChange: (value: string) => void;
  name: string;
  strategyNames: string[];
}

export const StrategyName: FC<IProps> = ({ onChange, name, strategyNames }) => {
  const findSameNames = strategyNames.filter((strategyName) => strategyName === name);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    if (name.length === 0) {
      setIsError(true);
    } else {
      if (
        findSameNames.findIndex(
          (item) => item.toLowerCase().trim() === name.toLowerCase().trim(),
        ) !== -1
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
  }, [name.length]);
  return (
    <>
      <div className={'strategy-name' + (isError ? ' strategy-name-error' : '')}>
        <input
          type="text"
          placeholder="Name of strategy"
          onChange={(e) => {
            if (
              findSameNames.findIndex(
                (item) => item.toLowerCase().trim() === e.target.value.toLowerCase().trim(),
              ) !== -1
            ) {
              setIsError(true);
              return;
            }
            if (e.target.value === '') {
              setIsError(true);
            } else {
              setIsError(false);
            }
            onChange(e.target.value);
          }}
          value={name}
        />
        <button
          onClick={() => {
            setIsError(true);
            onChange('');
          }}
        >
          <img src={Refresh} alt="X" />
        </button>
        {findSameNames.length > 0 && (
          <div className="strategy-name-error-message">Strategy with this name already exists</div>
        )}
      </div>
    </>
  );
};
