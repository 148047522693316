import prevArrowIcon from './icons/prev.svg';
import nextArrowIcon from './icons/next.svg';

import './Info.scss';
import { type FC, useState } from 'react';
import { type IStatistics } from '../../../../../../interfaces/IStrategy';
import { pages } from './pages';
import { useSelector } from '../../../../../../redux/rootReducer';
import { selectTheme } from '../../../../../../redux/theme/reducer';

export const Info: FC<IStatistics> = (props) => {
  const theme = useSelector(selectTheme);
  const [page, setPage] = useState(0);

  const nextPage = (): void => {
    setPage(page + 1 === pages.length ? 0 : page + 1);
  };
  const prevPage = (): void => {
    setPage(page - 1 < 0 ? pages.length - 1 : page - 1);
  };

  return (
    <div className="info">
      <div className="info__body">
        <button className="info__body__arrow" onClick={prevPage}>
          <img src={prevArrowIcon} alt="" />
        </button>
        <button className="info__body__arrow" onClick={nextPage}>
          <img src={nextArrowIcon} alt="" />
        </button>
      </div>

      {pages[page].map((Element, i) => (
        <Element key={i} data={props} theme={theme} />
      ))}
    </div>
  );
};
