import { createSlice } from '@reduxjs/toolkit';
import { type State } from './types';

const initialState: State = {
  test: 0,
};

const historyReducer = createSlice({
  name: 'history',
  initialState,
  reducers: {
    increment: (state) => {
      state.test += 1;
    },
  },
});

export const { increment } = historyReducer.actions;

export default historyReducer.reducer;
