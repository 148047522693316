import type { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';

import './recaptcha.scss';

interface ReCaptchaProps {
  callback: (state: boolean, token?: string) => void;
  class?: string;
}

const ReCaptchaComponent: FC<ReCaptchaProps> = (props) => {
  const reCaptchaKey = '6LcRthUpAAAAAE8PZxMvrgrGGvxwrEwh8x5IcdLc';

  return (
    <div className={cn('recaptcha', props.class)}>
      <ReCAPTCHA
        sitekey={reCaptchaKey}
        onChange={(token) => {
          props.callback(true, token);
        }}
        onExpired={() => {
          props.callback(false);
        }}
        onErrored={() => {
          props.callback(false);
        }}
      />
    </div>
  );
};

export default ReCaptchaComponent;
