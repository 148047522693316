import { type IStrategy } from '../../interfaces/IStrategy';
import { type IChartHeaderProps } from '../../interfaces/chartData';

// export const compareStrategyHeaderData = (
//   strategy: IStrategy,
//   chartHeaderData: IChartHeaderProps['data'],
// ): boolean => {
//   return (
//     chartHeaderData.baseAsset !== strategy.base_asset ||
//     chartHeaderData.quoteAsset !== strategy.quote_asset ||
//     chartHeaderData.timeframe !== strategy.timeframe ||
//     chartHeaderData.exchange !== strategy.exchange ||
//     chartHeaderData.customIndex?._id !== strategy.custom_ticker?.id
//   );
// };

export const compareStrategyHeaderData = (
  strategy: IStrategy,
  chartHeaderData: IChartHeaderProps['data'],
): boolean => {
  if (chartHeaderData.customIndex?._id) {
    return (
      chartHeaderData.timeframe !== strategy.timeframe ||
      chartHeaderData.exchange !== strategy.exchange ||
      chartHeaderData.customIndex?._id !== strategy.ticker?.id
    );
  } else {
    return (
      chartHeaderData.baseAsset !== strategy.ticker?.base_asset ||
      chartHeaderData.quoteAsset !== strategy.ticker?.quote_asset
    );
  }

  // return (
  //   chartHeaderData.baseAsset !== strategy.ticker?.base_asset ||
  //   chartHeaderData.quoteAsset !== strategy.ticker?.quote_asset ||
  //   chartHeaderData.timeframe !== strategy.timeframe ||
  //   chartHeaderData.exchange !== strategy.exchange ||
  //   chartHeaderData.customIndex?._id !== strategy.ticker?.id
  // );
};
