import React, { type FC, useEffect } from 'react';
import {
  ActionType,
  type Axis,
  type Chart as KlineChart,
  type IndicatorDrawParams,
  type IndicatorTemplate,
  init,
  type KLineData,
  OverlayMode,
  registerIndicator,
} from 'klinecharts';
import { type ICandleData } from '../../../../services/api/Strategy/types/IStrategyResponse';
import {
  type ITradeData,
  type ITradeData as TradesIndicatorValue,
  TradesDirections,
} from '../../../../interfaces/IStrategy';
import { ChartWrapper } from './components/ChartWrapper';
import {
  type IChartHeaderProps,
  type ITradeCache,
  type IVector2,
} from '../../../../interfaces/chartData';
import { drawBackground, drawPriceLine, drawTakeProfit, drawText } from './utils';
import { TRADES_COLORS, TRADES_CONFIG } from './config';
import { type State } from '../../../../redux/chart/types';
import { ChartEditorFigure } from '../../../../enums/ChartEditorFigure';
import { useSelector } from '../../../../redux/rootReducer';
import { roundRect } from '../../../../utils/canvas';
import { selectTheme, type ThemeState } from '../../../../redux/theme/reducer';

interface IProps {
  id: string;
  width: number;
  height: number;
  trades?: TradesIndicatorValue[];
  chartData: {
    header: IChartHeaderProps;
    candles: ICandleData[];
  };
  loadMore: () => void;
  setChartLoading: (toggle: boolean) => void;
  setVisibleRange: (value: { from: number; to: number }) => void;
}

interface IState {
  selectedEditor: string | null;
  figuresVisible: boolean;
  figuresLock: boolean;
  enableScrollToReal: boolean;
  isSideMenu: boolean;
  theme?: ThemeState;
  currentStrategyType?: string;
}

export default class Chart extends React.Component<IProps, IState> {
  public state: IState = {
    selectedEditor: null,
    figuresVisible: true,
    figuresLock: false,
    enableScrollToReal: false,
    isSideMenu: false,
    currentStrategyType: '',
  };

  private chart: KlineChart | null = null;
  private yAxis: Axis | null = null;
  private cursorPos: { x: number; y: number } = { x: 0, y: 0 };
  private visibleRange: { from: number; to: number } = { from: 0, to: 0 };
  private isUpdateCandles = true;
  private tradesCache: Record<number, ITradeCache> = {};
  private tradesCandles: Record<number, number> = {};
  private trianglesCache: Record<number, [number, number]> = {};
  private overlays = new Set<string>();
  private chartPrecision = 1;
  private scrollTo: number | null = null;
  private loadMoreResolver: ((_?: never) => void) | null = null;

  public get chartWidth(): number {
    return this.props.width - (!this.state.isSideMenu ? 52 : 0);
  }

  public get candles(): ICandleData[] {
    return this.props.chartData.candles;
  }

  //
  // Render
  //

  public Hook: FC = () => {
    const reducer = useSelector((state) => state.chart);
    const theme = useSelector(selectTheme);
    const currentStrategyType = useSelector((state) => state.strategy.currentStrategyType);
    useEffect(() => {
      this.setState({ theme });
    }, []);
    useEffect(() => {
      this.setState({ currentStrategyType });
    }, [currentStrategyType]);
    useEffect(this.onChangeChartSize.bind(this), [
      this.props.width,
      this.props.height,
      this.state.isSideMenu,
    ]);

    useEffect(this.onUpdateCandles.bind(this), [this.props.chartData.candles]);
    useEffect(this.onUpdateTrades.bind(this), [this.props.trades]);
    useEffect(this.onChangeDrawInstrument.bind(this, reducer), [reducer.selectedEditor]);
    useEffect(this.onChangeDrawInstrumentsVisible.bind(this, reducer), [reducer.isVisible]);
    useEffect(this.onChangeDrawInstrumentsLock.bind(this, reducer), [reducer.isVisible]);
    useEffect(this.onDestroyDrawInstruments.bind(this, reducer), [reducer.isDestroy]);

    useEffect(() => {
      document.addEventListener('click', this.onBarChartClick.bind(this));

      return () => {
        document.removeEventListener('click', this.onBarChartClick.bind(this));
      };
    }, []);
    return <></>;
  };

  public render(): React.ReactNode {
    return (
      <>
        <this.Hook />
        <ChartWrapper
          isSideMenu={this.state.isSideMenu}
          toggleSideMenu={() => {
            this.setState({ isSideMenu: !this.state.isSideMenu });
          }}
          chartHeader={this.props.chartData.header}
          enableScrollToReal={this.state.enableScrollToReal}
          scrollToReal={() => {
            this.chart?.scrollToRealTime();
          }}
        >
          <div
            className="chart"
            id={this.props.id}
            style={{
              backgroundColor: 'rgb(10, 8, 26)',
              height: `${
                window.innerWidth && window.innerWidth > 768
                  ? String(this.props.height - 30) + 'px'
                  : '90%'
              }`,
              maxHeight: `${
                window.innerWidth && window.innerWidth > 768
                  ? String(this.props.height) + 'px'
                  : '100%'
              }`,
              width: `100%`,
            }}
          />
        </ChartWrapper>
      </>
    );
  }

  //
  // Update Events
  //

  private async onBarChartClick(e: MouseEvent): Promise<void> {
    if (this.scrollTo !== null) return;
    // @ts-expect-error
    if (e.target.classList[0] !== 'bar-chart') return;
    // @ts-expect-error
    const date: number = e.target.__data__.time;

    this.scrollTo = date;

    this.props.setChartLoading(true);
    this.chart?.scrollToTimestamp(date);

    while (true) {
      let timeout: NodeJS.Timeout;
      const createTimeout = (): NodeJS.Timeout => {
        return setTimeout(() => {
          this.props.loadMore();
          timeout = createTimeout();
        }, 3000);
      };
      timeout = createTimeout();
      await new Promise((resolve) => (this.loadMoreResolver = resolve));
      clearTimeout(timeout);
      const startTimestamp = this.candles[0]?.time;

      if (this.scrollTo >= startTimestamp) {
        this.chart?.scrollToTimestamp(this.scrollTo);
        this.props.setChartLoading(false);
        this.scrollTo = null;
        // @ts-expect-error
        this.yAxis?.setAutoCalcTickFlag(true);
        this.chart.resize();
        break;
      } else {
        this.chart?.scrollToTimestamp(date);
      }
    }
  }

  private onChangeDrawInstrument(reducer: State): void {
    this.setState({ selectedEditor: reducer.selectedEditor });

    switch (reducer.selectedEditor) {
      case ChartEditorFigure.STRONG_MAGNETIC:
      case ChartEditorFigure.MAGNET: {
        this.overlays.forEach((id) => {
          const data = this.chart.getOverlayById(id);
          if (!data) return;
          this.chart.removeOverlay(id);
          this.chart.createOverlay({
            ...data,
            mode:
              reducer.selectedEditor === 'magnet'
                ? OverlayMode.WeakMagnet
                : OverlayMode.StrongMagnet,
          });
        });
        return;
      }
    }

    if (reducer.selectedEditor) {
      // eslint-disable-next-line
      const id = this.chart.createOverlay(reducer.selectedEditor) as string;
      if (id && !this.overlays.has(id)) this.overlays.add(id);
    }
  }

  private onChangeDrawInstrumentsLock(reducer: State): void {
    this.overlays.forEach((i) => {
      const data = this.chart.getOverlayById(i);

      if (!data) return;
      this.chart.removeOverlay(i);
      this.chart.createOverlay({
        ...data,
        lock: reducer.isLock,
      });
    });
  }

  private onChangeDrawInstrumentsVisible(reducer: State): void {
    this.overlays.forEach((i) => {
      const data = this.chart.getOverlayById(i);
      if (!data) return;
      this.chart.removeOverlay(i);
      this.chart.createOverlay({
        ...data,
        visible: reducer.isVisible,
      });
    });
  }

  private onDestroyDrawInstruments(reducer: State): void {
    if (!reducer.isDestroy) return;
    this.overlays.forEach((i) => {
      this.chart.removeOverlay(i);
      this.overlays.delete(i);
    });
  }

  private onChangeChartSize(): void {
    this.chart?.setStyles(`width: ${this.chartWidth}px; height: ${this.props.height}px; `);
  }

  private onUpdateTrades(): void {
    const trades = this.props.trades;

    this.tradesCache = {};
    this.tradesCandles = {};

    if (trades.length === 0) {
      return;
    }

    const candles = this.chart.getDataList();
    let candleIndex = candles.length - 1;
    for (let tradeIndex = trades.length - 1; tradeIndex >= 0; tradeIndex--) {
      const trade = trades[tradeIndex];
      const cache: ITradeCache = {
        entryCandle: null,
        exitCandle: null,
        exitPrice: null,
        y: {
          max: 0,
          min: null,
        },
        hitCandles: {},
      };

      cache.y.min = trade.stop_loss?.price ?? null;

      for (candleIndex; candleIndex >= 0; candleIndex--) {
        const candle = candles[candleIndex];

        if (trade.exit_time === null || candle.timestamp <= trade.exit_time) {
          this.tradesCandles[candleIndex] = tradeIndex;

          if (trade.exit_time === candle.timestamp) {
            cache.exitCandle = candleIndex;
          }
        }

        trade.take_profits?.forEach((e, index) => {
          if (e.time === candle.timestamp) {
            if (!cache.hitCandles.takeProfits) cache.hitCandles.takeProfits = {};
            cache.hitCandles.takeProfits[e.reach_price] = candleIndex;

            if (index === trade.take_profits.length - 1) {
              cache.exitCandle = candleIndex;
              cache.exitPrice = e.reach_price;
            }
          }
          cache.y.max = e.price[0];
        });

        trade.averages?.forEach((e) => {
          if (e.time === candle.timestamp) {
            if (!cache.hitCandles.averages) cache.hitCandles.averages = {};
            cache.hitCandles.averages[e.price] = candleIndex;
          }

          if (
            cache.y.min === null ||
            (trade.side === TradesDirections.Long ? e.price < cache.y.min : e.price > cache.y.min)
          ) {
            cache.y.min = e.price;
          }
        });

        if (trade?.liquidation?.time === candle.timestamp) {
          cache.hitCandles.liquidation = candleIndex;
          cache.exitCandle = candleIndex;
          cache.exitPrice = trade.liquidation.price[trade.liquidation.price.length - 1];
        }

        if (trade?.stop_loss?.time === candle.timestamp) {
          cache.hitCandles.stopLoss = candleIndex;
          cache.exitCandle = candleIndex;
          cache.exitPrice = trade.stop_loss.price;
        }

        if (candle.timestamp <= trade.time) {
          cache.entryCandle = candleIndex;
          break;
        }
      }

      this.tradesCache[trade.time] = cache;

      if (candleIndex <= 0) {
        break;
      }
    }
  }

  private onUpdateCandles(): void {
    if (this.candles.length === 0) {
      this.isUpdateCandles = true;
      return;
    }

    if (this.isUpdateCandles) {
      this.isUpdateCandles = false;
      this.createChart();
      return;
    }

    const loadedCandles = this.chart?.getDataList() ?? [];
    const firstTimestamp = loadedCandles[0]?.timestamp ?? 0;

    if (loadedCandles.length > 0 && this.candles.length - loadedCandles.length >= 999) {
      this.loadMoreResolver?.();
    }

    if (firstTimestamp > this.candles[0].time) {
      const newCandles: KLineData[] = [];

      for (const candle of this.candles) {
        if (candle.time >= firstTimestamp) break;

        newCandles.push({
          timestamp: candle.time,
          open: candle.open,
          high: candle.high,
          low: candle.low,
          close: candle.close,
          volume: candle.volume,
        });
      }

      this.chart.applyMoreData(newCandles, true);
      this.onUpdateTrades();
      return;
    }

    const candle = this.candles[this.candles.length - 1];
    this.chart.updateData({
      timestamp: candle.time,
      open: candle.open,
      high: candle.high,
      low: candle.low,
      close: candle.close,
      volume: candle.volume,
    });
  }

  private onChangeVisibleRange(data: { realFrom: number; realTo: number }): void {
    this.visibleRange = { from: data.realFrom, to: data.realTo };
    this.props.setVisibleRange(this.visibleRange);

    if (data.realTo === this.candles.length) {
      if (this.state.enableScrollToReal) this.setState({ enableScrollToReal: false });
    } else {
      if (!this.state.enableScrollToReal) this.setState({ enableScrollToReal: true });
    }

    if (data.realFrom === 0) {
      this.props.loadMore();
    }
  }

  //
  // Process Trades
  //

  private drawTrades({
    ctx,
    barSpace,
    visibleRange,
    xAxis,
    yAxis,
  }: IndicatorDrawParams<TradesIndicatorValue>): boolean {
    if (this.candles.length === 0) {
      this.trianglesCache = {};
      return;
    }

    if (!this.yAxis) this.yAxis = yAxis;

    const candles = this.chart.getDataList();
    const tradesRender = new Set<number>();

    let statisticsTrade: [ITradeData, number] | null = null;

    for (let i = visibleRange.realFrom; i < visibleRange.realTo; i++) {
      const tradeId = this.tradesCandles[i] ?? null;

      if (tradeId === null) continue;

      const trade = this.props.trades[tradeId];
      const tradeCache = this.tradesCache[trade?.time];

      if (!trade || !tradeCache || tradesRender.has(trade.time)) continue;

      tradesRender.add(trade.time);

      const isLong = trade.side === TradesDirections.Long;
      const entryPos: IVector2 = {
        x: xAxis.convertToPixel(tradeCache.entryCandle ?? 0) - barSpace.halfBar,
        y: yAxis.convertToPixel(trade.enter_price),
      };

      const exitPos: IVector2 = {
        x: xAxis.convertToPixel(tradeCache.exitCandle ?? candles.length - 1) + barSpace.halfBar,
        y: yAxis.convertToPixel(tradeCache.exitPrice ?? candles[candles.length - 1].close),
      };

      const yMin = yAxis.convertToPixel(tradeCache.y.min);
      const yMax = yAxis.convertToPixel(tradeCache.y.max);
      const isHovered = this.cursorPos.x > entryPos.x && this.cursorPos.x < exitPos.x;

      drawBackground(
        ctx,
        entryPos.x,
        exitPos.x,
        TRADES_COLORS.BACKGROUND[TradesDirections.Short],
        yMin,
        entryPos.y - yMin,
      );

      drawBackground(
        ctx,
        entryPos.x,
        exitPos.x,
        TRADES_COLORS.BACKGROUND[TradesDirections.Long],
        entryPos.y,
        yMax - entryPos.y,
        true,
      );

      drawPriceLine(
        ctx,
        yAxis,
        trade.enter_price,
        TRADES_COLORS.center_line,
        entryPos.x,
        exitPos.x,
        ``,
        this.chartPrecision,
        false,
      );

      // TakeProfits

      trade.take_profits.forEach((takeProfit, tpIndex) => {
        drawTakeProfit(
          ctx,
          xAxis,
          yAxis,
          takeProfit,
          trade,
          tradeCache,
          entryPos.x,
          exitPos.x,
          tpIndex,
          this.chartPrecision,
          isHovered,
        );

        const reachPrice = takeProfit.reach_price;
        const candleIdx = tradeCache.hitCandles.takeProfits?.[reachPrice];

        if (candleIdx) {
          const takeProfitX = xAxis.convertToPixel(candleIdx);

          this.drawCircle(ctx, takeProfitX, yAxis.convertToPixel(reachPrice), '#00FF1A', {
            bgColor: '#0D930A66',
            amount: takeProfit.qty,
            text: `TP${tpIndex + 1}`,
            time: new Date(takeProfit.time).toLocaleTimeString(),
            profit: `+${takeProfit.profit.toFixed(2)}$`,
          });
        }
      });

      // Average

      trade.averages?.forEach((e, i) => {
        drawPriceLine(
          ctx,
          yAxis,
          e.price,
          TRADES_COLORS.AVERAGE_LINE,
          entryPos.x,
          exitPos.x,
          `DCA${i + 1}`,
          this.chartPrecision,
          isHovered,
        );

        const candleIdx = tradeCache.hitCandles.averages?.[e.price];

        if (candleIdx) {
          const x = xAxis.convertToPixel(candleIdx);
          const y = yAxis.convertToPixel(e.price);

          this.drawCircle(ctx, x, y, '#FF8A00', {
            bgColor: '#281D4780',
            amount: e.qty,
            text: `DCA${i + 1}`,
            time: new Date(e.time).toLocaleTimeString(),
          });
        }
      });

      //

      if (trade.liquidation) {
        drawTakeProfit(
          ctx,
          xAxis,
          yAxis,
          trade.liquidation,
          trade,
          tradeCache,
          entryPos.x,
          exitPos.x,
          0,
          this.chartPrecision,
          isHovered,
        );

        const candleHit = this.props.chartData.candles[tradeCache.hitCandles.liquidation];

        if (candleHit) {
          const liquidationY = yAxis.convertToPixel(
            trade.liquidation.price[trade.liquidation.price.length - 1],
          );

          this.drawCircle(ctx, exitPos.x - barSpace.halfBar, liquidationY, '#FF0000', {
            bgColor: '#281D4780',
            amount: trade.qty - trade.take_profit_qty,
            text: 'LIQUIDATION',
            time: new Date(trade.liquidation.time).toLocaleTimeString(),
          });
        }
      }

      // Stoploss

      if (trade.stop_loss) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const fee = trade.stop_loss.fee + trade.enter_fee;
        drawPriceLine(
          ctx,
          yAxis,
          trade.stop_loss.price,
          TRADES_COLORS.STOPLOSS_LINE,
          entryPos.x,
          exitPos.x,
          'SL',
          this.chartPrecision,
          isHovered,
          isLong
            ? trade.qty * trade.stop_loss.price - trade.qty_quote - fee
            : trade.qty_quote - trade.qty * trade.stop_loss.price - fee,
        );

        const candleHit = this.props.chartData.candles[tradeCache.hitCandles.stopLoss];

        if (candleHit) {
          const stopLossY = yAxis.convertToPixel(trade.stop_loss.price);

          this.drawCircle(ctx, exitPos.x - barSpace.halfBar, stopLossY, '#FF0000', {
            bgColor: '#5B0930C4',
            text: 'SL',
            amount: trade.qty - trade.take_profit_qty,
            time: new Date(trade.stop_loss.time).toLocaleTimeString(),
          });
        }
      }
      // Open Trad

      const candle = this.candles[tradeCache.entryCandle];

      if (candle) {
        this.drawCircle(ctx, entryPos.x + barSpace.halfBar, entryPos.y, '#3300FF', {
          bgColor: isLong ? '#0D930A66' : '#5B0930C4',
          amount: trade.qty - (trade.average_qty ?? 0),
          text: isLong ? 'Open Long' : 'Open Short',
          time: new Date(trade.time).toLocaleTimeString(),
        });

        drawText(
          ctx,
          isLong ? 'Long' : 'Short',
          entryPos.x + barSpace.halfBar,
          yAxis.convertToPixel(isLong ? candle.low : candle.high) - 15 * (isLong ? -1 : 1),
          'center',
          'white',
          12,
        );
      }

      if (isHovered) {
        statisticsTrade = [trade, tradeId];
      }

      this.trianglesCache = {};
    }

    if (statisticsTrade !== null) {
      this.drawStatistics(ctx, statisticsTrade[0], statisticsTrade[1]);
    }

    return false;
  }

  //
  // Init Chart 1
  //

  public componentDidMount(): void {
    this.chart = init(this.props.id);
    // this.chart.loadMore(this.props.loadMore);
    this.chart.subscribeAction(
      ActionType.OnCrosshairChange,
      (data) => (this.cursorPos = { x: data.x, y: data.y }),
    );
    this.chart.subscribeAction(
      ActionType.OnVisibleRangeChange,
      this.onChangeVisibleRange.bind(this),
    );
    this.chart.setStyles({
      grid: { horizontal: { color: 'rgba(255,255,255, 0.1)', dashedValue: [1.5, 1.5] } },
    });
    this.chart.setStyles({
      grid: { vertical: { color: 'rgba(255,255,255, 0.1)', dashedValue: [1.5, 1.5] } },
    });

    const indicator: IndicatorTemplate<TradesIndicatorValue> = {
      name: 'Trades',
      calc: () => this.props.trades,
      draw: this.drawTrades.bind(this),
    };

    registerIndicator(indicator);

    this.chart.createIndicator('Trades', false, { id: 'candle_pane' });
    this.chart.overrideIndicator(indicator, 'candle_pane');
  }

  private createChart(): void {
    const candles: KLineData[] = [];
    let maxPrecision = 0;

    this.candles.forEach((candle) => {
      candles.push({
        timestamp: candle.time,
        open: candle.open,
        high: candle.high,
        low: candle.low,
        close: candle.close,
        volume: candle.volume,
      });

      const precision = (`${candle.close}`.split('.')[1] ?? '').length;

      if (precision > maxPrecision) maxPrecision = precision;
    });

    if (candles.length > 0) {
      this.chartPrecision = maxPrecision;
      this.chart.setPriceVolumePrecision(maxPrecision, 2);
    }

    // @ts-expect-error
    this.yAxis?.setAutoCalcTickFlag(true);

    this.chart.applyNewData(candles);
    this.chart.resize();

    if (this.visibleRange.from !== 0) {
      this.chart.scrollToDataIndex(this.visibleRange.from);
    }
  }

  //
  // Draw1 Utils
  //

  private drawCircle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    stroke: string,
    data?: {
      bgColor: string;
      amount: number;
      text: string;
      profit?: string;
      time: string;
    },
  ): void {
    const radius = 4;
    const fill = 'white';
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    if (fill) {
      ctx.fillStyle = fill;
      ctx.fill();
    }
    if (stroke) {
      ctx.lineWidth = 3;
      ctx.strokeStyle = stroke;
      ctx.stroke();
    }
    ctx.closePath();

    const r = radius;
    const startX = x - r;
    const endX = x + r;
    const startY = y - r;
    const endY = y + r;
    const { x: currX, y: currY } = this.cursorPos;

    if (data && currX >= startX && currX <= endX && currY >= startY && currY <= endY) {
      ctx.beginPath();
      ctx.setLineDash([]);

      let xi = x;
      let yi = y;

      const border = 5;
      ctx.font = `normal 14px Michroma, sans-serif`;
      const sizeWidthArr = [data.amount.toString(), data.text, data.time].map(
        (e) => ctx.measureText(e).width,
      );

      if (data.profit) {
        sizeWidthArr.push(sizeWidthArr[1] + ctx.measureText(data.profit).width + 5);
      }

      const width = Math.max(...sizeWidthArr) + border * 2;
      const height = border + 12 + border + 14 + border + 12 + border;
      ctx.moveTo(x, y);
      ctx.lineTo((xi -= 10), (yi -= 20));
      ctx.lineTo((xi -= width), yi);
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.closePath();

      ctx.fillStyle = data.bgColor;
      ctx.fillRect(xi, yi - height, width, height);
      xi += border;
      yi -= height - 6 - border;

      drawText(ctx, data.amount.toString(), xi, yi, 'left', 'white', 12);
      drawText(ctx, data.text, xi, (yi += 13 + border), 'left', 'white', 14);
      if (data.profit) {
        const w = ctx.measureText(data.text).width;

        drawText(
          ctx,
          data.profit,
          xi + w + 5,
          yi,
          'left',
          data.profit[0] === '+' ? '#00FF1A' : '',
          14,
        );
      }

      drawText(ctx, data.time, xi, (yi += 13 + border), 'left', 'white', 9);
    }
  }

  private drawStatistics(ctx: CanvasRenderingContext2D, trade: ITradeData, tradeIdx: number): void {
    const x = this.chart.getSize().width - (TRADES_CONFIG.STATS.width + 75);

    ctx.shadowColor = 'rgba(255,255,255,0.2)';
    ctx.shadowBlur = 20;
    ctx.fillStyle = '#0F0C22';
    roundRect(ctx, x, TRADES_CONFIG.STATS.y, TRADES_CONFIG.STATS.width, 270, 12, true, false);
    ctx.shadowColor = 'rgba(0,0,0,0)';

    drawText(
      ctx,
      `Trade #${tradeIdx + 1}`,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      x + TRADES_CONFIG.STATS.padding,
      TRADES_CONFIG.STATS.y + 25,
      'left',
      'white',
      12,
      '700',
    );
    drawText(
      ctx,
      `${this.state.currentStrategyType}`,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      x + TRADES_CONFIG.STATS.padding + 160,
      TRADES_CONFIG.STATS.y + 25,
      'left',
      'white',
      10,
      '700',
    );

    drawText(
      ctx,
      new Date(trade.time).toLocaleString(),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      x + TRADES_CONFIG.STATS.padding,
      TRADES_CONFIG.STATS.y + 50,
      'left',
      'white',
      8,
      '700',
    );
    let paramY = TRADES_CONFIG.STATS.y + 72.5;

    const isClosed = trade.exit_time !== null;

    const qty = trade.qty;
    const stat: Record<string, number | string> = {
      Status: isClosed ? 'Closed' : 'Open',
      Direction: TradesDirections[trade.side],
      'Entry price': trade.enter_price.toFixed(this.chartPrecision),
      'Avg. exit price': isClosed ? trade.average_exit.toFixed(this.chartPrecision) : 'NULL',
      Quantity: qty.toFixed(4),
      [isClosed ? 'Profit' : 'Open PL']: `${trade.pnl.toFixed(2)}$`,
    };
    for (const e in stat) {
      let color = 'white';
      const value = stat[e];

      if (e === 'Direction') {
        color = TradesDirections[value] === TradesDirections.Long ? '#30D95F' : '#F21212';
      }

      if (e === 'Profit' || e === 'Open PL') {
        color = parseFloat(value as string) > 0 ? '#30D95F' : '#F21212';
      }
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      drawText(
        ctx,
        e,
        x + TRADES_CONFIG.STATS.padding,
        paramY,
        'left',
        'rgba(127, 131, 144, 1)',
        9,
      );
      drawText(
        ctx,
        stat[e] as string,
        x + TRADES_CONFIG.STATS.width - TRADES_CONFIG.STATS.padding,
        paramY,
        'right',
        color,
        11,
        '600',
      );
      paramY += 35;
    }
  }

  private drawTriangle(
    ctx: CanvasRenderingContext2D,
    candle: ICandleData,
    upDown: boolean,
    x: number,
    y: number,
    text = '',
    reverse = false,
    color = 'black',
    textColor = color,
  ): number {
    if (!candle) return;
    const lineWidthX = x + TRADES_CONFIG.TRIANGLE.width * (reverse ? 1 : -1);
    const lineWidthY = TRADES_CONFIG.TRIANGLE.width / 2;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(lineWidthX, y - lineWidthY * (reverse ? 1 : -1));
    ctx.lineTo(lineWidthX, y + lineWidthY * (reverse ? 1 : -1));
    ctx.lineTo(x, y);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();

    const candleOffset = this.trianglesCache[candle.time]?.[upDown ? 1 : 0] ?? 0;

    ctx.beginPath();

    let initY = this.yAxis.convertToPixel(upDown ? candle.high : candle.low);
    const height = TRADES_CONFIG.TRIANGLE.width + TRADES_CONFIG.TRIANGLE.lineHeight + 5;

    initY -= (height + 15) * candleOffset * (upDown ? 1 : -1);

    const textY = initY - height * (upDown ? 1 : -1);
    const width = TRADES_CONFIG.TRIANGLE.width * (upDown ? -1 : 1);

    ctx.moveTo(x, initY);
    ctx.lineTo(x - lineWidthY, initY + width);
    ctx.lineTo(x + lineWidthY, initY + width);
    ctx.fillRect(
      x - TRADES_CONFIG.TRIANGLE.lineWidth / 2,
      initY + width - (upDown ? TRADES_CONFIG.TRIANGLE.lineHeight : 0),
      TRADES_CONFIG.TRIANGLE.lineWidth,
      TRADES_CONFIG.TRIANGLE.lineHeight,
    );
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();

    drawText(ctx, text, x, textY, 'center', textColor);

    if (!this.trianglesCache[candle.time]) this.trianglesCache[candle.time] = [0, 0];

    this.trianglesCache[candle.time][upDown ? 1 : 0]++;

    return textY;
  }
}
